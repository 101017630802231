import { ActionReducerMapBuilder, createReducer } from "@reduxjs/toolkit";

import { StockSymbol, UISupportedStocks } from "../../constants/enums";
import { DEFAULT_ACCORDION_STATUSES } from "../../types/electionDataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, some } from "../../types/typeUtils";
import { combineClients } from "../../utils/clientsUtils";
import {
  requestStageValidation,
  resetStageValidation,
  setAccordionStatus,
  setIsSBSElectionSaveEnabled,
  setUnacknowledgedElectStageWarnings,
} from "../actions/electionsActions";
import {
  closeAlert,
  errPageLoad,
  openAlert,
  setSelectedEntity,
  updateAllClients,
} from "../actions/entitlementActions";
import {
  recvEquityData,
  setSelectedStockSymbol,
} from "../actions/equityActions";
import { setPerformanceDetailsFilter } from "../actions/internalInvestmentActions";
import { setPointerType } from "../actions/viewActions";
import { IViewDataStore } from "../store";

const MAX_NUMBER_OF_ALERTS = 3;

export const defaultViewDataState: IViewDataStore = {
  alerts: [],
  isSBSElectionSaveEnabled: false,
  pageLoadStatus: 200,
  performanceDetailsFilter: undefined,
  selectedStockSymbol: StockSymbol.BX,
  electionAccordionCardStatuses: DEFAULT_ACCORDION_STATUSES,
  stageValidationRequested: false,
  unAcknowledgedElectStageWarnings: nothing,
  lastPointerType: "mouse",
  allClients: [],
  selectedEntity: nothing,
};

export const defaultViewDataBuilder = (
  builder: ActionReducerMapBuilder<IViewDataStore>
): void => {
  builder.addCase(openAlert, (state, action) => {
    // remove any closed alert in the array
    state.alerts = state.alerts.filter((alert) => alert.open);
    if (state.alerts.length === MAX_NUMBER_OF_ALERTS) {
      state.alerts.shift();
    }
    const alert = action.payload;
    alert.alertId = alert.alertId ?? `id-${Math.random()}`;
    state.alerts.push({
      ...alert,
      open: true,
    });
  });
  builder.addCase(closeAlert, (state, action) => {
    // we don't remove the alert yet to keep the component' exit transition effect
    const alert = state.alerts.find(
      (alert) => alert.alertId === action.payload
    );
    if (alert) {
      alert.open = false;
    }
  });
  builder.addCase(errPageLoad, (state, action) => {
    state.pageLoadStatus = action.payload;
  });
  builder.addCase(setPerformanceDetailsFilter, (state, action) => {
    state.performanceDetailsFilter = action.payload;
  });
  builder.addCase(setSelectedStockSymbol, (state, action) => {
    state.selectedStockSymbol = action.payload;
  });
  builder.addCase(recvEquityData, (state, action) => {
    const firstExistingSymbol = UISupportedStocks.find((symbol) =>
      isSomething(action.payload[symbol])
    );
    if (firstExistingSymbol !== undefined) {
      state.selectedStockSymbol = firstExistingSymbol;
    }
  });
  builder.addCase(setAccordionStatus, (state, action) => {
    state.electionAccordionCardStatuses[action.payload.accordionObject] =
      action.payload.status;
  });
  builder.addCase(requestStageValidation, (state) => {
    state.stageValidationRequested = true;
  });
  builder.addCase(resetStageValidation, (state) => {
    state.stageValidationRequested = false;
  });
  builder.addCase(setIsSBSElectionSaveEnabled, (state, action) => {
    state.isSBSElectionSaveEnabled = action.payload;
  });
  builder.addCase(setUnacknowledgedElectStageWarnings, (state, action) => {
    state.unAcknowledgedElectStageWarnings = action.payload;
  });
  builder.addCase(setPointerType, (state, action) => {
    state.lastPointerType = action.payload;
  });
  builder.addCase(updateAllClients, (state, action) => {
    state.allClients = combineClients(state.allClients, action.payload);
  });
  builder.addCase(setSelectedEntity, (state, action) => {
    state.selectedEntity = some(action.payload);
  });
};
export const investorUIViewDataReducer = createReducer<IViewDataStore>(
  defaultViewDataState,
  (builder) => {
    defaultViewDataBuilder(builder);
  }
);
