import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import {
  ErrorStringConstants,
  getElectionRoundConfigurationData,
  getResult,
  openAlert,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";
import { useDispatch } from "react-redux";
import sanitize from "sanitize-filename";

interface ElectionResultsDownloadButtonProps {
  id: string | undefined;
  label: string;
  disabled: boolean;
  showIcon?: boolean;
  isOutlined?: boolean;
  className?: string;
}

const ElectionResultsDownloadButton = ({
  id,
  label,
  disabled,
  showIcon,
  isOutlined,
  className,
}: ElectionResultsDownloadButtonProps) => {
  const dispatch = useDispatch();
  const handleExcelDownload = useExcelDownload();

  const handleClick = async () => {
    if (!id) return;

    const electionRoundResp = await getElectionRoundConfigurationData(id).then(
      (r) => {
        return r;
      },
      () => {
        return undefined;
      }
    );
    const jsonResponse = await getResult(id).then(
      (response) => {
        return response;
      },
      () => {
        return undefined;
      }
    );

    if (
      jsonResponse === undefined ||
      electionRoundResp === undefined ||
      jsonResponse.length < 1
    ) {
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.NO_ELECTION_RESULT,
        })
      );
      return;
    }

    try {
      const generator = worksheetGenerators.ElectionResult;
      const fileName = `ElectionResults_${sanitize(electionRoundResp.name)}`;
      await handleExcelDownload(generator, fileName, {
        resultResponse: jsonResponse,
      });
    } catch (e) {
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.DOWNLOAD_FAILED,
        })
      );
    }
  };

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      endIcon={showIcon ? <FileDownloadOutlined /> : undefined}
      variant={isOutlined ? "outlined" : undefined}
      className={className}
    >
      {label}
    </Button>
  );
};

export default ElectionResultsDownloadButton;
