export interface OIDCProviderDefinition {
  AUTHORITY_URL_BASE: string;
  CLIENT_ID: string;
  SCOPE: string;
}

export enum AuthProviderName {
  OKTA = "OKTA",
  COGNITO = "COGNITO",
}

export interface EnvironmentVariables {
  REACT_APP_PROFILE_IMAGES_URL_BASE: string;
  REACT_APP_BXACCESS_URL: string;
  REACT_APP_GOOGLE_ANALYTICS_TRACKER: string;
  REACT_APP_ENV: string;
  REACT_APP_PASSIVE_CHECK_URL: string;
  REACT_APP_ELECTION_ROUND_ID: string;
  RESTRICTED_EQUITY_FEATURE_FLAG: boolean;
  RESTRICTED_EQUITY_ADMIN_FEATURE_FLAG: boolean;
  LOGIN_HELP_LINK_HREF: string;
  REACT_APP_CLOUDWATCH_IDENTITY_POOL_ID: string;
  REACT_APP_CLOUDWATCH_APPLICATION_ID: string;
  AUTH_PROVIDER: AuthProviderName;
  OKTA_OIDC_PROVIDER: OIDCProviderDefinition;
  COGNITO_OIDC_PROVIDER: OIDCProviderDefinition;
  SHOW_USER_AGREEMENTS_FEATURE_FLAG: boolean;
}

const DEFAULT_ENV_VARS: EnvironmentVariables = {
  REACT_APP_PROFILE_IMAGES_URL_BASE: "",
  REACT_APP_BXACCESS_URL: "",
  REACT_APP_GOOGLE_ANALYTICS_TRACKER: "",
  REACT_APP_ENV: "",
  REACT_APP_PASSIVE_CHECK_URL: "",
  REACT_APP_ELECTION_ROUND_ID: "",
  RESTRICTED_EQUITY_FEATURE_FLAG: false,
  RESTRICTED_EQUITY_ADMIN_FEATURE_FLAG: false,
  LOGIN_HELP_LINK_HREF: "",
  REACT_APP_CLOUDWATCH_IDENTITY_POOL_ID: "",
  REACT_APP_CLOUDWATCH_APPLICATION_ID: "",
  AUTH_PROVIDER: AuthProviderName.OKTA,
  OKTA_OIDC_PROVIDER: {
    AUTHORITY_URL_BASE: "",
    CLIENT_ID: "",
    SCOPE: "",
  },
  COGNITO_OIDC_PROVIDER: {
    AUTHORITY_URL_BASE: "",
    CLIENT_ID: "",
    SCOPE: "",
  },
  SHOW_USER_AGREEMENTS_FEATURE_FLAG: false,
};

export class EnvironmentResolver {
  static ENV: EnvironmentVariables = DEFAULT_ENV_VARS;

  public static async configureEnvironment() {
    if (window.fetch) {
      return window
        .fetch("/env.json")
        .then((data) => data.json())
        .then((data: EnvironmentVariables) => {
          EnvironmentResolver.ENV = {
            ...data,
          };
        })
        .catch((e) => {
          console.error(
            "Something went terribly wrong! Confirm that 'public/env.json' exists.",
            e
          );
        });
    }
  }
}
