import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Collapse, IconButton } from "@mui/material";
import React from "react";

import { ILabeledDataPoint } from "../../../../types/labelTypes";
import { OverviewDataPoint } from "../OverviewDataPoint/OverviewDataPoint";
import styles from "./OverviewCard.module.scss";

export interface IOverviewCardProps {
  cardName: string;
  subHeader: string;
  overviewDataList: ILabeledDataPoint[];
}

/**
 * Component that encapsulates the Callout Banner for a user with carry
 */
export const OverviewCard = (props: IOverviewCardProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={styles.overviewCard}>
      <div className={styles.sections}>
        {props.overviewDataList.map((data, idx) => {
          const showSeparator = data.isTotalLabel;
          return data.subRows ? (
            <div key={idx}>
              <div className={styles.collapseRow}>
                <IconButton
                  aria-label="expand row"
                  onClick={() => setOpen(!open)}
                  className={styles.collapseIcon}
                >
                  {open ? (
                    <KeyboardArrowDownIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowRightIcon fontSize="small" />
                  )}
                </IconButton>
                <OverviewDataPoint
                  key={idx}
                  label={data.label}
                  tooltipParagraphs={data.tooltipParagraphs}
                  calloutDatum={data.calloutDatum}
                  getFormattedValue={data.getFormattedValue}
                  shouldHide={(data.calloutDatum?.value ?? 0) === 0}
                  isSubLabel={data.isSubLabel}
                  isTotalLabel={data.isTotalLabel}
                  subRows={data.subRows}
                />
              </div>
              <Collapse in={open}>
                {data.subRows.map((subData, i) => {
                  return (
                    <OverviewDataPoint
                      key={i}
                      label={subData.label}
                      tooltipParagraphs={subData.tooltipParagraphs}
                      calloutDatum={subData.calloutDatum}
                      getFormattedValue={subData.getFormattedValue}
                      shouldHide={(subData.calloutDatum?.value ?? 0) === 0}
                      isSubLabel={subData.isSubLabel}
                      isTotalLabel={subData.isTotalLabel}
                      subRows={subData.subRows}
                      className={styles.subBorder}
                    />
                  );
                })}
              </Collapse>
            </div>
          ) : (
            <OverviewDataPoint
              key={idx}
              label={data.label}
              tooltipParagraphs={data.tooltipParagraphs}
              calloutDatum={data.calloutDatum}
              getFormattedValue={data.getFormattedValue}
              shouldHide={(data.calloutDatum?.value ?? 0) === 0}
              isSubLabel={data.isSubLabel}
              isTotalLabel={data.isTotalLabel}
              subRows={data.subRows}
              className={
                showSeparator ? styles.totalTopBorder : styles.topBorder
              }
            />
          );
        })}
      </div>
    </div>
  );
};
