import CachedIcon from "@mui/icons-material/Cached";
import { IconButton } from "@mui/material";
import {
  DataLoadStatus,
  ElectionsLabels,
  ElectionWorkflowStageId,
  IElectionsForElectionRoundRow,
  isSomething,
  LoadingIndicator,
  NoDataAvailableError,
} from "common";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { AdminUIStore } from "../../../../../redux/store";
import { useReopenElection } from "../../../Hooks/ElectorViewGridHooks";
import { ElectorGridDialog } from "../ElectorGridDialog/ElectorGridDialog";

interface IReopenButtonProps {
  election: IElectionsForElectionRoundRow;
}
export const ReopenButton = ({ election }: IReopenButtonProps) => {
  const [reopenDialogOpen, setReopenDialogOpen] = useState<boolean>(false);
  const { handleReopenWorkflowState } = useReopenElection();
  const { electionRoundConfiguration, electionRoundConfigurationLoadStatus } =
    useSelector((state: AdminUIStore) => state.selectedElection);

  const now = new Date();
  const isElectionClosed =
    isSomething(electionRoundConfiguration) &&
    electionRoundConfiguration.value.systemCloseDate.getTime() <= now.getTime();
  const isSubmissionDateInThePast =
    isSomething(electionRoundConfiguration) &&
    electionRoundConfiguration.value.electionSubmissionDeadline.getTime() <
      now.getTime();
  const isEnabled =
    !isElectionClosed &&
    (election.currentStage === ElectionWorkflowStageId.COMPLETED ||
      isSubmissionDateInThePast);

  const nonSuccessComponent =
    electionRoundConfigurationLoadStatus === DataLoadStatus.UNSUCCESSFUL ||
    electionRoundConfigurationLoadStatus === DataLoadStatus.EMPTY_RESPONSE ? (
      <NoDataAvailableError />
    ) : (
      <LoadingIndicator />
    );

  return electionRoundConfigurationLoadStatus === DataLoadStatus.SUCCESSFUL ? (
    <>
      <IconButton
        aria-label="reopen-election"
        disabled={!isEnabled}
        onClick={() => setReopenDialogOpen(true)}
      >
        <CachedIcon />
      </IconButton>
      <ElectorGridDialog
        isOpen={reopenDialogOpen}
        handleClose={() => setReopenDialogOpen(false)}
        title={ElectionsLabels.DO_YOU_WANT_TO_REVISE}
        dialogContent={ElectionsLabels.REOPEN_ELECTION_CONFIRMATION_MESSAGE(
          election.mdmInvestmentVehicleId,
          election.name
        )}
        handleNext={() => {
          handleReopenWorkflowState(election.investmentVehicleId);
          setReopenDialogOpen(false);
        }}
      />
    </>
  ) : (
    nonSuccessComponent
  );
};
