import { useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator";
import { BreakpointConstants } from "../../../../constants/BreakpointConstants";
import { DataLoadStatus } from "../../../../constants/enums";
import { SummaryLabel } from "../../../../constants/LabelAndTooltipConstants";
import { selectCallout } from "../../../../redux/selectors";
import {
  SelectRestrictedEquityTotal,
  selectUnvestedEquityTotal,
} from "../../../../redux/selectors/equitySelectors";
import { IBaseStore } from "../../../../redux/store";
import { nothing } from "../../../../types/typeUtils";
import { getCalloutBannerData } from "../../../../utils/calloutBannerUtils";
import {
  getCurrencyFormattedValue,
  splitWithNewline,
} from "../../../../utils/formatters";
import styles from "./CalloutBanner.module.scss";

interface ICalloutDataPoint {
  label: string;
  value: number;
}

type CalloutBannerProps = {
  hasViewInternalInvestments: boolean;
  internalInvestmentDataLoadStatus: DataLoadStatus;
  hasViewStockEntitlement: boolean;
  hasViewRestrictedEquity: boolean;
  userWelcomeComponent?: React.ReactNode;
};

type CalloutsProps = {
  callouts: ICalloutDataPoint[];
  showLoading: boolean;
  total: number;
  shouldDisplayUser: boolean;
};

const CalloutLabel = ({ label }: { label: string }) => {
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  return isMobile
    ? label
    : splitWithNewline(label, 2).map((chunk, index) =>
        chunk === "\n" ? <br key={index} /> : <span key={index}>{chunk}</span>
      );
};

const Callouts = ({
  total,
  shouldDisplayUser,
  callouts,
  showLoading,
}: CalloutsProps) => {
  return showLoading ? (
    <LoadingIndicator className={styles.loading} />
  ) : (
    <div
      className={clsx(styles.callouts, {
        [styles.noUser]: !shouldDisplayUser,
      })}
    >
      <div
        className={clsx(styles.total, {
          [styles.noUser]: !shouldDisplayUser,
        })}
      >
        <div className={styles.label}>{SummaryLabel.TOTAL}</div>
        <div className={styles.value}>{getCurrencyFormattedValue(total)}</div>
      </div>
      <div className={styles.breakdown}>
        {callouts.map((callout: ICalloutDataPoint, idx) => {
          return (
            <div className={styles.callout} key={idx}>
              {idx !== 0 ? <div className={styles.separator} /> : null}
              <div className={styles.data}>
                <div className={styles.label}>
                  <CalloutLabel label={callout.label} />
                </div>
                <div className={styles.value}>
                  {getCurrencyFormattedValue(callout.value)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const CalloutBanner = ({
  userWelcomeComponent,
  internalInvestmentDataLoadStatus,
  hasViewStockEntitlement,
  hasViewRestrictedEquity,
  hasViewInternalInvestments,
}: CalloutBannerProps) => {
  const shouldDisplayUser = Boolean(userWelcomeComponent);
  const restrictedEquity = useSelector(SelectRestrictedEquityTotal);
  const unvestedEquity = useSelector(selectUnvestedEquityTotal);
  const equity = hasViewRestrictedEquity ? restrictedEquity : unvestedEquity;
  const { calloutData } = useSelector((store: IBaseStore) =>
    selectCallout(store, true)
  );

  // if admin/investor doesn't have internal investments set callout data to nothing to avoid displaying the data in total.
  if (!hasViewInternalInvestments) {
    calloutData.realized = nothing;
    calloutData.unrealized = nothing;
  }

  const { callouts, showLoading, total } = getCalloutBannerData(
    calloutData,
    internalInvestmentDataLoadStatus,
    hasViewStockEntitlement,
    equity
  );

  return (
    <div className={styles.header}>
      <div
        className={clsx(styles.headerContent, {
          [styles.noUser]: !shouldDisplayUser,
        })}
      >
        {shouldDisplayUser ? (
          <div className={styles.welcomeMessage}>{userWelcomeComponent}</div>
        ) : null}
        <Callouts
          callouts={callouts}
          showLoading={showLoading}
          total={total}
          shouldDisplayUser={shouldDisplayUser}
        />
      </div>
    </div>
  );
};
