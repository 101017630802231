import { IAdminClient, some } from "common";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectActiveClients } from "../../../redux/selectors/clientsSelector";
import { findInternalInvestmentClientForClientId } from "../../../utils/adminClientUtils";
import { InvestmentEntityDropdown } from "./InvestmentEntityDropdown/InvestmentEntityDropdown";

const InvestmentEntityDropdownWrapper = () => {
  const clients = useSelector(selectActiveClients);

  // Extract the clientId from the pathname
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const client = findInternalInvestmentClientForClientId(clientId, clients);
  const clientObjects: IAdminClient[] = client ? [client] : [];

  return (
    <InvestmentEntityDropdown
      internalInvestmentsClients={some(clientObjects)}
    />
  );
};

export default InvestmentEntityDropdownWrapper;
