import { Text, View } from "@react-pdf/renderer";
import { ElectionsLabels } from "common";
import React, { useMemo } from "react";

import { sharedStyles } from "../../SharedStyleSheet";
import { styles } from "./ElectionAmountTableHeaderStyleSheet";

interface IElectionAmountTableHeaderProps {
  hasForecastedInvestment: boolean;
  hasAnticipatedPriorPeriodsCapitalCalls: boolean;
  hasMandatoryCommitment: boolean;
}

export const ElectionAmountTableHeader = (
  props: IElectionAmountTableHeaderProps
) => {
  const {
    hasForecastedInvestment,
    hasAnticipatedPriorPeriodsCapitalCalls,
    hasMandatoryCommitment,
  } = props;

  const strategyStyle = useMemo(() => {
    if (!hasAnticipatedPriorPeriodsCapitalCalls && !hasMandatoryCommitment) {
      return styles.strategyNoMandatoryOrRemainingCommitment;
    }
    if (!hasAnticipatedPriorPeriodsCapitalCalls) {
      return styles.strategyNoRemainingCommitment;
    }
    if (!hasMandatoryCommitment) {
      return styles.strategyNoMandatoryCommitment;
    }
    return styles.strategy;
  }, [hasAnticipatedPriorPeriodsCapitalCalls, hasMandatoryCommitment]);

  return (
    <View style={[styles.row, sharedStyles.blackBorderBottom]}>
      <Text style={[sharedStyles.textColumn, strategyStyle]}>
        {ElectionsLabels.STRATEGY}
      </Text>
      {hasAnticipatedPriorPeriodsCapitalCalls && (
        <>
          <Text style={[sharedStyles.textColumn, styles.remainingCommitment]}>
            {ElectionsLabels.ANTICIPATED_PRIOR_PERIODS_CAPITAL_CALLS}
          </Text>
          <Text style={styles.signColumn}>{ElectionsLabels.PLUS_SIGN}</Text>
        </>
      )}
      {hasMandatoryCommitment && (
        <>
          <Text style={[sharedStyles.textColumn, styles.mandatoryCommitment]}>
            {ElectionsLabels.MANDATORY_COMMITMENT}
          </Text>
          <Text style={styles.signColumn}>{ElectionsLabels.PLUS_SIGN}</Text>
        </>
      )}
      <Text style={[sharedStyles.textColumn, styles.election]}>
        {ElectionsLabels.OPTIONAL_ELECTION}
      </Text>
      <Text style={styles.signColumn}>{ElectionsLabels.EQUAL_SIGN}</Text>
      <Text style={[sharedStyles.textColumn, styles.totalCommitment]}>
        {ElectionsLabels.TOTAL_COMMITMENT}
      </Text>
      {hasForecastedInvestment && (
        <Text style={[sharedStyles.textColumn, styles.forecastedInvestment]}>
          {ElectionsLabels.TOTAL_FORECASTED_INVESTMENT}
        </Text>
      )}
    </View>
  );
};
