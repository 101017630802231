import { DataLoadStatus } from "../constants/enums";

export const isInProgress = (...statuses: DataLoadStatus[]): boolean => {
  return statuses.some((status) =>
    [DataLoadStatus.NOT_REQUESTED, DataLoadStatus.LOADING].includes(status)
  );
};

export const isLoading = (...statuses: DataLoadStatus[]): boolean => {
  return statuses.some((status) => status === DataLoadStatus.LOADING);
};

export const isNotRequested = (...statuses: DataLoadStatus[]): boolean => {
  return statuses.some((status) => status === DataLoadStatus.NOT_REQUESTED);
};

export const isUnsuccessful = (...statuses: DataLoadStatus[]): boolean => {
  return statuses.some((status) => status === DataLoadStatus.UNSUCCESSFUL);
};

export const isSuccessful = (...statuses: DataLoadStatus[]): boolean => {
  return statuses.some((status) => status === DataLoadStatus.SUCCESSFUL);
};

export const isEmptyResponse = (...statuses: DataLoadStatus[]): boolean => {
  return statuses.some((status) => status === DataLoadStatus.EMPTY_RESPONSE);
};

export const isSettled = (...statuses: DataLoadStatus[]): boolean => {
  return statuses.some(
    (status) =>
      status === DataLoadStatus.SUCCESSFUL ||
      status === DataLoadStatus.EMPTY_RESPONSE ||
      status === DataLoadStatus.UNSUCCESSFUL
  );
};
