import { Stack, Typography, useMediaQuery } from "@mui/material";
import {
  BreakpointConstants,
  ElectionAccordionObject,
  ElectionsLabels,
  isEmptyResponse,
  reqElectionsInvestmentPortfolio,
  selectActiveElectionClientId,
  selectElectionInvestmentPortfolioLoadStatus,
  useFetchDatasetIfIdDefined,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { AccordionElectionCard } from "../../Shared/AccordionElectionCard/AccordionElectionCard";
import styles from "./CurrentInvestmentPortfolioCard.module.scss";
import { PriorElectionsCard } from "./PriorElectionsCard/PriorElectionsCard";
import { UnrealizedValueCard } from "./UnrealizedValueCard/UnrealizedValueCard";

export const CurrentInvestmentPortfolioCard = () => {
  const activeElectionClientId = useSelector(selectActiveElectionClientId);
  const electionInvestmentPortfolioLoadStatus = useSelector(
    selectElectionInvestmentPortfolioLoadStatus
  );

  const investmentPorfolioRetry = useFetchDatasetIfIdDefined(
    reqElectionsInvestmentPortfolio,
    activeElectionClientId,
    electionInvestmentPortfolioLoadStatus
  );

  return (
    <AccordionElectionCard
      headerComponent={<Header />}
      cardContentComponent={<CardContent />}
      accordionId={ElectionAccordionObject.ELECT_CURRENT_INVESTMENT_PORTFOLIO}
      additionalDataLoadStatuses={[electionInvestmentPortfolioLoadStatus]}
      additionalRetryFunctions={[investmentPorfolioRetry]}
      hideIfNoData
      noData={isEmptyResponse(electionInvestmentPortfolioLoadStatus)}
    />
  );
};

const Header = () => {
  return (
    <Stack className={styles.header}>
      <Typography className={styles.title}>
        {ElectionsLabels.CURRENT_INVESTMENT_PORTFOLIO}
      </Typography>
    </Stack>
  );
};

const CardContent = () => {
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.SMALL_MAX_WIDTH}px)`
  );

  return (
    <Stack direction="row" gap={4}>
      <PriorElectionsCard />
      {!isMobile && <UnrealizedValueCard />}
    </Stack>
  );
};
