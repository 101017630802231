import { createReducer } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import {
  EMPTY_DISTRIBUTIONS_DATA,
  EMPTY_EQUITY_DATA,
  EMPTY_STOCK_DATA,
} from "../../types/defaultTypes";
import { IEquityDataStore } from "../../types/storeTypes";
import { nothing, Optional, some } from "../../types/typeUtils";
import { recvEquityEntitlement } from "../actions/entitlementActions";
import {
  errDistributionsData,
  errEquityData,
  errStockData,
  noDistributionsData,
  noEquityData,
  noStockData,
  recvDistributionsData,
  recvEquityData,
  recvStockData,
  recvSupportedStocks,
  reqClientDistributionsData,
  reqClientEquityData,
  reqDistributionsData,
  reqEquityData,
  reqStockData,
  setActiveEquityDataId,
} from "../actions/equityActions";

const defaultState: IEquityDataStore = {
  activeEquityDataId: nothing,
  equityDataLoadStatus: DataLoadStatus.NOT_REQUESTED,
  equityData: EMPTY_EQUITY_DATA,
  supportedStocks: nothing,
  stockData: EMPTY_STOCK_DATA,
  stockDataLoadStatus: DataLoadStatus.NOT_REQUESTED,
  distributionsData: EMPTY_DISTRIBUTIONS_DATA,
  distributionsDataLoadStatus: DataLoadStatus.NOT_REQUESTED,
};

// For MVP this returns the lowest ID the employee is permissioned to
// This means a user can only be permissioned to one dataset at a time.
export const getActiveDataSetId = (
  entitlements: string[]
): Optional<string> => {
  if (entitlements === undefined || entitlements.length === 0) {
    return nothing;
  }
  // Entitlements come in form "investor:<<employeeNumber>>""
  const extractId = (entitlement: string) => Number(entitlement.split(":")[1]);
  return some(
    String(
      Math.min(
        ...entitlements
          .filter((e) => e.startsWith("employee") || e.startsWith("investor"))
          .map(extractId)
      )
    )
  );
};

export const equityDataReducer = createReducer<IEquityDataStore>(
  defaultState,
  (builder) => {
    builder.addCase(setActiveEquityDataId, (state, action) => {
      state.activeEquityDataId = some(action.payload);
    });
    builder.addCase(recvEquityEntitlement, (state, action) => {
      state.activeEquityDataId = getActiveDataSetId(action.payload);
    });
    builder.addCase(reqEquityData, (state) => {
      state.equityDataLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(reqClientEquityData, (state) => {
      state.equityDataLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvEquityData, (state, action) => {
      state.equityDataLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.equityData = action.payload;
    });
    builder.addCase(noEquityData, (state) => {
      state.equityDataLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.equityData = EMPTY_EQUITY_DATA;
    });
    builder.addCase(errEquityData, (state, action) => {
      state.equityDataLoadStatus = action.payload;
      state.equityData = EMPTY_EQUITY_DATA;
    });
    builder.addCase(reqDistributionsData, (state) => {
      state.distributionsDataLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(reqClientDistributionsData, (state) => {
      state.distributionsDataLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvDistributionsData, (state, action) => {
      state.distributionsDataLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.distributionsData = action.payload;
    });
    builder.addCase(noDistributionsData, (state) => {
      state.distributionsDataLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.distributionsData = EMPTY_DISTRIBUTIONS_DATA;
    });
    builder.addCase(errDistributionsData, (state, action) => {
      state.distributionsDataLoadStatus = action.payload;
      state.distributionsData = EMPTY_DISTRIBUTIONS_DATA;
    });
    builder.addCase(reqStockData, (state) => {
      state.stockDataLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvStockData, (state, action) => {
      state.stockDataLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.stockData = action.payload;
    });
    builder.addCase(noStockData, (state) => {
      state.stockDataLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.stockData = EMPTY_STOCK_DATA;
    });
    builder.addCase(errStockData, (state, action) => {
      state.stockDataLoadStatus = action.payload;
    });
    builder.addCase(recvSupportedStocks, (state, action) => {
      state.supportedStocks = action.payload;
    });
  }
);
