import { createSelector } from "@reduxjs/toolkit";

import { CalloutPersona } from "../../constants/enums";
import {
  ICallout,
  ICalloutData,
  IInvestmentBreakdownDatum,
} from "../../types/dataTypes";
import { EMPTY_INVESTMENT_BREAKDOWN_DATUM } from "../../types/defaultTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, some } from "../../types/typeUtils";
import {
  hasAnyRealizedData,
  hasAnyUnrealizedData,
} from "../../utils/calloutUtils";
import { convertToRoundedValidNumber } from "../../utils/converters";
import { isInProgress } from "../../utils/dataLoadUtils";
import { hasCapitalInvestedData } from "../../utils/historicalSummaryUtils";
import { hasFinancingData, LoansCardUtilization } from "../../utils/loanUtils";
import {
  selectActiveInvestmentEntityData,
  selectInternalInvestmentDataLoadStatus,
  selectViewDataStore,
} from "./baseStoreSelectors";

export const getLevelZeroTree = (
  investmentBreakdown: IInvestmentBreakdownDatum[]
): IInvestmentBreakdownDatum => {
  const levelZeroRows = investmentBreakdown.filter(
    (investmentBreakdownItem) => Number(investmentBreakdownItem.treeLevel) === 0
  );
  return levelZeroRows.length > 0
    ? levelZeroRows[0]
    : EMPTY_INVESTMENT_BREAKDOWN_DATUM;
};

export const getICalloutData = (
  investmentBreakdown: IInvestmentBreakdownDatum,
  totalFinancingBalanceQSTMT: number
): ICalloutData => {
  const realizedProceeds = investmentBreakdown.realizedProceeds;
  const unrealizedValue = investmentBreakdown.unrealizedValue;

  return {
    realized: isSomething(realizedProceeds)
      ? some({
          realizedCarry: {
            asOfDate: realizedProceeds.value.asOfDate,
            value: realizedProceeds.value.carriedInterest,
          },
          realizedOptionalAndMandatoryInvestments: {
            asOfDate: realizedProceeds.value.asOfDate,
            value: realizedProceeds.value.sbsAndMandatoryInvestments,
          },
          returnOfCapital: {
            asOfDate: realizedProceeds.value.asOfDate,
            value: realizedProceeds.value.returnOfCapital,
          },
          realizedGainLoss: {
            asOfDate: realizedProceeds.value.asOfDate,
            value: realizedProceeds.value.gainLoss,
          },
          totalRealizedProceeds: {
            asOfDate: realizedProceeds.value.asOfDate,
            value: realizedProceeds.value.total,
          },
        })
      : nothing,
    unrealized: isSomething(unrealizedValue)
      ? some({
          unrealizedCarry: {
            asOfDate: unrealizedValue.value.asOfDate,
            value: unrealizedValue.value.carriedInterest,
          },
          unrealizedOptionalAndMandatoryInvestments: {
            asOfDate: unrealizedValue.value.asOfDate,
            value: unrealizedValue.value.sbsAndMandatoryInvestments,
          },
          unrealizedCapitalInvested: {
            asOfDate: unrealizedValue.value.asOfDate,
            value: convertToRoundedValidNumber(
              unrealizedValue.value.remainingCapitalInvested
            ),
          },
          unrealizedGainLoss: {
            asOfDate: unrealizedValue.value.asOfDate,
            value: convertToRoundedValidNumber(unrealizedValue.value.gainLoss),
          },
          totalUnrealizedValue: {
            asOfDate: unrealizedValue.value.asOfDate,
            value: convertToRoundedValidNumber(unrealizedValue.value.total),
          },
          loans: {
            asOfDate: unrealizedValue.value.asOfDate,
            value: convertToRoundedValidNumber(totalFinancingBalanceQSTMT),
          },
          netUnrealizedValue: {
            asOfDate: unrealizedValue.value.asOfDate,
            value: convertToRoundedValidNumber(
              unrealizedValue.value.total - totalFinancingBalanceQSTMT
            ),
          },
        })
      : nothing,
  };
};

const selectInvestmentBreakdown = createSelector(
  selectActiveInvestmentEntityData,
  selectInternalInvestmentDataLoadStatus,
  (
    { investmentBreakdown, financingBalance },
    internalInvestmentDataLoadStatus
  ) => {
    return {
      internalInvestmentDataLoadStatus,
      data: { investmentBreakdown, financingBalance },
    };
  }
);

export const selectCallout = createSelector(
  selectInvestmentBreakdown,
  ({ internalInvestmentDataLoadStatus, data }) => {
    const emptyCallout: ICallout = {
      calloutData: {
        realized: nothing,
        unrealized: nothing,
      },
      calloutPersona: CalloutPersona.NON_CARRY,
    };

    if (isInProgress(internalInvestmentDataLoadStatus)) {
      return emptyCallout;
    }

    const hasTotalFinancingBalanceQSTMT = isSomething(data.financingBalance);

    if (
      data.investmentBreakdown.length === 0 &&
      !hasTotalFinancingBalanceQSTMT
    ) {
      return emptyCallout;
    }

    const levelZeroTree = getLevelZeroTree(data.investmentBreakdown);
    const calloutData = getICalloutData(
      levelZeroTree,
      isSomething(data.financingBalance)
        ? data.financingBalance.value.totalFinancingBalanceQSTMT
        : 0
    );

    const hasRealizedCarry =
      isSomething(calloutData.realized) &&
      calloutData.realized.value.realizedCarry.value !== 0;
    const hasUnrealizedCarry =
      isSomething(calloutData.unrealized) &&
      calloutData.unrealized.value.unrealizedCarry.value !== 0;
    const calloutPersona =
      hasRealizedCarry || hasUnrealizedCarry
        ? CalloutPersona.CARRY
        : CalloutPersona.NON_CARRY;

    const callout: ICallout = {
      calloutData: calloutData,
      calloutPersona,
    };

    return callout;
  }
);

export const selectCanViewDashboard = createSelector(
  selectActiveInvestmentEntityData,
  selectCallout,
  selectViewDataStore,
  ({ entityId }, { calloutData }, { selectedEntity }) => {
    return (
      isSomething(entityId) &&
      isSomething(selectedEntity) &&
      entityId.value === selectedEntity.value.clientId &&
      isSomething(calloutData.unrealized) &&
      calloutData.unrealized.value.totalUnrealizedValue.value !== 0
    );
  }
);

export const selectCanViewInvestmentsAndCarry = createSelector(
  selectActiveInvestmentEntityData,
  selectCallout,
  selectViewDataStore,
  (
    { entityId, financingBalance, historicalSummary },
    { calloutData },
    { selectedEntity }
  ) => {
    return (
      isSomething(entityId) &&
      isSomething(selectedEntity) &&
      entityId.value === selectedEntity.value.clientId &&
      ((isSomething(financingBalance) &&
        hasFinancingData(
          financingBalance.value,
          LoansCardUtilization.INCLUDE_UTILIZATION
        )) ||
        hasAnyRealizedData(calloutData.realized) ||
        hasAnyUnrealizedData(calloutData.unrealized) ||
        hasCapitalInvestedData(historicalSummary))
    );
  }
);
