import { SelectChangeEvent } from "@mui/material";
import {
  BaseEntityDropdown,
  ClientSelectLabels,
  Entity,
  EntityDropdownStyle,
  EntitySelectLabels,
  getEntityDropdownItems,
  IAdminClient,
  IBaseStore,
  IDropdownItemData,
  IInvestmentEntityDropdownItem,
  isSomething,
  Optional,
  selectActiveInvestmentEntityDropdownItemId,
  setSelectedEntity,
} from "common";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectActiveClients } from "../../../../redux/selectors/clientsSelector";
import styles from "./InvestmentEntityDropdown.module.scss";

interface InvestmentEntityDropdownProps {
  internalInvestmentsClients: Optional<IAdminClient[]>;
  clientsOnly?: boolean;
  styleVariant?: EntityDropdownStyle;
}
export const InvestmentEntityDropdown = (
  props: InvestmentEntityDropdownProps
) => {
  const {
    internalInvestmentsClients,
    clientsOnly = false,
    styleVariant = EntityDropdownStyle.DEFAULT,
  } = props;

  const clients = useSelector(selectActiveClients);

  const activeInvestmentEntityDropdowmItem = useSelector((state: IBaseStore) =>
    selectActiveInvestmentEntityDropdownItemId(state, clientsOnly)
  );

  const dispatch = useDispatch();

  // Calculate dropdown items using useMemo for performance optimization
  const investmentEntityDropdownItems = useMemo(() => {
    if (!isSomething(internalInvestmentsClients)) {
      return [];
    }

    if (internalInvestmentsClients.value.length === 0) {
      return [];
    }

    const clients = [...internalInvestmentsClients.value];
    return getEntityDropdownItems(clients, clientsOnly);
  }, [clientsOnly, internalInvestmentsClients]);

  /**
   * Sets the seleted investment entity menu item.
   * @param event A select change event
   */
  const onInvestmentEntityChange = (event: SelectChangeEvent) => {
    const [clientId, investmentVehicleId] = event.target.value.split(":");

    const selectedClient = isSomething(clients)
      ? clients.value.find((client: IAdminClient) => client.id === clientId)
      : undefined;

    if (selectedClient) {
      const selectedEntity: Entity = {
        clientId: clientId,
        investmentVehicleId: investmentVehicleId,
        mdmClientId: selectedClient.mdmOId,
      };

      dispatch(setSelectedEntity(selectedEntity));
    }
  };

  const getMenuItemTextClass = (
    investmentEntityDropdownItem: IInvestmentEntityDropdownItem
  ): string => {
    if (investmentEntityDropdownItem.isClient) {
      return investmentEntityDropdownItem.id ===
        activeInvestmentEntityDropdowmItem
        ? styles.selectedClientText
        : styles.clientText;
    } else {
      return investmentEntityDropdownItem.id ===
        activeInvestmentEntityDropdowmItem
        ? styles.selectedInvestmentVehicleText
        : styles.investmentVehicleText;
    }
  };

  const getMenuItemData = (
    item: IInvestmentEntityDropdownItem
  ): IDropdownItemData => {
    const label = item.displayName;
    const classes = getMenuItemTextClass(item);
    return { label, classes };
  };

  const getActiveItemLabel = (id: string): string => {
    const foundDropdownItem = investmentEntityDropdownItems.find(
      (dropdownItem) => dropdownItem.id == id
    );
    const label = foundDropdownItem?.activeDisplayName ?? "-";
    return label;
  };

  return (
    <BaseEntityDropdown
      items={investmentEntityDropdownItems}
      getActiveItemLabel={getActiveItemLabel}
      getMenuItemData={getMenuItemData}
      onSelectionChange={onInvestmentEntityChange}
      selectedValue={activeInvestmentEntityDropdowmItem}
      subheaderLabel={
        clientsOnly
          ? ClientSelectLabels.DEFAULT_TEXT
          : EntitySelectLabels.DEFAULT_TEXT
      }
      styleVariant={styleVariant}
    />
  );
};
