import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ElectionsLabels, IElectionsForElectionRoundRow } from "common";
import React, { useState } from "react";

import { useRemoveElection } from "../../../Hooks/ElectorViewGridHooks";
import { ElectorGridDialog } from "../ElectorGridDialog/ElectorGridDialog";

interface IDeleteButtonProps {
  election: IElectionsForElectionRoundRow;
  disabled: boolean;
}
export const DeleteButton = ({ election, disabled }: IDeleteButtonProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const { handleRemoveElectionParticipant } = useRemoveElection();

  return (
    <>
      <IconButton
        aria-label="remove-election"
        onClick={() => setDeleteDialogOpen(true)}
        disabled={disabled}
      >
        <Close />
      </IconButton>
      <ElectorGridDialog
        isOpen={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        title={ElectionsLabels.DO_YOU_WANT_TO_REMOVE}
        dialogContent={ElectionsLabels.REMOVE_ELECTION_CONFIRMATION_MESSAGE(
          election.mdmInvestmentVehicleId,
          election.name
        )}
        handleNext={() => {
          handleRemoveElectionParticipant(election.investmentVehicleId);
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};
