import { createAction } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import { DocumentRequest } from "../../services/documentsService";
import {
  IDocumentClients,
  IDocumentResponse,
  IDocumentsClient,
  IDocumentSortOptions,
} from "../../types/dataTypes";
import {
  ColumnStates,
  DocumentColumnFilterOptions,
} from "../../types/documentSettingTypes";
import { Optional } from "../../types/typeUtils";

export const reqDocumentClients = createAction<boolean>("REQ_DOCUMENT_CLIENTS");
export const recvDocumentClients = createAction<Optional<IDocumentClients>>(
  "RECV_DOCUMENT_CLIENTS"
);
export const errDocumentClients = createAction<DataLoadStatus>(
  "ERR_DOCUMENT_CLIENTS"
);

export const updateDocumentColumnSettings = createAction<ColumnStates>(
  "RECV_DOC_COL_SELECTOR_SETTINGS"
);

export const reqAvailableDocumentFilters = createAction<IDocumentsClient>(
  "REQ_AVAIL_DOC_FILTERS"
);
export const errAvailableDocumentFilters = createAction<DataLoadStatus>(
  "ERR_AVAIL_DOC_FILTERS"
);
export const updateDocumentAvailableFilterConfig =
  createAction<DocumentColumnFilterOptions>(
    "UPDATE_DOCUMENT_AVAIL_FILTER_CONFIG"
  );
export const updateDocumentSearchTerm = createAction<Optional<string>>(
  "UPDATE_DOC_SEARCH_TERM"
);
export const updateDocumentSortOptions = createAction<
  Optional<IDocumentSortOptions>
>("UPDATE_DOC_SORT_OPTIONS");

export const reqDocuments = createAction<DocumentRequest>("REQ_DOCUMENTS");
export const reqDocumentsForSearch = createAction<DocumentRequest>(
  "REQ_DOCUMENTS_FOR_SEARCH"
);
export const recvDocuments = createAction<IDocumentResponse>("RECV_DOCUMENTS");
export const errDocuments = createAction("ERR_DOCUMENT_CLIENTS");

export const toggleDocumentSelection = createAction<number>(
  "TOGGLE_DOCUMENT_SELECTION"
);
export const toggleVisibleDocumentSelection = createAction<boolean>(
  "TOGGLE_VISIBLE_DOCUMENT_SELECTION"
);
export const deselectAllDocuments = createAction("DESELECT_ALL_DOCUMENTS");

export const markDocumentsAsDownloaded = createAction<number[]>(
  "MARK_DOCUMENTS_AS_DOWNLOADED"
);

export const shiftSelectDocuments = createAction<number>(
  "SHIFT_SELECT_DOCUMENTS"
);
