import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { BankAccountConstants } from "../../constants/BankAccountConstants";
import { DataLoadStatus } from "../../constants/enums";
import {
  BankAccountAssignment,
  BankAccountsResponse,
  GPBankAccount,
  GPInvestmentVehicle,
  GPPendingBankAccount,
  IBankAccountsStore,
  ICountry,
  ICurrency,
  SelectableBankAccount,
  TReviewAndSignFormBankAccounts,
  UserAddedBankAccount,
} from "../../types/bankAccountDataTypes";
import { Entity } from "../../types/dataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, some } from "../../types/typeUtils";
import {
  findActiveAccount,
  getBankAccountOptions,
  getReviewAndSignFormBankAccounts,
} from "../../utils/bankAccountUtils";
import { setActiveElection } from "../actions/electionsActions";
import { setSelectedEntity } from "../actions/entitlementActions";

const BLANK_SERVER_RESPONSE = {
  bankAccounts: [],
  pendingBankAccounts: [],
  investmentVehicles: [],
};

export const initialState: IBankAccountsStore = {
  isBankAccountsAdminMode: false,
  bankAccountsLoadStatus: DataLoadStatus.NOT_REQUESTED,
  bankAccountsInvestmentVehicleId: nothing,
  serverData: BLANK_SERVER_RESPONSE,
  selectedBankAccounts: {},
  userAddedAccounts: [],
  countries: [],
  countriesLoadStatus: DataLoadStatus.NOT_REQUESTED,
  currencies: [],
  currenciesLoadStatus: DataLoadStatus.NOT_REQUESTED,
  unsubmittedChanges: {},
  initialState: nothing,
};

export interface ReqBankAccountBody {
  id: number;
  isAdmin: boolean;
}

// Define this outside of slice because we want it to take a number as payload,
// but we would only use that in the saga.
export const addBankAccount = createAction<{
  userAddedBankAccount: UserAddedBankAccount;
}>("ADD_BANK_ACCOUNT");
export const editBankAccount = createAction<{
  userAddedBankAccount: UserAddedBankAccount;
}>("EDIT_BANK_ACCOUNT");
export const setSelectedContributionAccount = createAction<{
  contributionBankAccount: SelectableBankAccount;
  investmentVehicleId: number;
}>("SELECT_CONTRIBUTION_ACCOUNT");
export const setSelectedDistributionAccount = createAction<{
  distributionBankAccount: SelectableBankAccount;
  investmentVehicleId: number;
}>("SELECT_DISTRIBUTION_ACCOUNT");
export const reqBankAccounts =
  createAction<ReqBankAccountBody>("REQ_BANK_ACCOUNTS");
export const reqBankAccountsForClient = createAction<Entity>(
  "REQ_BANK_ACCOUNTS_FOR_CLIENT"
);

export const bankAccountsSlice = createSlice({
  name: "bankAccounts",
  initialState,
  reducers: {
    recvNoBankAccounts: (state) => {
      state.bankAccountsLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.bankAccountsInvestmentVehicleId = nothing;
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedBankAccounts = {};
    },
    recvBankAccounts: (state, action: PayloadAction<BankAccountsResponse>) => {
      state.bankAccountsLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.serverData = action.payload;
      const { selectedBankAccounts, unsubmittedChanges } = state;
      action.payload.investmentVehicles.forEach((iv) => {
        const selectedDistributionAcc = findActiveAccount(
          BankAccountAssignment.DISTRIBUTION,
          iv.mdmId,
          action.payload
        );
        const selectedContributionAcc = findActiveAccount(
          BankAccountAssignment.CONTRIBUTION,
          iv.mdmId,
          action.payload
        );
        selectedBankAccounts[iv.axiomId] = {
          investmentVehicle: iv,
          contributionBankAccount: selectedContributionAcc,
          distributionBankAccount: selectedDistributionAcc,
        };
        unsubmittedChanges[iv.axiomId] = {
          [BankAccountAssignment.CONTRIBUTION]: false,
          [BankAccountAssignment.DISTRIBUTION]: false,
        };
      });
      state.initialState = some({
        selectedBankAccounts,
        userAddedAccounts: [],
        unsubmittedChanges,
      });
    },
    errBankAccounts: (state) => {
      state.bankAccountsLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    recvNoBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.countries = [];
    },
    recvBankAccountCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.countriesLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.countries = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    errBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    recvNoBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      state.currencies = [];
    },
    recvBankAccountCurrencies: (state, action: PayloadAction<ICurrency[]>) => {
      state.currenciesLoadStatus = DataLoadStatus.SUCCESSFUL;
      state.currencies = action.payload.sort((a, b) =>
        a.currencyCode.localeCompare(b.currencyCode)
      );
    },
    errBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    },
    reqBankAccountCountries: (state) => {
      state.countriesLoadStatus = DataLoadStatus.LOADING;
      state.countries = [];
    },
    reqBankAccountCurrencies: (state) => {
      state.currenciesLoadStatus = DataLoadStatus.LOADING;
      state.currencies = [];
    },
    undoBankAccountChanges: (
      state,
      action: PayloadAction<{ investmentVehicleId: number }>
    ) => {
      if (!isSomething(state.initialState)) return;
      const { selectedBankAccounts, unsubmittedChanges, userAddedAccounts } =
        state.initialState.value;

      state.selectedBankAccounts[action.payload.investmentVehicleId] = {
        ...selectedBankAccounts[action.payload.investmentVehicleId],
      };
      state.unsubmittedChanges[action.payload.investmentVehicleId] = {
        ...unsubmittedChanges[action.payload.investmentVehicleId],
      };
      state.userAddedAccounts = userAddedAccounts;
    },
    changeContributionAccount: (state, action) => {
      const { bankAccountUniqueId, investmentVehicleId, isEditMode } =
        action.payload;
      if (
        [
          BankAccountConstants.NO_ACCOUNT_ASSIGNED,
          BankAccountConstants.NO_US_ACCOUNT,
        ].includes(bankAccountUniqueId)
      ) {
        state.selectedBankAccounts[investmentVehicleId] = {
          ...state.selectedBankAccounts[investmentVehicleId],
          contributionBankAccount: nothing,
        };
        return;
      }

      const options = getBankAccountOptions(
        state.serverData,
        state.userAddedAccounts,
        BankAccountAssignment.CONTRIBUTION,
        false
      );
      const bankAccount = options[investmentVehicleId].find(
        (acc) => acc.main.bankAccountUniqueId === bankAccountUniqueId
      );

      if (bankAccount) {
        const contributionBankAccount = {
          ...bankAccount,
          assignments: [BankAccountAssignment.CONTRIBUTION],
        };
        state.selectedBankAccounts[investmentVehicleId] = {
          ...state.selectedBankAccounts[investmentVehicleId],
          contributionBankAccount: some(contributionBankAccount),
        };
      } else {
        state.selectedBankAccounts[investmentVehicleId] = {
          ...state.selectedBankAccounts[investmentVehicleId],
          contributionBankAccount: nothing,
        };
      }

      // only set unsubmitted changes to true if chosen account is not the initial one or account was editted
      const initialContributionAccount = isSomething(state.initialState)
        ? state.initialState.value.selectedBankAccounts[investmentVehicleId]
            ?.contributionBankAccount
        : nothing;
      state.unsubmittedChanges[investmentVehicleId][
        BankAccountAssignment.CONTRIBUTION
      ] =
        !(
          isSomething(initialContributionAccount) &&
          initialContributionAccount.value.main.bankAccountUniqueId ===
            bankAccountUniqueId
        ) || isEditMode;
    },
    changeDistributionAccount: (state, action) => {
      const { bankAccountUniqueId, investmentVehicleId, isEditMode } =
        action.payload;
      const options = getBankAccountOptions(
        state.serverData,
        state.userAddedAccounts,
        BankAccountAssignment.DISTRIBUTION,
        false
      );
      const bankAccount = options[investmentVehicleId].find(
        (acc) => acc.main.bankAccountUniqueId === bankAccountUniqueId
      );

      if (bankAccount) {
        const distributionBankAccount = {
          ...bankAccount,
          assignments: [BankAccountAssignment.DISTRIBUTION],
        };
        state.selectedBankAccounts[investmentVehicleId] = {
          ...state.selectedBankAccounts[investmentVehicleId],
          distributionBankAccount: some(distributionBankAccount),
        };
      } else {
        state.selectedBankAccounts[investmentVehicleId] = {
          ...state.selectedBankAccounts[investmentVehicleId],
          distributionBankAccount: nothing,
        };
      }

      // only set unsubmitted changes to true if chosen account is not the initial one
      const initialDistributionAccount = isSomething(state.initialState)
        ? state.initialState.value.selectedBankAccounts[investmentVehicleId]
            ?.distributionBankAccount
        : nothing;
      state.unsubmittedChanges[investmentVehicleId][
        BankAccountAssignment.DISTRIBUTION
      ] =
        !(
          isSomething(initialDistributionAccount) &&
          initialDistributionAccount.value.main.bankAccountUniqueId ===
            bankAccountUniqueId
        ) || isEditMode;
    },
    submitBankAccountChanges: (
      state,
      action: PayloadAction<{ investmentVehicleId: number }>
    ) => {
      state.unsubmittedChanges[action.payload.investmentVehicleId][
        BankAccountAssignment.DISTRIBUTION
      ] = false;
      state.unsubmittedChanges[action.payload.investmentVehicleId][
        BankAccountAssignment.CONTRIBUTION
      ] = false;
    },
    submitMultipleBankAccountChanges: (
      state,
      action: PayloadAction<{ investmentVehicleIds: number[] }>
    ) => {
      action.payload.investmentVehicleIds.forEach((ivId) => {
        state.unsubmittedChanges[ivId][BankAccountAssignment.DISTRIBUTION] =
          false;
        state.unsubmittedChanges[ivId][BankAccountAssignment.CONTRIBUTION] =
          false;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedEntity, (state) => {
      state.bankAccountsInvestmentVehicleId = nothing;
      state.bankAccountsLoadStatus = DataLoadStatus.NOT_REQUESTED;
      state.serverData = BLANK_SERVER_RESPONSE;
      state.unsubmittedChanges = {};
      state.selectedBankAccounts = nothing;
      state.userAddedAccounts = [];
      state.initialState = nothing;
    });
    builder.addCase(reqBankAccounts, (state, action) => {
      state.isBankAccountsAdminMode = action.payload.isAdmin;
      state.bankAccountsLoadStatus = DataLoadStatus.LOADING;
      state.bankAccountsInvestmentVehicleId = some(action.payload.id);
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedBankAccounts = {};
      state.unsubmittedChanges = {};
      state.userAddedAccounts = [];
    });

    builder.addCase(reqBankAccountsForClient, (state) => {
      state.bankAccountsLoadStatus = DataLoadStatus.LOADING;
      state.serverData = BLANK_SERVER_RESPONSE;
      state.selectedBankAccounts = {};
      state.unsubmittedChanges = {};
      state.userAddedAccounts = [];
    });
    builder.addCase(addBankAccount, (state, action) => {
      const { userAddedBankAccount } = action.payload;
      state.userAddedAccounts.push(userAddedBankAccount);
    });
    builder.addCase(setActiveElection, (state, action) => {
      (state.bankAccountsLoadStatus = DataLoadStatus.NOT_REQUESTED),
        (state.bankAccountsInvestmentVehicleId = some(
          action.payload.investmentVehicleId
        )),
        (state.serverData = BLANK_SERVER_RESPONSE),
        (state.selectedBankAccounts[action.payload.investmentVehicleId] = {
          ...state.selectedBankAccounts[action.payload.investmentVehicleId],
          contributionBankAccount: nothing,
          distributionBankAccount: nothing,
        }),
        (state.userAddedAccounts = []),
        (state.unsubmittedChanges[action.payload.investmentVehicleId] = {
          [BankAccountAssignment.CONTRIBUTION]: false,
          [BankAccountAssignment.DISTRIBUTION]: false,
        });
    });
    builder.addCase(editBankAccount, (state, action) => {
      const matchingApprovedAccounts: GPBankAccount[] =
        state.serverData.bankAccounts.filter(
          (acc: GPBankAccount) =>
            acc.bankAccount.main.bankAccountUniqueId ===
            action.payload.userAddedBankAccount.main.bankAccountUniqueId
        );
      if (matchingApprovedAccounts.length > 0) {
        // account is in use
        const nonMatchingApprovedAccounts: GPBankAccount[] =
          state.serverData.bankAccounts.filter(
            (acc: GPBankAccount) =>
              acc.bankAccount.main.bankAccountUniqueId !==
              action.payload.userAddedBankAccount.main.bankAccountUniqueId
          );
        state.userAddedAccounts.push(action.payload.userAddedBankAccount);
        state.serverData.bankAccounts = [...nonMatchingApprovedAccounts];
      } else {
        // account not in approved list, so must be a user added one!
        state.userAddedAccounts = state.userAddedAccounts.map(
          (acc: UserAddedBankAccount) => {
            return acc.main.bankAccountUniqueId ===
              action.payload.userAddedBankAccount.main.bankAccountUniqueId
              ? action.payload.userAddedBankAccount
              : acc;
          }
        );
      }
    });
  },
  selectors: {
    selectElegibleContributionAccounts: createSelector(
      [
        (sliceState: IBankAccountsStore) => sliceState.serverData,
        (sliceState: IBankAccountsStore) => sliceState.userAddedAccounts,
      ],
      (serverData, userAddedAccounts) => {
        return getBankAccountOptions(
          serverData,
          userAddedAccounts,
          BankAccountAssignment.CONTRIBUTION,
          true
        );
      }
    ),
    selectElegibleDistributionAccounts: createSelector(
      [
        (sliceState: IBankAccountsStore) => sliceState.serverData,
        (sliceState: IBankAccountsStore) => sliceState.userAddedAccounts,
      ],
      (serverData, userAddedAccounts) => {
        return getBankAccountOptions(
          serverData,
          userAddedAccounts,
          BankAccountAssignment.DISTRIBUTION,
          true
        );
      }
    ),
    hasPendingContribution: createSelector(
      (sliceState: IBankAccountsStore) => sliceState.serverData,
      (serverData): { [investmentVehicleId: number]: boolean } => {
        const investmentVehicles = serverData.investmentVehicles.map(
          (iv: GPInvestmentVehicle) => iv.axiomId
        );
        const allOptions: { [investmentVehicleId: number]: boolean } = {};
        investmentVehicles.forEach((investmentVehicleId: number) => {
          allOptions[investmentVehicleId] = serverData.pendingBankAccounts
            .filter(
              (account: GPPendingBankAccount) =>
                account.investmentVehicle.axiomId === investmentVehicleId
            )
            .some((account: GPPendingBankAccount) =>
              account.assignments.includes(BankAccountAssignment.CONTRIBUTION)
            );
        });
        return allOptions;
      }
    ),
    hasPendingDistribution: createSelector(
      (sliceState: IBankAccountsStore) => sliceState.serverData,
      (serverData): { [investmentVehicleId: number]: boolean } => {
        const investmentVehicles = serverData.investmentVehicles.map(
          (iv: GPInvestmentVehicle) => iv.axiomId
        );
        const allOptions: { [investmentVehicleId: number]: boolean } = {};
        investmentVehicles.forEach((investmentVehicleId: number) => {
          allOptions[investmentVehicleId] = serverData.pendingBankAccounts
            .filter(
              (account: GPPendingBankAccount) =>
                account.investmentVehicle.axiomId === investmentVehicleId
            )
            .some((account: GPPendingBankAccount) =>
              account.assignments.includes(BankAccountAssignment.DISTRIBUTION)
            );
        });
        return allOptions;
      }
    ),
    reviewAndSignFormBankAccounts: (sliceState) => {
      const { selectedBankAccounts, unsubmittedChanges, serverData } =
        sliceState;

      return getReviewAndSignFormBankAccounts(
        selectedBankAccounts,
        serverData.investmentVehicles,
        unsubmittedChanges
      ) as TReviewAndSignFormBankAccounts[];
    },
  },
});

export const bankAccountsReducer = bankAccountsSlice.reducer;
export const {
  errBankAccounts,
  recvBankAccounts,
  recvNoBankAccounts,
  errBankAccountCountries,
  recvBankAccountCountries,
  recvNoBankAccountCountries,
  errBankAccountCurrencies,
  recvBankAccountCurrencies,
  recvNoBankAccountCurrencies,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  changeContributionAccount,
  changeDistributionAccount,
  undoBankAccountChanges,
  submitBankAccountChanges,
  submitMultipleBankAccountChanges,
} = bankAccountsSlice.actions;
export const {
  selectElegibleContributionAccounts,
  selectElegibleDistributionAccounts,
  hasPendingContribution,
  hasPendingDistribution,
  reviewAndSignFormBankAccounts,
} = bankAccountsSlice.selectors;
