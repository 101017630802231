import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import clsx from "clsx";
import React from "react";

import styles from "./BaseEntityDropdown.module.scss";

export interface IItems {
  id: string | number;
}

export interface IDropdownItemData {
  label: string;
  classes: string;
}

export enum EntityDropdownStyle {
  DEFAULT,
  DASHBOARD,
}
export interface IBaseEntityDropdownProps<TItem extends IItems> {
  subheaderLabel: string;
  items: TItem[];
  selectedValue: number | string;
  onSelectionChange: (event: SelectChangeEvent) => void;
  getActiveItemLabel: (id: string) => string;
  getMenuItemData: (item: TItem) => IDropdownItemData;
  styleVariant?: EntityDropdownStyle;
}

/* 
  Always using pre-wrap as the value for white-space was causing some weird extra line breaks.
  The following function makes sure we only wrap the text if it's longer than 30 characters.
  Ticket AC said 50 char limit but this was going beyond the width of the container due to 
  large font size.
  -z
*/
const shouldWrapText = (label: string) => label.length >= 30;

export const BaseEntityDropdown = <TItem extends IItems>(
  props: IBaseEntityDropdownProps<TItem>
) => {
  const {
    subheaderLabel,
    items,
    selectedValue,
    onSelectionChange,
    getActiveItemLabel,
    getMenuItemData,
    styleVariant = EntityDropdownStyle.DEFAULT,
  } = props;

  const renderActiveItemLabel = (id: string) => {
    const label = getActiveItemLabel(id);
    const classes = getMenuItemClasses(true, shouldWrapText(label));
    return <span className={classes}>{label}</span>;
  };

  /**
   * Gets the appropiate class for the menu item being rendered
   * @returns A class name string
   */
  const getMenuItemClasses = (
    isActiveMenuItem: boolean,
    shouldWrap: boolean
  ): string => {
    return clsx(styles.itemLinePreWrap, {
      [styles.activeItem]: isActiveMenuItem,
      [styles.itemLayout]: isActiveMenuItem,
      [styles.wrapText]: shouldWrap,
    });
  };

  const buildDropdownItem = (item: TItem, i: number) => {
    const { label, classes } = getMenuItemData(item);
    return (
      <MenuItem
        key={i}
        value={item.id}
        className={getMenuItemClasses(false, shouldWrapText(label))}
      >
        <span className={classes}>{label}</span>
        {item.id === selectedValue && (
          <span>
            <CheckCircleIcon className={styles.selectedItemIcon} />
          </span>
        )}
      </MenuItem>
    );
  };

  const getSelectContainerClasses = () => {
    switch (styleVariant) {
      case EntityDropdownStyle.DASHBOARD:
        return styles.selectContainerDashboardVariant;
      case EntityDropdownStyle.DEFAULT:
        return styles.selectContainer;
      default:
        return "";
    }
  };

  return (
    <div
      className={clsx(styles.selectContainerBase, getSelectContainerClasses())}
    >
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          disableScrollLock: true,
          marginThreshold: undefined,
          classes: {
            paper: styles.menuContainer,
          },
        }}
        className={styles.select}
        renderValue={renderActiveItemLabel}
        value={selectedValue.toString()}
        onChange={onSelectionChange}
      >
        <ListSubheader className={styles.selectSubHeader}>
          {subheaderLabel}
        </ListSubheader>
        {items.map(buildDropdownItem)}
      </Select>
    </div>
  );
};
