import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { DataLoadStatus } from "../../constants/enums";
import { BANK_ACCOUNT_PERMISSIONS } from "../../constants/permissions";
import {
  getAdminEntitlements,
  getAllEntitlements,
  getBankAccountEntitlements,
} from "../../services/entitlementDataService";
import { AuthProviderName } from "../../services/environmentResolver";
import {
  IBankAccountInvestmentVehicle,
  IBankAccountPermittedClient,
  IBankAccountsCapabilities,
  IBankAccountsEntitlements,
} from "../../types/bankAccountDataTypes";
import {
  IClientDto,
  IDocumentClients,
  IDocumentsClient,
  IPermittedEntities,
} from "../../types/dataTypes";
import {
  ClientId,
  IElectionInvestmentVehicle,
  IElectionPermissions,
  IPermittedClient,
} from "../../types/electionDataTypes";
import {
  AdminEntitlementSummary,
  ViewElectionResultPage,
} from "../../types/storeTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import { setNextAuthProvider } from "../../utils/authUtils";
import { recvDocumentClients } from "../actions/documentActions";
import { recvPermittedElectionClients } from "../actions/electionsActions";
import {
  errAdminEntitlements,
  errDocumentsEntitlements,
  errEquityEntitlements,
  errInternalInvestmentDataPermittedEntities,
  errRestrictedEquityEntitlement,
  errStockEntitlements,
  errV2DashboardEquityCardEntitlement,
  recvAdminEntitlements,
  recvBankAccountsEntitlements,
  recvCanViewElectionResultPage,
  recvDocumentsEntitlement,
  recvElectionAdminEntitlements,
  recvEquityEntitlement,
  recvInternalInvestmentDataPermittedEntities,
  recvRestrictedEquityEntitlement,
  recvStockEntitlement,
  recvV2DashboardEquityCardEntitlement,
  updateAllClients,
} from "../actions/entitlementActions";
import { reqEquityClients, reqEquityEmployees } from "../actions/equityActions";
import { reqInternalInvestmentClients } from "../actions/internalInvestmentActions";

function* resolveInvestmentDataPermittedEntities(
  permittedEntities: IPermittedEntities
) {
  if (permittedEntities != undefined) {
    // depending on output, put permitted entities to the store
    if (permittedEntities.clients.length === 0) {
      yield put(recvInternalInvestmentDataPermittedEntities(nothing));
    } else {
      yield put(
        recvInternalInvestmentDataPermittedEntities(some(permittedEntities))
      );
    }
  } else {
    yield put(
      errInternalInvestmentDataPermittedEntities(DataLoadStatus.UNSUCCESSFUL)
    );
  }
}

export function* updateClientsAfterInternalInvestmentEntitlementLoaded(
  action: PayloadAction<Optional<IPermittedEntities>>
) {
  if (!isSomething(action.payload)) {
    return;
  }

  const permittedEntities = action.payload.value;
  yield put(updateAllClients(permittedEntities.clients));
}

function* resolveEquityDataEntitlements(equityDataEntitlements: string[]) {
  if (equityDataEntitlements != undefined) {
    //filter out the admin level from equity data entitlement, if it exists for this user
    const equityAdminIndex = equityDataEntitlements.indexOf("");
    if (equityAdminIndex !== -1) {
      equityDataEntitlements.splice(equityAdminIndex, 1);
    }
    // depending on output, put equity Data and stocks Entitlements to store
    if (
      equityDataEntitlements === undefined ||
      equityDataEntitlements.length === 0
    ) {
      yield put(recvEquityEntitlement([]));
      yield put(recvStockEntitlement(false));
    } else {
      yield put(recvEquityEntitlement(equityDataEntitlements));
      yield put(recvStockEntitlement(true));
    }
  } else {
    yield put(errEquityEntitlements(DataLoadStatus.UNSUCCESSFUL));
    yield put(errStockEntitlements(DataLoadStatus.UNSUCCESSFUL));
  }
}

function* resolveRestrictedEquityEntitlement(entitlements: string[]) {
  if (entitlements === undefined || entitlements.length === 0) {
    yield put(errRestrictedEquityEntitlement(DataLoadStatus.EMPTY_RESPONSE));
  }

  yield put(
    recvRestrictedEquityEntitlement(
      some(entitlements.includes("restricted-equity"))
    )
  );
}

function* resolveV2DashboardEquityCardEntitlement(entitlements: string[]) {
  if (entitlements === undefined || entitlements.length === 0) {
    yield put(
      errV2DashboardEquityCardEntitlement(DataLoadStatus.EMPTY_RESPONSE)
    );
  }

  yield put(
    recvV2DashboardEquityCardEntitlement(
      some(entitlements.includes("v2-dashboard-equity-card"))
    )
  );
}
// For MVP this returns the lowest ID the employee is permissioned to
// This means a user can only be permissioned to one dataset at a time.
export const hasElectionsViewEntitlement = (
  entitlements: string[]
): boolean => {
  if (entitlements === undefined || entitlements.length === 0) {
    return false;
  }
  return entitlements.includes("elections-page");
};

const requiredClientCapabilities = [
  "read-election-iv-configuration",
  "read-election-workflow-state",
  "write-election-workflow-state",
  "read-investment-portfolio",
];

const requiredGlobalCapabilities = [
  "read-election-round-configuration",
  "read-election-document",
];

const requiredElectionAdminCapabilities = [
  "read-election-round-configuration",
  "write-election-round-configuration",
  "read-election-iv-configuration",
  "write-election-iv-configuration",
  "read-election-workflow-state",
  "read-investment-portfolio",
  "read-strategies",
  "write-strategies",
  "read-election-document",
  "write-election-document",
  "read-election-permission",
  "write-election-permission",
  "read-election-result",
];

const requiredAdminOnlyScopedCapabilities = ["write-election-workflow-state"];

function* resolveElectionsEntitlements(
  electionsEntitlements: IElectionPermissions
) {
  if (
    electionsEntitlements === undefined ||
    Object.keys(electionsEntitlements.clientCapabilities).length === 0
  ) {
    yield put(recvPermittedElectionClients(nothing));
  } else {
    const permittedClients: IPermittedClient[] = [];
    const foundClientIds: ClientId[] = Object.keys(
      electionsEntitlements.clientCapabilities
    ).map(Number);
    const hasAllGlobalReqs = requiredGlobalCapabilities.every(
      (permission: string) =>
        electionsEntitlements.globalCapabilities.includes(permission)
    );
    if (hasAllGlobalReqs) {
      foundClientIds.forEach((clientId: ClientId) => {
        const clientPermissions: string[] =
          electionsEntitlements.clientCapabilities[clientId].capabilities;
        const hasAllClientLevelReqs = requiredClientCapabilities.every(
          (permission: string) => clientPermissions.includes(permission)
        );
        if (hasAllClientLevelReqs) {
          permittedClients.push({
            clientId: clientId,
            clientName:
              electionsEntitlements.clientCapabilities[clientId].clientName,
            clientShortName:
              electionsEntitlements.clientCapabilities[clientId].shortName ??
              electionsEntitlements.clientCapabilities[clientId].clientName,
            investmentVehicles:
              electionsEntitlements.clientCapabilities[clientId]
                .investmentVehicles,
          });
        }
      });
    }

    if (permittedClients.length === 0) {
      yield put(recvPermittedElectionClients(nothing));
    } else {
      yield put(recvPermittedElectionClients(some(permittedClients)));
    }

    const electionsClientDtos: IClientDto[] = permittedClients.map(
      (client: IPermittedClient) => {
        return {
          id: client.clientId.toString(),
          name: client.clientName,
          shortName: client.clientShortName,
          investmentVehicles: client.investmentVehicles.map(
            (electionIV: IElectionInvestmentVehicle) => {
              return {
                id: electionIV.investmentVehicleId.toString(),
                name: electionIV.name,
                shortName: electionIV.name,
              };
            }
          ),
        };
      }
    );
    yield put(updateAllClients(electionsClientDtos));
  }
}

const requiredBankAccountsPermissions = [
  BANK_ACCOUNT_PERMISSIONS.READ.BANK_ACCOUNTS,
];

function* resolveBankAccountsEntitlements(
  bankAccountsEntitlements: IBankAccountsCapabilities
) {
  const permittedClients: IBankAccountPermittedClient[] = [];
  const clientIds: number[] = Object.keys(
    bankAccountsEntitlements?.clientCapabilities || {}
  ).map(Number);
  const hasAllGlobalReqs = requiredBankAccountsPermissions.every(
    (permission: string) =>
      bankAccountsEntitlements?.globalCapabilities?.includes(permission)
  );

  clientIds.forEach((clientId: ClientId) => {
    const permittedInvestmentVehicles: IBankAccountInvestmentVehicle[] = [];
    const client = bankAccountsEntitlements.clientCapabilities[clientId];
    client.investmentVehicles.forEach((iv: IBankAccountInvestmentVehicle) => {
      const hasReqPermissions = requiredBankAccountsPermissions.every(
        (permission: string) => iv.capabilities.includes(permission)
      );
      if (hasReqPermissions) {
        permittedInvestmentVehicles.push(iv);
      }
    });
    if (permittedInvestmentVehicles.length > 0) {
      permittedClients.push({
        clientId,
        clientName:
          bankAccountsEntitlements.clientCapabilities[clientId].clientName,
        investmentVehicles: permittedInvestmentVehicles,
      });
    }
  });

  yield put(
    recvBankAccountsEntitlements(
      some({
        globalCapabilities: hasAllGlobalReqs
          ? requiredBankAccountsPermissions
          : [],
        permittedBankAccountClients: permittedClients,
      })
    )
  );
}

export function* updateClientsFromBankAccounts(
  action: PayloadAction<Optional<IBankAccountsEntitlements>>
) {
  if (!isSomething(action.payload)) {
    return;
  }

  const permittedBankAccountClients =
    action.payload.value.permittedBankAccountClients;

  const bankAccountsClients: IClientDto[] = permittedBankAccountClients.map(
    (bankAccountClient) => {
      return {
        id: bankAccountClient.clientId.toString(),
        name: bankAccountClient.clientName,
        shortName: bankAccountClient.clientName,
        investmentVehicles: bankAccountClient.investmentVehicles.map(
          (bankAccountIV: IBankAccountInvestmentVehicle) => {
            return {
              id: bankAccountIV.investmentVehicleId.toString(),
              name: bankAccountIV.name,
              shortName: bankAccountIV.name,
            };
          }
        ),
      };
    }
  );

  yield put(updateAllClients(bankAccountsClients));
}

export const hasInternalInvestmentsAdminEntitlement = (
  entitlements: string[]
): boolean => {
  return entitlements.includes("");
};

function* resolveAdminEntitlements(adminEntitlements: AdminEntitlementSummary) {
  if (adminEntitlements != undefined) {
    yield put(recvAdminEntitlements(some(adminEntitlements)));
    if (adminEntitlements.canViewAllInternalInvestmentData) {
      yield put(reqInternalInvestmentClients());
    }
    if (adminEntitlements.canViewAllEquityData) {
      yield put(reqEquityEmployees());
      yield put(reqEquityClients());
    }
  } else {
    yield put(errAdminEntitlements(DataLoadStatus.UNSUCCESSFUL));
  }
}

function* resolveElectionAdminEntitlements(
  electionsEntitlements: IElectionPermissions,
  canViewElectionResultPage: boolean
) {
  if (
    electionsEntitlements != undefined &&
    electionsEntitlements.globalCapabilities.length > 0 &&
    electionsEntitlements.adminOnlyScopedCapabilities.length > 0
  ) {
    const isElectionsAdmin =
      requiredElectionAdminCapabilities.every((capabilty: string) =>
        electionsEntitlements.globalCapabilities.includes(capabilty)
      ) &&
      requiredAdminOnlyScopedCapabilities.every((capabilty: string) =>
        electionsEntitlements.adminOnlyScopedCapabilities.includes(capabilty)
      );
    yield put(recvElectionAdminEntitlements(isElectionsAdmin));
  } else {
    yield put(recvElectionAdminEntitlements(false));
  }
  yield put(recvCanViewElectionResultPage(canViewElectionResultPage));
}

function* resolveDocumentsClients(documentsClients: IDocumentClients) {
  if (documentsClients !== undefined) {
    if (documentsClients.clients.length === 0) {
      yield put(recvDocumentsEntitlement(some(false)));
      yield put(recvDocumentClients(nothing));
    } else {
      const defaultClientOId = documentsClients.preferredClientOId;

      yield put(recvDocumentsEntitlement(some(true)));
      yield put(
        recvDocumentClients(
          some({
            clients: documentsClients.clients,
            preferredClientOId: defaultClientOId,
          })
        )
      );
    }
  } else {
    yield put(errDocumentsEntitlements(DataLoadStatus.EMPTY_RESPONSE));
  }
}

export function* updateClientsFromDocuments(
  action: PayloadAction<Optional<IDocumentClients>>
) {
  if (!isSomething(action.payload)) {
    return;
  }

  const documentsClients = action.payload.value;
  const docClientDtos: IClientDto[] = documentsClients.clients.map(
    (client: IDocumentsClient) => {
      return {
        id: client.axiomId,
        name: client.name,
        shortName: client.shortName,
        mdmOId: client.oId,
        investmentVehicles: [],
      };
    }
  );
  yield put(updateAllClients(docClientDtos));
}

export function* fetchViewEntitlements() {
  const [
    investmentDataPermittedEntities,
    equityDataEntitlements,
    bxwealthServiceEntitlements,
    electionsEntitlements,
    documentsClients,
  ]: [
    IPermittedEntities,
    string[],
    string[],
    IElectionPermissions,
    IDocumentClients
  ] = yield call(getAllEntitlements);
  yield resolveInvestmentDataPermittedEntities(investmentDataPermittedEntities);
  yield resolveEquityDataEntitlements(equityDataEntitlements);
  yield resolveDocumentsClients(documentsClients);
  yield resolveElectionsEntitlements(electionsEntitlements);
  yield resolveRestrictedEquityEntitlement(bxwealthServiceEntitlements);
  yield resolveV2DashboardEquityCardEntitlement(bxwealthServiceEntitlements);
  yield setAuthProviderForNextSession(bxwealthServiceEntitlements);
}

export function* fetchBankAccountsEntitlements() {
  const bankAccountsEntitlements: IBankAccountsCapabilities = yield call(
    getBankAccountEntitlements
  );
  yield resolveBankAccountsEntitlements(bankAccountsEntitlements);
}

export function* fetchAdminEntitlements() {
  const [
    adminEntitlements,
    viewElectionResultPage,
    electionsEntitlements,
    featureFlags,
  ]: [
    AdminEntitlementSummary,
    ViewElectionResultPage,
    IElectionPermissions,
    string[]
  ] = yield call(getAdminEntitlements);
  yield resolveAdminEntitlements(adminEntitlements);
  yield resolveElectionAdminEntitlements(
    electionsEntitlements,
    viewElectionResultPage.canViewElectionResultPage
  );
  yield setAuthProviderForNextSession(featureFlags);
}

const setAuthProviderForNextSession = (featureFlags: string[]) => {
  // We want to set user-specific auth flags, but we can't know who the user is
  // until after they are authenticated! We are accepting this "off-by-one"
  // error while we have this  temporary feature flag in place. When a user logs
  // in, we set this cookie for what their device should use on their NEXT
  // login. This can override the global feature flag and allow the user to
  // access the "experimental" Cognito auth, while it is still in testing.
  setNextAuthProvider(
    featureFlags.includes("cognito-auth")
      ? AuthProviderName.COGNITO
      : AuthProviderName.OKTA
  );
};
