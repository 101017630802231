import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import {
  DataLoadStatus,
  DistributionUnitType,
  StockSymbol,
  UISupportedStocks,
} from "../../constants/enums";
import {
  getClientEquityData,
  getEquityClients,
  IEquityClientSources,
} from "../../services/clientEquityDataService";
import {
  getClientDistributionsData,
  getDistributionsData,
  IDistriutionsSourceDatum,
} from "../../services/distributionDataService";
import {
  getEquityData,
  getEquityEmployees,
  IEmployeeSources,
  IEquitySourceData,
} from "../../services/equityDataService";
import {
  getStockData,
  getSupportedStocks,
  IStockSourceDataValue,
} from "../../services/stockDataService";
import {
  IAdminClient,
  IAward,
  IDistributionsDataBySymbol,
  IDistributionsDatum,
  IEmployee,
  IEmployeeSource,
  IEquityClientSource,
  IEquityData,
  IStockData,
  IStockDataValue,
  IVestingData,
} from "../../types/dataTypes";
import {
  EMPTY_DISTRIBUTIONS_DATA,
  EMPTY_EQUITY_DATA,
  EMPTY_STOCK_DATA,
} from "../../types/defaultTypes";
import { isSomething } from "../../types/typeGuards";
import { Json, LoadError, Maybe, nothing, some } from "../../types/typeUtils";
import {
  convertToDateObject,
  convertToNYTimeZoneDateObject,
  convertToOptional,
  convertToOptionalDateObject,
  convertToRoundedValidNumber,
  convertToTwoDigitRoundedDecimal,
} from "../../utils/converters";
import {
  errDistributionsData,
  errEquityClients,
  errEquityData,
  errEquityEmployees,
  noDistributionsData,
  noEquityData,
  noStockData,
  recvDistributionsData,
  recvEquityClients,
  recvEquityData,
  recvEquityEmployees,
  recvStockData,
  recvSupportedStocks,
  setSelectedStockSymbol,
} from "../actions/equityActions";

/*
helper function to get awards in format we want.

filters out all past data, computes a total unvested units and next vesting date
    at the award level
*/
const convertAwards = (awards: Json<IAward>[]): IAward[] => {
  // gets vesting data in proper format, ignoring past vesting dates
  const convertVestingData = (
    vestingData: Json<IVestingData>[]
  ): IVestingData[] => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return vestingData
      .map((vestingDatum) => {
        return {
          date: convertToDateObject(vestingDatum.date),
          quantity: convertToRoundedValidNumber(vestingDatum.quantity),
        };
      })
      .sort((a, b) => a.date.valueOf() - b.date.valueOf());
  };

  const convertedAwards: IAward[] = awards.map((award) => {
    const convertedVestingData = convertVestingData(award.vestingData);
    return {
      id: award.id,
      name: award.name,
      grantDate: convertToDateObject(award.grantDate),
      unvestedUnits: award.unvestedUnits,
      vestedUnits: award.vestedUnits,
      nextVestingDate: convertToDateObject(award.nextVestingDate),
      vestingData: convertedVestingData,
      finalVestingDate:
        convertedVestingData[convertedVestingData.length - 1].date,
    };
  });
  return convertedAwards;
};

export function* fetchEquityData(action: PayloadAction<string>) {
  try {
    const equityData: Maybe<IEquitySourceData[]> = yield call(
      getEquityData,
      action.payload
    );

    if (equityData === undefined) {
      yield put(noEquityData());
      return;
    } else {
      const convert = (data: Json<IEquityData>): IEquityData => {
        return {
          symbol: data.symbol as StockSymbol,
          totalUnvestedUnits: data.totalUnvestedUnits,
          nextVestingDate: convertToDateObject(data.nextVestingDate),
          awards: convertAwards(data.awards),
          asOfDate: convertToDateObject(data.asOfDate),
          vestedRestrictedUnits: convertToOptional(data.vestedRestrictedUnits),
          vestedExchangeableUnits: convertToOptional(
            data.vestedExchangeableUnits
          ),
          vestedAndExchangeableAsOfDate: convertToOptionalDateObject(
            data.vestedAndExchangeableAsOfDate
          ),
        };
      };

      const convertedEquityData = equityData
        .map((equityData: Json<IEquityData>) => convert(equityData))
        .filter((equityData: IEquityData) =>
          UISupportedStocks.includes(equityData.symbol)
        );

      // check for any future vesting events
      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0);
      const futureEquity = convertedEquityData.filter(
        (equityDatum: IEquityData) =>
          equityDatum.nextVestingDate.toLocaleDateString() !== "Invalid Date" &&
          equityDatum.nextVestingDate > startOfToday
      );

      if (futureEquity.length === 0) {
        yield put(noEquityData());
      } else {
        const equityByStock = { ...EMPTY_EQUITY_DATA };
        futureEquity.forEach((type) => {
          equityByStock[type.symbol] = some({
            totalUnvestedUnits: type.totalUnvestedUnits,
            nextVestingDate: type.nextVestingDate,
            awards: type.awards,
            asOfDate: type.asOfDate,
            vestedRestrictedUnits: type.vestedRestrictedUnits,
            vestedExchangeableUnits: type.vestedExchangeableUnits,
            vestedAndExchangeableAsOfDate: type.vestedAndExchangeableAsOfDate,
          });
        });

        const firstSymbolWithData = UISupportedStocks.find((symbol) =>
          isSomething(equityByStock[symbol])
        );
        if (firstSymbolWithData !== undefined) {
          yield put(setSelectedStockSymbol(firstSymbolWithData));
        }
        yield put(recvEquityData(equityByStock));
      }
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errEquityData(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}

export function* fetchClientEquityData(action: PayloadAction<string>) {
  try {
    const equityData: Maybe<IEquitySourceData[]> = yield call(
      getClientEquityData,
      action.payload
    );

    if (equityData === undefined) {
      yield put(noEquityData());
      return;
    } else {
      const convert = (data: Json<IEquityData>): IEquityData => {
        return {
          symbol: data.symbol as StockSymbol,
          totalUnvestedUnits: data.totalUnvestedUnits,
          nextVestingDate: convertToDateObject(data.nextVestingDate),
          awards: convertAwards(data.awards),
          asOfDate: convertToDateObject(data.asOfDate),
          vestedRestrictedUnits: convertToOptional(data.vestedRestrictedUnits),
          vestedExchangeableUnits: convertToOptional(
            data.vestedExchangeableUnits
          ),
          vestedAndExchangeableAsOfDate: convertToOptionalDateObject(
            data.vestedAndExchangeableAsOfDate
          ),
        };
      };

      const convertedEquityData = equityData
        .map((equityData: Json<IEquityData>) => convert(equityData))
        .filter((equityData: IEquityData) =>
          UISupportedStocks.includes(equityData.symbol)
        );

      // check for any future vesting events
      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0);
      const futureEquity = convertedEquityData.filter(
        (equityDatum: IEquityData) =>
          equityDatum.nextVestingDate.toLocaleDateString() !== "Invalid Date" &&
          equityDatum.nextVestingDate > startOfToday
      );

      if (futureEquity.length === 0) {
        yield put(noEquityData());
      } else {
        const equityByStock = { ...EMPTY_EQUITY_DATA };
        futureEquity.forEach((type) => {
          equityByStock[type.symbol] = some({
            totalUnvestedUnits: type.totalUnvestedUnits,
            nextVestingDate: type.nextVestingDate,
            awards: type.awards,
            asOfDate: type.asOfDate,
            vestedRestrictedUnits: type.vestedRestrictedUnits,
            vestedExchangeableUnits: type.vestedExchangeableUnits,
            vestedAndExchangeableAsOfDate: type.vestedAndExchangeableAsOfDate,
          });
        });

        const firstSymbolWithData = UISupportedStocks.find((symbol) =>
          isSomething(equityByStock[symbol])
        );
        if (firstSymbolWithData !== undefined) {
          yield put(setSelectedStockSymbol(firstSymbolWithData));
        }
        yield put(recvEquityData(equityByStock));
      }
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errEquityData(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}

export function* fetchEquityEmployees() {
  try {
    const employees: Maybe<IEmployeeSources> = yield call(getEquityEmployees);

    // if no employees found set employees array to nothing
    if (employees === undefined || employees.length === 0) {
      yield put(recvEquityEmployees(nothing));
    } else {
      const convertEmployee = (
        equityEmployee: Json<IEmployeeSource>
      ): IEmployee => ({
        id: equityEmployee.employeeId.toString(),
        name: equityEmployee.employeeName,
      });
      yield put(recvEquityEmployees(some(employees.map(convertEmployee))));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errEquityEmployees(DataLoadStatus.UNSUCCESSFUL));
    }
  }
}

export function* fetchEquityClients() {
  try {
    const clients: Maybe<IEquityClientSources> = yield call(getEquityClients);

    // if no clients found set clients array to nothing
    if (clients === undefined || clients.length === 0) {
      yield put(recvEquityClients(nothing));
    } else {
      const convertClient = (
        client: Json<IEquityClientSource>
      ): IAdminClient => ({
        id: client.id.toString(),
        name: client.name,
        shortName: client.shortName,
        mdmOId: client.mdmId.toString(),
        investmentVehicles: [],
      });
      yield put(recvEquityClients(some(clients.map(convertClient))));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errEquityClients(DataLoadStatus.UNSUCCESSFUL));
    }
  }
}

export function* fetchStockData() {
  try {
    const supportedStocks: Maybe<StockSymbol[]> = yield call(
      getSupportedStocks
    );
    if (supportedStocks === undefined) {
      yield put(noStockData());
    } else {
      yield put(recvSupportedStocks(some(supportedStocks)));
      const allStockData: IStockData[] = [];
      for (let i = 0; i < supportedStocks.length; i++) {
        // for each supported stock, pull the latest stock data values
        const stock = supportedStocks[i];
        const stockData: Maybe<IStockSourceDataValue[]> = yield call(
          getStockData,
          stock
        );
        if (stockData) {
          const convert = (datum: Json<IStockDataValue>): IStockDataValue => ({
            asOfDate: convertToNYTimeZoneDateObject(datum.asOfDate),
            value: datum.value,
          });

          const convertedStockData = stockData.map(
            (stockDataValue: Json<IStockDataValue>) => convert(stockDataValue)
          );
          // sort stocks data so that most recent date is first in list
          convertedStockData.sort((a, b) => (a.asOfDate > b.asOfDate ? -1 : 1));
          const dataForSymbol: IStockData = {
            symbol: stock,
            data: convertedStockData,
          };
          allStockData.push(dataForSymbol);
        }
      }

      const userStockData = { ...EMPTY_STOCK_DATA };

      allStockData.forEach((type) => {
        userStockData[type.symbol] = some(type.data);
      });

      yield put(recvStockData(userStockData));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errEquityData(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}

export function* fetchDistributionsData(action: PayloadAction<string>) {
  try {
    const distributionsData: Maybe<IDistriutionsSourceDatum[]> = yield call(
      getDistributionsData,
      action.payload
    );
    if (distributionsData === undefined) {
      yield put(noDistributionsData());
      return;
    } else {
      const convert = (
        datum: Json<IDistributionsDatum>
      ): IDistributionsDatum => {
        return {
          distributionDate: convertToDateObject(datum.distributionDate),
          ticker: datum.ticker as StockSymbol,
          unitType: datum.unitType as DistributionUnitType,
          distributionPerUnit: convertToTwoDigitRoundedDecimal(
            datum.distributionPerUnit
          ),
          unitsEntitled: convertToRoundedValidNumber(datum.unitsEntitled),
          dividendAmount: convertToRoundedValidNumber(datum.dividendAmount),
        };
      };

      const convertedDistributionsData = distributionsData.map((data) =>
        convert(data)
      );

      const distributionsBySymbol: IDistributionsDataBySymbol = {
        ...EMPTY_DISTRIBUTIONS_DATA,
      };

      UISupportedStocks.forEach((symbol) => {
        const relevantDistributions = convertedDistributionsData
          .filter((distribution) => distribution.ticker === symbol)
          .sort((a, b) => {
            return (
              b.distributionDate.getTime() - a.distributionDate.getTime() ||
              a.ticker.localeCompare(b.ticker)
            );
          });
        if (relevantDistributions.length) {
          distributionsBySymbol[symbol] = some(relevantDistributions);
        }
      });

      yield put(recvDistributionsData(distributionsBySymbol));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errDistributionsData(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}

export function* fetchClientDistributionsData(action: PayloadAction<string>) {
  try {
    const distributionsData: Maybe<IDistriutionsSourceDatum[]> = yield call(
      getClientDistributionsData,
      action.payload
    );
    if (distributionsData === undefined) {
      yield put(noDistributionsData());
      return;
    } else {
      const convert = (
        datum: Json<IDistributionsDatum>
      ): IDistributionsDatum => {
        return {
          distributionDate: convertToDateObject(datum.distributionDate),
          ticker: datum.ticker as StockSymbol,
          unitType: datum.unitType as DistributionUnitType,
          distributionPerUnit: convertToTwoDigitRoundedDecimal(
            datum.distributionPerUnit
          ),
          unitsEntitled: convertToRoundedValidNumber(datum.unitsEntitled),
          dividendAmount: convertToRoundedValidNumber(datum.dividendAmount),
        };
      };

      const convertedDistributionsData = distributionsData.map((data) =>
        convert(data)
      );

      const distributionsBySymbol: IDistributionsDataBySymbol = {
        ...EMPTY_DISTRIBUTIONS_DATA,
      };

      UISupportedStocks.forEach((symbol) => {
        const relevantDistributions = convertedDistributionsData
          .filter((distribution) => distribution.ticker === symbol)
          .sort((a, b) => {
            return (
              b.distributionDate.getTime() - a.distributionDate.getTime() ||
              a.ticker.localeCompare(b.ticker)
            );
          });
        if (relevantDistributions.length) {
          distributionsBySymbol[symbol] = some(relevantDistributions);
        }
      });

      yield put(recvDistributionsData(distributionsBySymbol));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errDistributionsData(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}
