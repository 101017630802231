import {
  IElectionsFilter,
  IElectionsForElectionRoundRow,
  ISortOptions,
  isSomething,
  Optional,
  SortDirection,
} from "common";

export enum ElectorViewGridColumn {
  mdmInvestmentVehicleId = "mdmInvestmentVehicleId",
  name = "name",
  currentStage = "currentStage",
  submissionDateTime = "submissionDateTime",
  reopenedDate = "reopenedDate",
}

/**
 * Searches and return matches from current searchTerm.
 * Elections can be searched by exact mdmInvestmentVehicleId match, mdmInvestmentVehicleId beginning match,
 * mdmInvestmentVehicleId containing match, or by the investment vehicle's name with the same criteria.
 *
 * @param elections An array of elections to search and sort.
 * @param searchTerm The string used to search for elections.
 * @returns An object that contains property for each type of match (exactMatches, beginsWithMatches, containingMatches).
 */
export const searchElections = (
  electionRounds: IElectionsForElectionRoundRow[],
  searchTerm: Optional<string>
): {
  exactMatches: IElectionsForElectionRoundRow[];
  beginsWithMatches: IElectionsForElectionRoundRow[];
  containingMatches: IElectionsForElectionRoundRow[];
} => {
  // if no search term, return all results
  if (!isSomething(searchTerm) || searchTerm.value === "")
    return {
      exactMatches: electionRounds,
      beginsWithMatches: [],
      containingMatches: [],
    };

  // Convert search string to lowercase for case-insensitive comparison
  const searchStringLowerCase = searchTerm.value.toLowerCase();

  // Arrays to store different types of matches
  const exactMatches: IElectionsForElectionRoundRow[] = [];
  const beginsWithMatches: IElectionsForElectionRoundRow[] = [];
  const containingMatches: IElectionsForElectionRoundRow[] = [];

  // Iterate through each election to find matches
  electionRounds.forEach((electionRound) => {
    const mdmIdString = electionRound.mdmInvestmentVehicleId.toString();
    const nameLowerCase = electionRound.name.toLowerCase();

    if (mdmIdString === searchStringLowerCase) {
      exactMatches.push(electionRound);
    } else if (nameLowerCase === searchStringLowerCase) {
      exactMatches.push(electionRound);
    } else if (mdmIdString.startsWith(searchStringLowerCase)) {
      beginsWithMatches.push(electionRound);
    } else if (nameLowerCase.startsWith(searchStringLowerCase)) {
      beginsWithMatches.push(electionRound);
    } else if (nameLowerCase.includes(searchStringLowerCase)) {
      containingMatches.push(electionRound);
    }
  });

  // Combine the arrays, prioritizing the order of match types
  return { exactMatches, beginsWithMatches, containingMatches };
};

export const sortElectionsForElectionRound = (
  election1: IElectionsForElectionRoundRow,
  election2: IElectionsForElectionRoundRow,
  currentSort: Optional<ISortOptions<ElectorViewGridColumn>>
) => {
  const sortByName = (
    election1: IElectionsForElectionRoundRow,
    election2: IElectionsForElectionRoundRow,
    sortDirection?: SortDirection
  ) =>
    !sortDirection || sortDirection === SortDirection.DESC
      ? election1.name.localeCompare(election2.name)
      : election2.name.localeCompare(election1.name);

  const sortByNumber = (
    field1: number,
    field2: number,
    sortDirection: SortDirection
  ): number =>
    sortDirection === SortDirection.ASC ? field1 - field2 : field2 - field1;

  const sortByOptionalDate = (
    field1: Date | undefined,
    field2: Date | undefined,
    sortDirection: SortDirection
  ): number => {
    if (sortDirection === SortDirection.ASC) {
      if (!field1) {
        return -1;
      }
      if (!field2) {
        return 1;
      }

      return field1 > field2 ? 1 : -1;
    }
    if (!field1) {
      return 1;
    }
    if (!field2) {
      return -1;
    }

    return field2 > field1 ? 1 : -1;
  };
  if (!isSomething(currentSort)) {
    return sortByName(election1, election2);
  }

  switch (currentSort.value.sortBy) {
    case ElectorViewGridColumn.name:
      return sortByName(election1, election2, currentSort.value.orderBy);
    case ElectorViewGridColumn.mdmInvestmentVehicleId:
      return election1.mdmInvestmentVehicleId !==
        election2.mdmInvestmentVehicleId
        ? sortByNumber(
            election1.mdmInvestmentVehicleId,
            election2.mdmInvestmentVehicleId,
            currentSort.value.orderBy
          )
        : sortByName(election1, election2);
    case ElectorViewGridColumn.currentStage:
      return election1.currentStage !== election2.currentStage
        ? sortByNumber(
            election1.currentStage,
            election2.currentStage,
            currentSort.value.orderBy
          )
        : sortByName(election1, election2);
    case ElectorViewGridColumn.reopenedDate:
      return election1.reopenedDate !== election2.reopenedDate
        ? sortByOptionalDate(
            election1.reopenedDate,
            election2.reopenedDate,
            currentSort.value.orderBy
          )
        : sortByName(election1, election2);
    case ElectorViewGridColumn.submissionDateTime:
      return election1.submissionDateTime !== election2.submissionDateTime
        ? sortByOptionalDate(
            election1.submissionDateTime,
            election2.submissionDateTime,
            currentSort.value.orderBy
          )
        : sortByName(election1, election2);
  }
};

export const sortElections = (
  exactMatches: IElectionsForElectionRoundRow[],
  beginsWithMatches: IElectionsForElectionRoundRow[],
  containingMatches: IElectionsForElectionRoundRow[],
  currentSort: Optional<ISortOptions<ElectorViewGridColumn>>
): IElectionsForElectionRoundRow[] => {
  const sortArray = (array: IElectionsForElectionRoundRow[]) =>
    array.sort((election1, election2) =>
      sortElectionsForElectionRound(election1, election2, currentSort)
    );

  return [
    ...sortArray(exactMatches),
    ...sortArray(beginsWithMatches),
    ...sortArray(containingMatches),
  ];
};

export const filterElections = (
  item: IElectionsForElectionRoundRow,
  filter: IElectionsFilter
): boolean => {
  if (
    isSomething(filter.currentStage) &&
    filter.currentStage.value.length > 0 &&
    filter.currentStage.value.indexOf(item.currentStage) < 0
  ) {
    return false;
  }
  if (
    isSomething(filter.wasReopened) &&
    filter.wasReopened.value !== !!item.reopenedDate
  ) {
    return false;
  }
  return true;
};

export const getActiveFiltersCount = (filters: IElectionsFilter) => {
  return (
    (isSomething(filters.currentStage) && filters.currentStage.value.length > 0
      ? 1
      : 0) + (isSomething(filters.wasReopened) ? 1 : 0)
  );
};
