import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "redux-saga/effects";

import { DataLoadStatus, TreeDepth, TreeNumber } from "../../constants/enums";
import { ALL_NAME_PLACEHOLDER } from "../../constants/InvestmentBreakdownConstants";
import {
  getInternalInvestmentClients,
  getInvestmentDataforEntityByPeriod,
  IInteralInvestmentClientsSource,
  IInvestmentSourceData,
} from "../../services/internalInvestmentDataService";
import {
  IAdminClient,
  ICommitment,
  ICommitmentData,
  IDateRange,
  IFinancingBalance,
  IForecastedCapCall,
  IHoldbackPerFund,
  IInteralInvestmentClient,
  IInternalInvestmentClientDataDownload,
  IInvestmentBreakdownDatum,
  IInvestmentDataDto,
  IInvestmentVehicleByClientByPeriod,
  ISegregatedTrustBalance,
} from "../../types/dataTypes";
import { isSomething } from "../../types/typeGuards";
import { Json, LoadError, Maybe, nothing, some } from "../../types/typeUtils";
import {
  convertToDateObject,
  convertToOptional,
  convertToRoundedValidNumber,
} from "../../utils/converters";
import {
  errAllClientData,
  errInternalInvestmentClients,
  errInternalInvestmentData,
  recvAllClientData,
  recvInternalInvestmentClients,
  recvInternalInvestmentData,
} from "../actions/internalInvestmentActions";

/**
 * Parses a Date Range JSON string, constructing the Date Range object
 * @param dateRange - The date range json
 * @returns A Date Range object.
 */
const parseJsonToDateRange = (dateRange: IDateRangeSourceData): IDateRange => {
  return {
    latestAsOfDate: dateRange?.latestAsOfDate
      ? convertToDateObject(dateRange.latestAsOfDate)
      : null,
    latestAsOfDateWithUnrealizedData:
      dateRange?.latestAsOfDateWithUnrealizedData
        ? convertToDateObject(dateRange.latestAsOfDateWithUnrealizedData)
        : null,
    earliestAsOfDate: dateRange?.earliestAsOfDate
      ? convertToDateObject(dateRange.earliestAsOfDate)
      : null,
  } as IDateRange;
};

/**
 * Parses a Commitment JSON string, constructing the Commitment object for LOF or Annual
 * @param commitment - The commitment data json
 * @returns An ICommitment object.
 */
const convertToCommitmentDatum = (
  commitmentDatum: ICommitmentSource
): ICommitment => {
  return {
    ...commitmentDatum,
    fund:
      commitmentDatum.fund !== undefined && commitmentDatum.fund !== null
        ? {
            id: commitmentDatum.fund.id,
            name: commitmentDatum.fund.name,
            mdmId: commitmentDatum.fund.mdmId,
            strategyId: commitmentDatum.fund.strategyId,
            strategyName: commitmentDatum.fund.strategyName,
          }
        : null,
    commitmentType: commitmentDatum.commitmentType,
    electionYear: commitmentDatum.electionYear,
    electedAmount: commitmentDatum.electedAmount,
    capitalCalled: commitmentDatum.capitalCalled,
  } as ICommitment;
};

/**
 * Parses a Forecasted Cap Call JSON string, constructing the Forecasted object
 * @param forecasted - The forecasted data json
 * @returns A Forecasted Data object.
 */
const convertToForecastedCapCall = (
  forecastedDatum: IForecastedSourceData
): IForecastedCapCall => {
  return {
    ...forecastedDatum,
    fund: {
      id: forecastedDatum.fund.id,
      name: forecastedDatum.fund.name,
      mdmId: forecastedDatum.fund.mdmId,
      strategyId: forecastedDatum.fund.strategyId,
      strategyName: forecastedDatum.fund.strategyName,
    },
    amount: forecastedDatum.amount,
  };
};

/**
 * Parses a Commitment Data JSON string, constructing the Commitment Data object
 * @param commitment - The commitment data json
 * @returns A Commitment Data object.
 */
const parseJsonToCommitmentData = (
  commitment: ICommitmentSourceData
): ICommitmentData | null => {
  if (commitment === null) {
    return null;
  }

  let fundCommitments = null;
  let forecastedCapitalCalls = null;

  if (commitment.fundCommitments != null) {
    fundCommitments = {
      commitments: [...commitment.fundCommitments.commitments].map(
        convertToCommitmentDatum
      ),
      asOfDate: convertToDateObject(commitment.fundCommitments.asOfDate),
    };
  }

  if (commitment.forecastedCapitalCalls != null) {
    forecastedCapitalCalls = {
      capitalCalls: [...commitment.forecastedCapitalCalls.capitalCalls].map(
        convertToForecastedCapCall
      ),
      asOfDate: convertToDateObject(commitment.forecastedCapitalCalls.asOfDate),
    };
  }

  return {
    fundCommitments: convertToOptional(fundCommitments),
    forecastedCapitalCalls: convertToOptional(forecastedCapitalCalls),
  } as ICommitmentData;
};

/**
 * Parses a Financing Balance JSON string, constructing the Financing Balance object
 * @param financingBalance - The financing Balance json
 * @returns A Financing Balance object.
 */
const parseJsonToFinancingBalance = (
  financingBalance: IFinancingBalanceSourceData
): IFinancingBalance | null => {
  if (financingBalance == null) {
    return null;
  }

  const parsedFinancingBalance: IFinancingBalance = {
    asOfDate: convertToDateObject(financingBalance.asOfDate),
    firmFinancingBalance: convertToRoundedValidNumber(
      financingBalance.firmFinancingBalance
    ),
    thirdPartyFinancingBalance: convertToRoundedValidNumber(
      financingBalance.thirdPartyFinancingBalance
    ),
    thirdPartyPendingLoanBalance: convertToRoundedValidNumber(
      financingBalance.thirdPartyPendingLoanBalance
    ),
    thirdPartyLoanLimit: convertToRoundedValidNumber(
      financingBalance.thirdPartyLoanLimit
    ),
    thirdPartyRemainingLoanCapacity: convertToRoundedValidNumber(
      financingBalance.thirdPartyRemainingLoanCapacity
    ),
    totalFinancingBalance: convertToRoundedValidNumber(
      financingBalance.totalFinancingBalance
    ),
    totalFinancingBalanceQSTMT: convertToRoundedValidNumber(
      financingBalance.totalFinancingBalanceQSTMT
    ),
  };

  return parsedFinancingBalance;
};

const maxTreeLevelNeeded = (
  investmentBreakdownSource: IInvestmentBreakdownSourceData
) =>
  Number(investmentBreakdownSource.treeLevel) <=
  TreeDepth[investmentBreakdownSource.treeNumber as TreeNumber];

const convertToInvestmentBreakdownDatum = (
  datum: IInvestmentBreakdownSourceData
): IInvestmentBreakdownDatum => {
  return {
    ...datum,
    treeLevel: Number(datum.treeLevel),
    investmentDate:
      datum.investmentDate !== undefined &&
      datum.investmentDate !== ALL_NAME_PLACEHOLDER &&
      datum.investmentDate !== ""
        ? convertToDateObject(datum.investmentDate)
        : undefined,
    investment:
      datum.investment !== undefined && datum.investment !== null
        ? some({
            asOfDate: convertToDateObject(datum.investment.asOfDate),
            capitalInvested: datum.investment.capitalInvested,
            netCashFlow: datum.investment.netCashFlow,
          })
        : nothing,
    realizedProceeds:
      datum.realizedProceeds !== undefined && datum.realizedProceeds !== null
        ? some({
            asOfDate: convertToDateObject(datum.realizedProceeds.asOfDate),
            sbsAndMandatoryInvestments:
              datum.realizedProceeds.sbsAndMandatoryInvestments,
            returnOfCapital: datum.realizedProceeds.returnOfCapital,
            gainLoss: datum.realizedProceeds.gainLoss,
            carriedInterest: datum.realizedProceeds.carriedInterest,
            total: datum.realizedProceeds.total,
          })
        : nothing,
    unrealizedValue:
      datum.unrealizedValue !== undefined && datum.unrealizedValue !== null
        ? some({
            asOfDate: convertToDateObject(datum.unrealizedValue.asOfDate),
            sbsAndMandatoryInvestments:
              datum.unrealizedValue.sbsAndMandatoryInvestments,
            remainingCapitalInvested:
              datum.unrealizedValue.remainingCapitalInvested,
            carriedInterest: datum.unrealizedValue.carriedInterest,
            total: datum.unrealizedValue.total,
            gainLoss: datum.unrealizedValue.gainLoss,
          })
        : nothing,
  } as IInvestmentBreakdownDatum;
};

/**
 * Parses a InvestmentBreakdown JSON string, constructing the InvestmentBreakdown object
 * @param investmentBreakdownSource - The investment breakdown json
 * @returns A list of InvestmentBreakdowDatum objects.
 */
const parseJsonToInvestmentBreakdown = (
  investmentBreakdownSource: IInvestmentBreakdownSourceData[]
): IInvestmentBreakdownDatum[] => {
  return investmentBreakdownSource
    .filter(maxTreeLevelNeeded)
    .map(convertToInvestmentBreakdownDatum);
};

/**
 * Parses a HoldbackPerFund JSON string, constructing the HoldbackPerFund object
 * @param holdbackPerFund - The holdback json
 * @returns A HoldbackPerFund object.
 */
const parseJsonToHoldbackPerFund = (
  holdbackPerFund: IHoldbackPerFundSourceData
): IHoldbackPerFund => {
  return {
    fundName: holdbackPerFund.fundName,
    holdback: convertToRoundedValidNumber(holdbackPerFund.holdback),
  } as IHoldbackPerFund;
};

/**
 * Parses a Segregated Trust Balance JSON string, constructing the Segregated Trust Balance object
 * @param segregatedTrustBalance - The segregated trust balance json
 * @returns A Segregated Trust Balance object.
 */
const parseJsonToSegregatedTrustBalance = (
  segregatedTrustBalance: ISegregatedTrustBalanceSourceData
): ISegregatedTrustBalance | null => {
  if (segregatedTrustBalance === null) {
    return null;
  }

  return {
    asOfDate: convertToDateObject(segregatedTrustBalance.asOfDate),
    totalHoldbackValue: convertToRoundedValidNumber(
      segregatedTrustBalance.totalHoldbackValue
    ),
    holdbackInvested: convertToRoundedValidNumber(
      segregatedTrustBalance.holdbackInvested
    ),
    holdbackCash: convertToRoundedValidNumber(
      segregatedTrustBalance.holdbackCash
    ),
    holdbackPerFund: [...segregatedTrustBalance.holdbackPerFund].map(
      parseJsonToHoldbackPerFund
    ),
    totalCollateralValue: convertToRoundedValidNumber(
      segregatedTrustBalance.totalCollateralValue
    ),
    requiredCollateralization: convertToRoundedValidNumber(
      segregatedTrustBalance.requiredCollateralization
    ),
    excessCollateralization: convertToRoundedValidNumber(
      segregatedTrustBalance.excessCollateralization
    ),
  } as ISegregatedTrustBalance;
};

/*
  checks if a date is one of the 4 quarter end dates
*/
export const checkDateIsQuarterEnd = (date: Date) => {
  const month = date.getMonth();
  const day = date.getDate();
  return (
    (month === 2 && day === 31) ||
    (month === 5 && day === 30) ||
    (month === 8 && day === 30) ||
    (month === 11 && day === 31)
  );
};

/*
  checks if a date is the last day of the year
*/
export const checkDateIsYearEnd = (date: Date) => {
  const month = date.getMonth();
  const day = date.getDate();
  return month === 11 && day === 31;
};

/*
  checks if a quarter date is for Q1
*/
export const getQuarterFromDate = (date: Date) => {
  const month = date.getMonth();
  if (month === 2) {
    return "Q1";
  }
  if (month === 5) {
    return "Q2";
  }
  if (month === 8) {
    return "Q3";
  } else {
    return "Q4";
  }
};

/**
 * Parses a InvestmentDataDto JSON string, constructing the InvestmentDataDto object
 * @param investmentDataDto - The investment data dto
 * @returns An Investment Data object.
 */
const parseJsonToInvestmentData = (
  investmentDataDto: IInvestmentSourceData
): IInvestmentDataDto => {
  const data: IInvestmentDataDto = {
    entityId: investmentDataDto.entityId,
    entityType: investmentDataDto.entityType,
    entityName: investmentDataDto.entityName,
    dateRange: parseJsonToDateRange(investmentDataDto.dateRange),
    commitmentData: parseJsonToCommitmentData(investmentDataDto.commitmentData),
    financingBalance: parseJsonToFinancingBalance(
      investmentDataDto.financingBalance
    ),
    historicalSummary: parseJsonToInvestmentBreakdown(
      investmentDataDto.historicalSummary
    ).filter((investmentBreakdownDatum: IInvestmentBreakdownDatum) => {
      return isSomething(investmentBreakdownDatum.investment);
    }),
    investmentBreakdown: parseJsonToInvestmentBreakdown(
      investmentDataDto.investmentBreakdown
    ),
    // TODO: when we want to stop hiding seg trust for everyone, update the below
    segregatedTrustBalance: parseJsonToSegregatedTrustBalance(
      null // investmentDataDto.segregatedTrustBalance
    ),
  };

  return data;
};

type IDateRangeSourceData = Json<IDateRange | null>;
type ICommitmentSourceData = Json<ICommitmentData | null>;
type ICommitmentSource = Json<ICommitment>;
type IForecastedSourceData = Json<IForecastedCapCall>;
type IFinancingBalanceSourceData = Json<IFinancingBalance | null>;
type IInvestmentBreakdownSourceData = Json<IInvestmentBreakdownDatum>;
type ISegregatedTrustBalanceSourceData = Json<ISegregatedTrustBalance | null>;
type IHoldbackPerFundSourceData = Json<IHoldbackPerFund>;

export function* fetchInternalInvestmentData(
  action: PayloadAction<IInvestmentVehicleByClientByPeriod>
) {
  if (action.payload) {
    try {
      const investmentData: Maybe<IInvestmentSourceData> = yield call(
        getInvestmentDataforEntityByPeriod,
        action.payload.clientId,
        action.payload.investmentVehicleId,
        action.payload.periodId
      );

      if (investmentData === undefined) {
        yield put(recvInternalInvestmentData(nothing));
      } else {
        const convertedInvestmentData =
          parseJsonToInvestmentData(investmentData);
        yield put(recvInternalInvestmentData(some(convertedInvestmentData)));
      }
    } catch (e) {
      if (e instanceof LoadError) {
        yield put(errInternalInvestmentData(DataLoadStatus.UNSUCCESSFUL));
      }
    }
  }
}

export function* fetchInternalInvestmentClients() {
  try {
    const clients: Maybe<IInteralInvestmentClientsSource> = yield call(
      getInternalInvestmentClients
    );

    if (clients === undefined || clients.length === 0) {
      yield put(recvInternalInvestmentClients(nothing));
    } else {
      const convertClient = (
        internalInvestmentClient: Json<IInteralInvestmentClient>
      ): IAdminClient => ({
        id: internalInvestmentClient.id,
        mdmOId: internalInvestmentClient.mdmId.toString(),
        name: internalInvestmentClient.name,
        shortName: internalInvestmentClient.shortName,
        investmentVehicles: internalInvestmentClient.investmentVehicles.map(
          (investmentVehicle) => {
            return {
              id: investmentVehicle.id,
              name: investmentVehicle.name,
              shortName: investmentVehicle.shortName,
            };
          }
        ),
      });
      yield put(
        recvInternalInvestmentClients(some(clients.map(convertClient)))
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errInternalInvestmentClients(DataLoadStatus.UNSUCCESSFUL));
    }
  }
}

export function* fetchAllClientData(
  action: PayloadAction<IInternalInvestmentClientDataDownload>
) {
  try {
    if (action.payload) {
      // check for client
      let clientData: IInvestmentDataDto | undefined;
      if (!action.payload.isClientLoaded) {
        // if not loaded - load client data
        const jsonData: Json<IInvestmentDataDto> = yield call(
          getInvestmentDataforEntityByPeriod,
          action.payload.clientId,
          undefined,
          action.payload.periodId
        );
        const parsedClientData = parseJsonToInvestmentData(jsonData);
        clientData = parsedClientData;
      }

      // create list of data inputs/calls
      const fetchDataCalls = action.payload.entityIds.map((entityId) =>
        call(
          getInvestmentDataforEntityByPeriod,
          action.payload.clientId,
          entityId,
          action.payload.periodId
        )
      );

      // use promise all to call them in parallel
      const investmentDataDtos: Json<IInvestmentDataDto>[] = yield all(
        fetchDataCalls
      );

      const entityData = investmentDataDtos.flatMap((entity) =>
        entity ? [parseJsonToInvestmentData(entity)] : []
      );

      if (clientData) entityData.push(clientData);

      if (entityData !== undefined) {
        yield put(recvAllClientData(some(entityData)));
      }
    }
  } catch (e) {
    yield put(errAllClientData(DataLoadStatus.UNSUCCESSFUL));
  }
}
