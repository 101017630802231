import { createSelector } from "@reduxjs/toolkit";
import { IElectionRound, isSomething } from "common";

import { AdminUIStore } from "../store";

// selects the name of the current election round
export const selectElectionsStore = (state: AdminUIStore) => state.elections;

export const selectCurrentElectionRoundName = createSelector(
  [selectElectionsStore],
  ({ allElectionRounds, currentElectionRoundId }): string => {
    let matchingElectionRound: IElectionRound | undefined;
    if (isSomething(currentElectionRoundId)) {
      matchingElectionRound = allElectionRounds.find(
        (round: IElectionRound) =>
          round.electionRoundId === currentElectionRoundId.value
      );
    }
    if (matchingElectionRound) {
      return matchingElectionRound.name;
    }
    return "";
  }
);
