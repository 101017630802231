import { StyleSheet } from "@react-pdf/renderer";

import colors from "../../../../styles/_colors.scss";

export const sharedStyles = StyleSheet.create({
  textColumn: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    padding: 4,
    alignItems: "center",
  },
  blackBorderTop: {
    borderTopWidth: 1,
    borderTopColor: colors.black,
  },
  blackBorderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: colors.black,
  },
  grayBorderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: colors.bx_slate_shade,
  },
  grayBackgroundColor: {
    backgroundColor: colors.light_column_grey,
    marginTop: 0.5,
    marginBottom: 0.5,
  },
});
