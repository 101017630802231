import { Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { IAlert } from "../../types/storeTypes";
import { Alert } from "../Alert/Alert";
import styles from "./AlertStack.module.scss";

interface IAlertStackProps<T> {
  alertsSelector: (store: T) => IAlert[];
  handleClose: (alertId: string) => void;
}

export const AlertStack = <T,>(props: IAlertStackProps<T>) => {
  const { alertsSelector, handleClose } = props;
  const alerts = useSelector(alertsSelector);

  return (
    <Stack className={styles.alertsStack}>
      {alerts.map((alert) => (
        <Alert key={alert.alertId} alert={alert} handleClose={handleClose} />
      ))}
    </Stack>
  );
};
