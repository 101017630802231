import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { DashboardCard } from "../../components/DashboardCard/DashboardCard";
import { SegregatedTrustBalance } from "../../components/SegregatedTrustBalance/SegregatedTrustBalance";
import { CalloutPersona, PeriodHash } from "../../constants/enums";
import {
  DashboardTooltips,
  getAsOfLine,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { useFetchDatasetIfIdDefined } from "../../hooks/dataFetchHooks";
import { reqInternalInvestmentData } from "../../redux/actions/internalInvestmentActions";
import {
  selectActiveInvestmentEntityData,
  selectCallout,
  selectFilteredBy,
  selectInternalInvestmentData,
  selectInvestmentBreakdownGrouped,
} from "../../redux/selectors";
import { selectAsOfDates } from "../../redux/selectors/asOfDateSelectors";
import { IBaseStore } from "../../redux/store";
import {
  ICarryCardData,
  IInvestmentVehicleByClientByPeriod,
} from "../../types/dataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import { LoansCardUtilization, showLoanCard } from "../../utils/loanUtils";
import { CalloutBanner } from "./components/CalloutBanner/CalloutBanner";
import { Carry } from "./components/Carry/Carry";
import { Equity } from "./components/Equity/Equity";
import { EquityV2 } from "./components/EquityV2/EquityV2";
import { InvestmentAllocation } from "./components/InvestmentAllocation/InvestmentAllocation";
import { LoanBalance } from "./components/LoanBalance/LoanBalance";
import styles from "./Dashboard.module.scss";

interface IDashboardProps {
  hasViewInternalInvestments: boolean;
  hasViewStockEntitlement: boolean;
  hasViewEquity: boolean;
  hasViewRestrictedEquity: boolean;
  hasViewV2DashboardEquityCardEntitlement: boolean;
  userWelcomeComponent?: React.ReactNode;
}

export const Dashboard = (props: IDashboardProps) => {
  // components renders only if user is already authenticated
  const {
    hasViewInternalInvestments,
    hasViewStockEntitlement,
    hasViewEquity,
    hasViewRestrictedEquity,
    hasViewV2DashboardEquityCardEntitlement,
    userWelcomeComponent,
  } = props;

  const { internalInvestmentDataLoadStatus } = useSelector(
    (state: IBaseStore) => selectInternalInvestmentData(state)
  );

  const filteredBy = useSelector(selectFilteredBy);

  const { financingBalance, segregatedTrustBalance } = useSelector(
    (store: IBaseStore) => selectActiveInvestmentEntityData(store, true)
  );

  const asOfDate = useSelector((store: IBaseStore) =>
    selectAsOfDates(store, true)
  );

  const { calloutData, calloutPersona } = useSelector((store: IBaseStore) =>
    selectCallout(store, true)
  );

  const { investmentBreakdownGrouped } = useSelector((store: IBaseStore) =>
    selectInvestmentBreakdownGrouped(store, true)
  );

  const [carryCardData, setCarryCardData] =
    useState<Optional<ICarryCardData>>(nothing);

  const [showFinancingData, setShowFinancingData] = useState<boolean>(false);

  const [dashboardInvestmentDataFilter, setDashboardInvestmentDataFilter] =
    useState<Optional<IInvestmentVehicleByClientByPeriod>>(nothing);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    dashboardInvestmentDataFilter,
    internalInvestmentDataLoadStatus
  );

  useEffect(() => {
    const data: Optional<ICarryCardData> = some({
      investmentBreakdownGrouped,
      calloutData,
      calloutPersona,
      asOfDate,
    });
    setCarryCardData(data);
  }, [calloutPersona, calloutData, investmentBreakdownGrouped, asOfDate]);

  useEffect(() => {
    if (isSomething(financingBalance)) {
      showLoanCard(
        financingBalance.value,
        setShowFinancingData,
        LoansCardUtilization.IGNORE_UTILIZATION
      );
    } else {
      setShowFinancingData(false);
    }
  }, [financingBalance]);

  useEffect(() => {
    if (isSomething(filteredBy)) {
      setDashboardInvestmentDataFilter(
        some({
          clientId: filteredBy.value.clientId,
          periodId: PeriodHash.LATEST,
          investmentVehicleId: undefined,
          mdmClientId: undefined,
        })
      );
    } else {
      setDashboardInvestmentDataFilter(nothing);
    }
  }, [filteredBy]);

  return (
    <div className={styles.dashboardPage}>
      <CalloutBanner
        hasViewInternalInvestments={hasViewInternalInvestments}
        internalInvestmentDataLoadStatus={internalInvestmentDataLoadStatus}
        hasViewStockEntitlement={hasViewStockEntitlement}
        hasViewRestrictedEquity={hasViewRestrictedEquity}
        /* The following prop defines wether we want to show just the Client's name or a custom component.
        The reason behind this is that we don't want to display the client selector if we're using Admin-UI
        and we share this Dashboard component between them. userWelcomeComponent is only set in the DashboardWrapper under Investor-UI
        */
        userWelcomeComponent={userWelcomeComponent}
      />
      <div className={styles.dashboard}>
        <div className={styles.widgets}>
          <div className={styles.unrealizedCards}>
            <DashboardCard
              header={SectionHeader.UNREALIZED_CARRY}
              headerTooltipParagraphs={DashboardTooltips.UNREALIZED_CARRY}
              subHeader={
                isSomething(asOfDate.latestAsOfDateWithUnrealizedData)
                  ? getAsOfLine(asOfDate.latestAsOfDateWithUnrealizedData.value)
                  : undefined
              }
              cardContentComponent={Carry}
              retryRequest={retryFunction}
              data={carryCardData}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              hide={
                calloutPersona !== CalloutPersona.CARRY ||
                !isSomething(calloutData.unrealized) ||
                !hasViewInternalInvestments
              }
              className={`${styles.card} ${styles.unrealizedCard}`}
            />
            <DashboardCard
              header={SectionHeader.UNREALIZED_INVESTMENTS}
              headerTooltipParagraphs={DashboardTooltips.UNREALIZED_INVESTMENTS}
              subHeader={
                isSomething(asOfDate.latestAsOfDateWithUnrealizedData)
                  ? getAsOfLine(asOfDate.latestAsOfDateWithUnrealizedData.value)
                  : undefined
              }
              cardContentComponent={InvestmentAllocation}
              retryRequest={retryFunction}
              data={carryCardData}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              hideOnEmpty={true}
              hide={
                !hasViewInternalInvestments ||
                !isSomething(calloutData.unrealized)
              }
              className={`${styles.card} ${styles.unrealizedCard}`}
            />
          </div>
          {dashboardInvestmentDataFilter &&
            hasViewEquity &&
            (hasViewV2DashboardEquityCardEntitlement ? (
              <EquityV2 hasViewRestrictedEquity={hasViewRestrictedEquity} />
            ) : (
              <Equity hasViewRestrictedEquity={hasViewRestrictedEquity} />
            ))}
          <DashboardCard
            header={SectionHeader.LOANS}
            subHeader={getAsOfLine(asOfDate.latestAsOfDate)}
            cardContentComponent={LoanBalance}
            retryRequest={retryFunction}
            data={financingBalance}
            dataLoadStatus={internalInvestmentDataLoadStatus}
            hideOnEmpty={true}
            hide={!hasViewInternalInvestments || !showFinancingData}
            className={styles.card}
          />
          <DashboardCard
            header={SectionHeader.SEG_TRUST_BALANCE}
            subHeader={getAsOfLine(
              isSomething(asOfDate.segTrustBalanceDate)
                ? asOfDate.segTrustBalanceDate.value
                : new Date()
            )}
            cardContentComponent={SegregatedTrustBalance}
            retryRequest={retryFunction}
            data={segregatedTrustBalance}
            dataLoadStatus={internalInvestmentDataLoadStatus}
            hideOnEmpty={true}
            className={styles.card}
          />
        </div>
      </div>
    </div>
  );
};
