import { Warning } from "@mui/icons-material";
import { Box } from "@mui/material";
import clsx from "clsx";
import React from "react";

import styles from "./Banner.module.scss";

export enum BannerType {
  WARNING = "warning",
  INFO = "info",
}

type BannerProps = {
  bannerType: BannerType;
  children: React.ReactNode;
};

function BannerIcon({ bannerType }: { bannerType: BannerType }) {
  if (bannerType === BannerType.WARNING) return <Warning />;
  return null;
}

export function Banner({ bannerType, children }: BannerProps) {
  const classes = {
    [BannerType.WARNING]: styles.BannerWarning,
    [BannerType.INFO]: styles.BannerInfo,
  };

  return (
    <Box className={clsx(styles.Banner, classes[bannerType])}>
      <BannerIcon bannerType={bannerType} />
      {children}
    </Box>
  );
}
