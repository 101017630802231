import { IPage, SectionHeader } from "common";

import { ClientData } from "../../features/ClientData/ClientData";
import ElectionEditorWrapper from "../../features/ElectionEditor/ElectionEditorWrapper";
import ElectionAdminPage from "../../features/Elections/ElectionAdmin/ElectionAdminPage";
import { ElectorViewPage } from "../../features/Elections/ElectionAdmin/ElectorViewPage/ElectorViewPage";
import { ElectionReviewWrapper } from "../../features/Elections/ElectionsReview/ElectionsReviewWrapper";
import { ElectionWorkflowPageWrapper } from "../../features/Elections/ElectionWorkflow/ElectionWorkflowPageWrapper";
import { RelativePath } from "../Paths";

export enum PageName {
  CLIENT_DATA = "ClientData",
  ELECTION_ADMIN_PAGE = "ElectionAdminPage",
  ELECTOR_VIEW_PAGE = "ElectorViewPage",
  ELECTION_EDITOR_PAGE = "ElectionEditorPage",
  ELECTION = "Election",
  ELECTION_REVIEW = "ElectionReview",
}

export enum ClientLevelPageName {
  DASHBOARD = "Dashboard",
  INVESTMENTS_AND_CARRY = "InvestmentsAndCarry",
  COMMITMENTS = "Commitments",
  EQUITY = "Equity",
}

type Pages = {
  [key in PageName]: IPage;
};

type ITab = {
  name: string;
  getPath: (clientId: string | undefined) => string;
};

type ClientLevelPages = {
  [key in ClientLevelPageName]: ITab;
};

export const ClientTabDisplayOrder: ClientLevelPageName[] = [
  ClientLevelPageName.DASHBOARD,
  ClientLevelPageName.INVESTMENTS_AND_CARRY,
  ClientLevelPageName.COMMITMENTS,
  ClientLevelPageName.EQUITY,
];

export const Pages: Pages = {
  ClientData: {
    name: SectionHeader.CLIENT_DATA,
    path: RelativePath.CLIENT_DATA + "/*",
    element: ClientData,
    hasError: false,
  },
  ElectionAdminPage: {
    name: SectionHeader.ELECTION_ADMIN_PAGE,
    path: `/${RelativePath.ELECTION_ADMIN}`,
    element: ElectionAdminPage,
    hasError: false,
  },
  ElectorViewPage: {
    name: SectionHeader.ELECTION_ADMIN_PAGE,
    path: `/${RelativePath.ELECTOR_VIEW_PAGE}`,
    element: ElectorViewPage,
    hasError: false,
  },
  ElectionEditorPage: {
    name: SectionHeader.ELECTION_EDITOR_PAGE,
    path: `/${RelativePath.ELECTION_EDITOR}/*`,
    element: ElectionEditorWrapper,
    hasError: false,
  },
  Election: {
    name: SectionHeader.ELECTIONS,
    path: `/${RelativePath.ELECTION}/*`,
    hasError: false,
    element: ElectionWorkflowPageWrapper,
    rootPath: `/${RelativePath.ELECTION}`,
    parentNavBarName: SectionHeader.ELECTIONS,
  },
  ElectionReview: {
    name: SectionHeader.REVIEW,
    path: `/${RelativePath.ELECTION}/${RelativePath.ELECTION_REVIEW}`,
    hasError: false,
    element: ElectionReviewWrapper,
    rootPath: `/${RelativePath.ELECTION_REVIEW}`,
  },
};

const replaceClient = (path: RelativePath, clientId: string) =>
  path.replace(":clientId", clientId);

export const ClientLevelPages: ClientLevelPages = {
  Dashboard: {
    name: SectionHeader.DASHBOARD,
    getPath: (clientId: string | undefined) =>
      replaceClient(RelativePath.DASHBOARD, clientId ?? ""),
  },
  InvestmentsAndCarry: {
    name: SectionHeader.INVESTMENTS_AND_CARRY,
    getPath: (clientId: string | undefined) =>
      replaceClient(RelativePath.INVESTMENTS_AND_CARRY, clientId ?? ""),
  },
  Commitments: {
    name: SectionHeader.COMMITMENTS,
    getPath: (clientId: string | undefined) =>
      replaceClient(RelativePath.COMMITMENTS, clientId ?? ""),
  },
  Equity: {
    name: SectionHeader.EQUITY,
    getPath: (clientId: string | undefined) =>
      replaceClient(RelativePath.CLIENT_EQUITY, clientId ?? ""),
  },
};
