import {
  ElectionWorkflowPage,
  EnvironmentResolver,
  PathFragment,
  WorkflowTypeEntity,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { HasEntitlementContainer } from "../../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import { ElectionWorkflowPages } from "../../../constants/Pages/ElectionWorkflowPages";
import { RelativePath } from "../../../constants/Paths";
import { AdminUIStore } from "../../../redux/store";
import { useActiveElection } from "../Hooks/useActiveElectionHook";
import { useElectionQueryParams } from "../Hooks/useElectionQueryParamsHook";

export const ElectionWorkflowPageWrapper = () => {
  const { electionRoundId, mdmInvestmentVehicleId } = useElectionQueryParams();
  useActiveElection();

  const { isBankAccountsAdmin } = useSelector(
    (state: AdminUIStore) => state.entitlements
  );

  const electionListPath = useMemo(() => {
    return electionRoundId
      ? (RelativePath.ELECTOR_VIEW_PAGE.replace(
          ":electionRoundId",
          electionRoundId
        ) as RelativePath)
      : (RelativePath.ELECTOR_VIEW_PAGE.replace(
          ":electionRoundId",
          EnvironmentResolver.ENV.REACT_APP_ELECTION_ROUND_ID
        ) as RelativePath);
  }, [electionRoundId]);

  return (
    <HasEntitlementContainer
      entitlementLoadStatusName="isElectionsAdminLoadStatus"
      entitlementName="isElectionsAdmin"
    >
      <ElectionWorkflowPage
        canReadBankAccounts={isBankAccountsAdmin}
        ElectionWorkflowPages={ElectionWorkflowPages}
        electionListPath={electionListPath}
        electionDetailPath={`/${PathFragment.ELECTIONS}/${electionRoundId}/elector-view/${mdmInvestmentVehicleId}`}
        workflowType={WorkflowTypeEntity.Admin}
      />
    </HasEntitlementContainer>
  );
};
