import { Chip, Tooltip } from "@mui/material";
import clsx from "clsx";
import { ElectionsLabels } from "common";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useElectionCountdownTimer } from "../../../../../../hooks/useElectionCountdownTimer";
import { selectElectionRoundConfiguration } from "../../../../../../redux/selectors";
import styles from "./DaysLeftChip.module.scss";

export const DaysLeftChip = () => {
  const { electionSubmissionDeadline } = useSelector(
    selectElectionRoundConfiguration
  );
  const [daysLeft, hoursLeft] = useElectionCountdownTimer(
    electionSubmissionDeadline
  );

  const getChipColorByDaysLeft = useCallback((): string => {
    if (daysLeft < 0) {
      return styles.expired;
    } else if (daysLeft < 2) {
      return styles.critical;
    } else if (daysLeft <= 5) {
      return styles.considerate;
    } else {
      return styles.ample;
    }
  }, [daysLeft]);

  const getChipMessage = useCallback(() => {
    if (daysLeft < 0) {
      return ElectionsLabels.DEADLINE_PASSED;
    }
    if (daysLeft < 1) {
      if (hoursLeft <= 12) {
        return ElectionsLabels.DUE_AT(electionSubmissionDeadline);
      }
      return ElectionsLabels.HOURS_LEFT(hoursLeft);
    }
    if (daysLeft < 2) {
      return ElectionsLabels.DAYS_AND_HOURS_LEFT(daysLeft, hoursLeft);
    }
    return ElectionsLabels.DAYS_LEFT(daysLeft);
  }, [daysLeft, hoursLeft, electionSubmissionDeadline]);

  return (
    <Tooltip
      title={ElectionsLabels.SUBMISSION_DEADLINE(electionSubmissionDeadline)}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      placement={"bottom-end"}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <Chip
        className={clsx(styles.chip, getChipColorByDaysLeft())}
        label={getChipMessage()}
        size="small"
      />
    </Tooltip>
  );
};
