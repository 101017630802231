import { IEntity, QuarterDate } from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { Optional } from "../types/typeUtils";

/**
 * Returns true if the date is valid
 * @param date - The input date
 * @returns boolean value
 */
const isValidDate = (date: Date): boolean => {
  return isFinite(+(date instanceof Date));
};

const createCurrencyNumberFormat = (
  maximumFractionDigits: number
): Intl.NumberFormat => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits,
  });
};

export const zeroIfNaN = (val: number) => {
  return isNaN(val) ? 0 : val;
};

// Return a zero if the value is nothing for an optional number
export const zeroIfNothing = (amount: Optional<number>) => {
  return isSomething(amount) ? amount.value : 0;
};

// Return an empty string if the value is nothing for an optional string
export const emptyIfNothing = (source: Optional<string>) => {
  return isSomething(source) ? source.value : "";
};

export const formatPercentToOneDecimal = (
  percent: number | undefined
): string => {
  return `${(percent ?? 0).toFixed(1)}%`;
};

export const getRoundedPercentFormatted = (
  percent: number | undefined
): string => {
  if (percent === Infinity || percent === undefined) {
    return "-";
  }
  const wholePercent = percent * 100;
  return `${wholePercent.toFixed(0)}%`;
};

const CURRENCY_NUMBER_FORMAT = createCurrencyNumberFormat(0);
const TWO_DP_CURRENCY_NUMBER_FORMAT = createCurrencyNumberFormat(2);
const UP_TO_TWO_DP_NUMBER_FORMAT = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
});
const EXACTLY_TWO_DP_NUMBER_FORMAT = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
const ROUNDED_NUMBER_FORMAT = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
  currencySign: "accounting",
});

export const getRoundedNumberWithZeroDefault = (
  value: number | undefined
): string => {
  return value !== undefined && value !== 0
    ? ROUNDED_NUMBER_FORMAT.format(value)
    : ROUNDED_NUMBER_FORMAT.format(0);
};

// Format number as exactly two decimal places - Zero as default if the param value is undefined
export const getExactly2DPNumberFormattedWithZeroDefault = (
  value: number | undefined
): string =>
  value !== undefined && value !== 0
    ? EXACTLY_TWO_DP_NUMBER_FORMAT.format(value)
    : EXACTLY_TWO_DP_NUMBER_FORMAT.format(0);

// Format number as up to two decimal places - Zero as default if the param value is undefined
export const get2DPNumberFormattedWithZeroDefault = (
  value: number | undefined
): string =>
  value !== undefined && value !== 0
    ? UP_TO_TWO_DP_NUMBER_FORMAT.format(value)
    : UP_TO_TWO_DP_NUMBER_FORMAT.format(0);

export const get2DPNumberFormattedWithZeroDefaultForOptional = (
  value: Optional<number>
): string => get2DPNumberFormattedWithZeroDefault(zeroIfNothing(value));

// Format currency values as USD - Dash (-) as default if the param value is undefined or zero
export const getCurrencyFormattedValue = (value: number | undefined): string =>
  value !== undefined && value !== 0
    ? CURRENCY_NUMBER_FORMAT.format(value)
    : "-";

// Format currency values as USD - middle dash as default (wirhout $) if the param value is nothing
export const getCurrencyFormattedValueWithDashDefault = (
  amount: Optional<number>
) => {
  return isSomething(amount) ? getCurrencyFormattedValue(amount.value) : "-";
};

// Format currency values as USD - Zero as default if the param value is undefined or zero
export const getCurrencyFormattedValueWithZeroDefault = (
  value: number | undefined
): string =>
  value !== undefined && value !== 0
    ? CURRENCY_NUMBER_FORMAT.format(value)
    : CURRENCY_NUMBER_FORMAT.format(0);

export const getCurrencyFormattedValueWithZeroDefaultForOptional = (
  value: Optional<number>
): string => getCurrencyFormattedValueWithZeroDefault(zeroIfNothing(value));

// Format currency values as USD with two decimal places - Zero as default if the param value is undefined
export const getCurrencyFormattedTwoDPValueWithZeroDefault = (
  value: number | undefined
): string =>
  value !== undefined && value !== 0
    ? TWO_DP_CURRENCY_NUMBER_FORMAT.format(value)
    : TWO_DP_CURRENCY_NUMBER_FORMAT.format(0);

// Format number as a multiplier
export const getMultiplierFormattedValue = (
  value: number | undefined
): string =>
  value !== undefined ? `${UP_TO_TWO_DP_NUMBER_FORMAT.format(value)}x` : "-";

// Format a pre-computed percentage value
export const getPreComputedPercentageFormattedValueOrDash = (
  value: number | undefined
): string => {
  if (value === undefined || value === 0) {
    return "-";
  }
  return Math.round(value) + "%";
};

// Format a pre-computed percentage value
export const getPreComputedPercentageFormattedValue = (
  value: number | undefined
): string => {
  return Math.round(value ?? 0) + "%";
};

// Compute and format percentage as whole numbers
export const getPercentageFormattedValue = (
  numerator: number | undefined,
  denominator: number | undefined
): string => {
  if (numerator == undefined || denominator == undefined) {
    return "-";
  }
  return Math.round((numerator / denominator) * 100) + "%";
};

// Compute and format percentage as whole numbers returns number value
export const getPercentageAsDecimalFormattedValue = (
  numerator: number | undefined,
  denominator: number | undefined
): number | null => {
  if (numerator == undefined || denominator == undefined || denominator == 0) {
    return null;
  }
  return numerator / denominator;
};

export const formatDateString = (
  date: Date | undefined,
  format: Intl.DateTimeFormatOptions
): string => {
  return date ? date.toLocaleDateString("en-US", format) : "";
};

// Format dates as Aug 9, 2022
export const formatDateMMMDDYYYYOrNull = (date: Date | undefined): string => {
  return formatDateMMMDDYYYY(date) || "-";
};

// Format dates as Aug 9, 2022 or empty string if not valid date or returns dash if the date is in the past
export const getFormattedDateWithDashForPastEvents = (
  date: Date | undefined
): string => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const inputDate = date ? new Date(date) : undefined;

  if (inputDate && inputDate < today) {
    return "-";
  }

  return formatDateMMMDDYYYYOrNull(inputDate);
};

/**
 * Formats a date as: Aug 2022
 * @param date - The date
 * @returns A string
 */
export const getFormattedDateMMMYYYY = (date: Date | undefined): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "numeric",
  };
  return formatDateString(date, format);
};

/**
 * Formats a date as: Aug 22
 * @param date - The input date
 * @returns A formatted date string
 */
export const getFormattedDateMMMYY = (date: Date | undefined): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "2-digit",
  };
  return formatDateString(date, format);
};

// Format dates as Aug 9, 2022 or empty string if not valid date
export const formatDateMMMDDYYYY = (date: Date | undefined): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return formatDateString(date, format);
};

// Format dates as Aug 9, 2022 or empty string if not valid date
//  does not consider time or timezone
export const formatDateTimeUTCMMMDDYYYY = (date: Date | undefined): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  };
  const formatter = new Intl.DateTimeFormat("en-US", format);
  return date ? formatter.format(date) : "";
};

export const getFormattedDateMMMDDYYYYOrNA = (date: Date): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return date === undefined || date === null
    ? "N/A"
    : formatDateString(date, format);
};

export const getFormattedDateMMMDD = (date: Date): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  return formatDateString(date, format);
};

function getOrdinalSuffix(number: number): string {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = number % 100;
  const suffix =
    suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
  return suffix;
}

/*
formats date as 5pm in January 1st, 2024
*/
export const getFormattedDateTimeOnMDYOrNA = (
  date: Date | undefined
): string => {
  if (date === undefined) {
    return "N/A";
  }

  const hour = date.getHours();
  const minute = date.getMinutes();
  const formattedTime = `${hour > 12 ? hour - 12 : hour}:${minute
    .toString()
    .padStart(2, "0")} ${hour >= 12 ? "PM" : "AM"}`;

  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${formattedTime} on ${month} ${day}${getOrdinalSuffix(
    day
  )}, ${year}`;

  return formattedDate;
};

/**
 * Pulls time from a date and format that time as: 1:30 PM ET
 * @param date - The input date
 * @returns A local time string
 */
export const getFormattedLocalTime = (date: Date | undefined): string => {
  const format: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "shortGeneric",
  };
  return date ? date.toLocaleTimeString("en-US", format) : "";
};

/**
 * Return a formatted string, time (12 hours clock) and time zone.
 * @param date - The input date
 * @returns  A date and time string with timezone
 */
export const getDateAndTime = (
  dateInput: string | Date | undefined | null,
  setToEasternTimeZone = false
): string => {
  if (dateInput === undefined || dateInput === null) {
    return "";
  }
  const date = new Date(dateInput);
  const formattedDate = getFormattedDateYYYYMMDD(date, true);
  const options: Intl.DateTimeFormatOptions = {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  };
  if (setToEasternTimeZone) {
    options.timeZone = "America/New_York";
  }
  const formattedTime = Intl.DateTimeFormat("en-US", options).format(date);
  const zoneOptions: Intl.DateTimeFormatOptions = {
    timeZoneName: "shortGeneric",
  };
  if (setToEasternTimeZone) {
    zoneOptions.timeZoneName = "short";
    zoneOptions.timeZone = "America/New_York";
  }
  const timeZone =
    Intl.DateTimeFormat("en-US", zoneOptions)
      .formatToParts()
      .find((p) => p.type === "timeZoneName")?.value ?? "";
  return `${formattedDate} at ${formattedTime} ${timeZone}`;
};

/**
 * Return a formatted as of string, time (12 hours clock) and time zone.
 * @param date - The input date
 * @returns  A local date and time string
 */
export const getAsOfDateAndTime = (date: Date): string => {
  const formattedDate = getFormattedDateYYYYMMDD(date, true);
  const formattedTime = Intl.DateTimeFormat("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
  return `(as of ${formattedDate} at ${formattedTime} ${getAbbreviatedTimeZone()})`;
};

/**
 * Return the date, time (12 hours clock): Jan 10, 2024 9:00 PM
 * @param date - The input date
 * @returns  A local date and time string
 */
export const formatDateAndTimeMMMDDYYYY = (date: Date): string => {
  // Define the date format options
  const dateFormat: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  // Define the time format options
  const timeFormat: Intl.DateTimeFormatOptions = {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  };

  if (!date) return "";

  const formattedDate = new Intl.DateTimeFormat("en-US", dateFormat).format(
    date
  );
  const formattedTime = new Intl.DateTimeFormat("en-US", timeFormat).format(
    date
  );

  // Concatenate the formatted date and time without a comma
  return `${formattedDate} ${formattedTime}`;
};

/**
 * Return the date, time (12 hours clock) and time zone.
 * @param date - The input date
 * @returns  A local date and time string
 */
export const formatDateTimeAndZoneMMMDDYYYY = (date: Date): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "shortGeneric",
  };

  return formatDateString(date, format);
};

/**
 * Return the time zone abbreviated
 * @returns string
 */
const getAbbreviatedTimeZone = (): string => {
  return (
    Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
      .formatToParts()
      .find((p) => p.type === "timeZoneName")?.value ?? ""
  );
};

/**
 * Return a formatted date (year, month and day)
 * @param date - The input date
 * @param withSlash - A input bolean
 * @returns A local formatted date
 */
export const getFormattedDateYYYYMMDD = (
  date: Date,
  withSlash: boolean
): string => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return withSlash ? `${year}/${month}/${day}` : `${year}${month}${day}`;
};

// Format date as 2022 Q2
export const getFormattedQuarterDate = (date: Date): string => {
  const year = date.getUTCFullYear();

  //Divide month by 3 and floor to get quarter. Add one because of zero indexing.
  const quarter = Math.floor(date.getUTCMonth() / 3) + 1;

  return `${year} Q${quarter}`;
};

// Convert camelCase variable name to Capitalized Label
export const getLabelFromCamelCase = (camelCaseName: string): string => {
  return camelCaseName
    .replace(/([A-Z])/g, (match) => ` ${match}`) // add spaces before capital letters
    .replace(/^./, (match) => match.toUpperCase()) // capitalize first letter
    .trim();
};

// Format tick values for charting
export const formatTickByUnit = (value: number): string => {
  // NOTE: This function does not specify a fixed number of
  // decimal places, as such it should be given round numbers
  // to avoid excessively long decimal strings

  // Nine Zeroes for Billions
  const formattedNumber =
    "$" +
    (Math.abs(value) >= 1.0e9
      ? (Math.abs(value) / 1.0e9).toString() + "B"
      : // Six Zeroes for Millions
      Math.abs(value) >= 1.0e6
      ? (Math.abs(value) / 1.0e6).toString() + "M"
      : // Three Zeroes for Thousands
      Math.abs(value) >= 1.0e3
      ? (Math.abs(value) / 1.0e3).toString() + "K"
      : String(Math.abs(value)));

  return value >= 0 ? formattedNumber : `(${formattedNumber})`;
};

export const mapValueToZeroIfUndefined = (value: number | undefined) =>
  typeof value === "number" ? (value as number) : 0;

//Replace Blackstone word to BX
export const replaceBlackstoneString = (value: string): string => {
  return value.replace("Blackstone", "BX");
};

/**
 * Returns the first day of a quarter,
 * by default it returns the first day of the first quarter,
 * Note: date month index is a number between 0 amd 11
 * @param date - The input date
 * @returns A formatted date
 */
export const getFirstDayOfQuarter = (date: Date): Date => {
  if (!isValidDate(date)) {
    return new Date(new Date().getFullYear(), 0, 1);
  }

  const quarterDate = new QuarterDate(date);
  return new Date(quarterDate.getFullYear(), quarterDate.quarter * 3, 1);
};

/*
Returns the entity name, followed by the entity ein(s) in parenthesis (if applicable)
  Not all entities have eins, so for those that do not, this will just return the entity name.
  For those with multiple eins, this will return a comma separated list in parenthesis
*/
export const getEntityLabel = (entity: IEntity): string => {
  if (entity) {
    let entityString = `${entity.name}`;
    if (entity.ein && entity.ein.length > 0) {
      entityString += ` (${entity.ein.join(", ")})`;
    }
    return entityString;
  }
  return "";
};

export const listNames = (names: string[]): string => {
  const sortedNames = names.sort((a, b) => a.localeCompare(b));
  const count = names.length;
  switch (count) {
    case 0:
      return "";
    case 1:
      return sortedNames[0];
    case 2:
      return sortedNames.join(" and ");
    default:
      return `${sortedNames.slice(0, -1).join(", ")} and ${
        sortedNames[count - 1]
      }`;
  }
};

/* 
This function takes a string `input` and an integer `wordsPerLine`, 
it splits the `input` string, and returns an array of strings where 
each element contains a specified number of words (defined by `wordsPerLine`), 
separated by newlines ('\n'). This allows for formatting text into manageable lines. 
*/
export function splitWithNewline(input: string, wordsPerLine: number) {
  const words = input.split(/\s+/);
  const result: string[] = [];

  for (let i = 0; i < words.length; i += wordsPerLine) {
    const chunk = words.slice(i, i + wordsPerLine).join(" ");
    result.push(chunk);

    if (i + wordsPerLine < words.length) {
      result.push("\n");
    }
  }

  return result;
}
