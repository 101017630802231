import { ApiPath } from "../constants/ApiPathConstants";
import { StockSymbol } from "../constants/enums";
import { IStockDataValue } from "../types/dataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { handleResponse } from "./utils";

export type IStockSourceDataValue = Json<IStockDataValue>;

export const getSupportedStocks = async (): Promise<Maybe<StockSymbol[]>> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_URL_BASE}/stocks`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return handleResponse(response);
};

export const getStockData = async (
  symbol: string
): Promise<Maybe<IStockSourceDataValue[]>> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_URL_BASE}/stocks/${symbol}`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return handleResponse(response);
};
