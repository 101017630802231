import {
  ElectionWorkflowLabels,
  ElectionWorkflowStageId,
  ElectionWorkflowStageIdToLabel,
  ElectorStatusExcelConstants,
  getDateAndTime,
  IElectionsForElectionRoundRow,
  IPermissionedUserRow,
} from "common";
import ExcelJS from "exceljs";

import { WorksheetGeneratorParams } from "../../types/excelTypes";

export interface IElectorStatusRow {
  mdmInvestmentVehicleId: number;
  name: string;
  step: string;
  reopenedDate: string;
  submissionDateTime: string;
  permissionedUserName: string;
  permissionedUserEmail: string;
}

const electorStatusColumns: Partial<ExcelJS.Column>[] = [
  {
    header: "Investment Vehicle Id",
    key: ElectorStatusExcelConstants.KEYS.IV_ID,
    width: ElectorStatusExcelConstants.SIZES.MEDIUM,
  },
  {
    header: "Investment Vehicle Name",
    key: ElectorStatusExcelConstants.KEYS.IV_NAME,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
  {
    header: "Current Step",
    key: ElectorStatusExcelConstants.KEYS.STEP,
    width: ElectorStatusExcelConstants.SIZES.MEDIUM,
  },
  {
    header: "Submitted Date",
    key: ElectorStatusExcelConstants.KEYS.SUBMITTED_DATE,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
  {
    header: "Reopened Date",
    key: ElectorStatusExcelConstants.KEYS.REOPENED_DATE,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
  {
    header: "Permissioned User Name",
    key: ElectorStatusExcelConstants.KEYS.PERMISSIONED_USER_NAME,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
  {
    header: "Permissioned User Email Address",
    key: ElectorStatusExcelConstants.KEYS.PERMISSIONED_USER_EMAIL,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
];

const getRows = (
  data: IElectionsForElectionRoundRow[] | undefined
): IElectorStatusRow[] => {
  const electorResponseRows: IElectorStatusRow[] = [];
  if (data === undefined) {
    return electorResponseRows;
  }

  const mapElectionsForElectionRoundRowToElectorStatusRow = (
    row: IElectionsForElectionRoundRow,
    permissionedUser?: IPermissionedUserRow
  ): IElectorStatusRow => ({
    mdmInvestmentVehicleId: row.mdmInvestmentVehicleId,
    name: row.name,
    step:
      ElectionWorkflowStageIdToLabel[
        row.currentStage as ElectionWorkflowStageId
      ] ?? ElectionWorkflowLabels.UNKNOWN,
    submissionDateTime: getDateAndTime(row.submissionDateTime, true),
    reopenedDate: getDateAndTime(row.reopenedDate, true),
    permissionedUserName: permissionedUser?.fullname ?? "",
    permissionedUserEmail: permissionedUser?.email ?? "",
  });

  electorResponseRows.push(
    ...data.reduce(
      (acc: IElectorStatusRow[], current: IElectionsForElectionRoundRow) => {
        if (current.permissionedUsers.length == 0) {
          return [
            ...acc,
            mapElectionsForElectionRoundRowToElectorStatusRow(current),
          ];
        }
        const mappedRows = current.permissionedUsers.map(
          (permissionedUser: IPermissionedUserRow) =>
            mapElectionsForElectionRoundRowToElectorStatusRow(
              current,
              permissionedUser
            )
        );
        return [...acc, ...mappedRows];
      },
      []
    )
  );

  return electorResponseRows;
};

export const buildElectorStatusWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { settings, workbook } = params;
  const worksheet = workbook.addWorksheet("Electors", {
    views: [
      {
        state: "frozen",
        ySplit: 1,
      },
    ],
  });
  worksheet.columns = electorStatusColumns;
  worksheet.addRows(getRows(settings.electorResponse));
  const headerRow = worksheet.getRow(1);
  headerRow.font = { ...headerRow.font, bold: true };
  headerRow.alignment = { ...headerRow.alignment, horizontal: "left" };
  return workbook;
};
