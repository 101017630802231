import { useCallback, useEffect, useState } from "react";

const ONE_DAY_HOURS = 24;
const ONE_HOUR_MS = 1000 * 60 * 60;
const ONE_DAY_MS = ONE_HOUR_MS * ONE_DAY_HOURS;

export const useElectionCountdownTimer = (electionSubmissionDeadline: Date) => {
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [hoursLeft, setHoursLeft] = useState<number>(0);

  const getMillisecondTimeUntil = (dueDate: Date) => {
    return dueDate.getTime() - new Date().getTime();
  };

  const calculateTimeLeft = useCallback(() => {
    const timeInMs = getMillisecondTimeUntil(electionSubmissionDeadline);
    setDaysLeft(Math.floor(timeInMs / ONE_DAY_MS));
    setHoursLeft(Math.floor(timeInMs / ONE_HOUR_MS) % ONE_DAY_HOURS);
  }, [electionSubmissionDeadline]);

  useEffect(() => {
    calculateTimeLeft();
    // Calculate the time left every 1 hour
    const intervalId = setInterval(calculateTimeLeft, ONE_HOUR_MS);

    return () => clearInterval(intervalId);
  }, [calculateTimeLeft]);

  return [daysLeft, hoursLeft];
};
