import { Breadcrumbs, Grid, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IDashboardCardContentProps } from "../../../../components/DashboardCard/DashboardCard";
import { HoverableChartLegends } from "../../../../components/HoverableChartLegends/HoverableChartLegends";
import { CalloutPersona, TreeNumber } from "../../../../constants/enums";
import { ALL_FUNDS_PLACEHOLDER } from "../../../../constants/InvestmentBreakdownConstants";
import { UnrealizedValueLabels } from "../../../../constants/LabelAndTooltipConstants";
import { MUIConstants } from "../../../../constants/MUIConstants";
import { setPointerType } from "../../../../redux/actions/viewActions";
import {
  IInvestmentAllocationCardData,
  IInvestmentBreakdownDatum,
  IInvestmentChartData,
} from "../../../../types/dataTypes";
import { isSomething } from "../../../../types/typeGuards";
import { nothing, Optional, some } from "../../../../types/typeUtils";
import { mapInvestmentDataToChartLegends } from "../../../../utils/charting";
import {
  createInvestmentChartList,
  filterToPositiveInvestmentsByBusinessUnit,
  filterToPositiveInvestmentsByFund,
  sortAndReduceInvestmentCardData,
} from "../../../../utils/investmentCardUtils";
import styles from "./InvestmentAllocation.module.scss";
import { InvestmentChart } from "./InvestmentChart/InvestmentChart";
import { InvestmentCardTable } from "./InvestmentTable/InvestmentCardTable";

export const InvestmentAllocation = (
  props: IDashboardCardContentProps<IInvestmentAllocationCardData>
) => {
  const dispatch = useDispatch();

  const { investmentBreakdownGrouped, calloutData, calloutPersona } =
    props.data;
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<Optional<IInvestmentChartData>>(nothing);
  const unrealizedInvestments = isSomething(calloutData.unrealized)
    ? calloutData.unrealized.value.unrealizedOptionalAndMandatoryInvestments
        .value
    : undefined;
  const remainingCapitalInvested: number | undefined = isSomething(
    calloutData.unrealized
  )
    ? calloutData.unrealized.value.unrealizedCapitalInvested.value
    : undefined;
  const gainLoss: number | undefined = isSomething(calloutData.unrealized)
    ? calloutData.unrealized.value.unrealizedGainLoss.value
    : undefined;
  const showTable: boolean =
    calloutPersona !== CalloutPersona.CARRY ||
    !(remainingCapitalInvested || gainLoss);

  const investmentCardChartData = useMemo(() => {
    if (isSomething(investmentBreakdownGrouped)) {
      let filteredInvestments: IInvestmentBreakdownDatum[] = [];

      if (isSomething(selectedBusinessUnit)) {
        /*
          When there's a business unit selected, we should show
          investments grouped by funds for that selected 
          business unit.
        */
        filteredInvestments = filterToPositiveInvestmentsByFund(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT],
          selectedBusinessUnit.value.businessUnitName
        );
      } else {
        /*
          When there's not business unit selected, we should show
          investments grouped by business unit level.
        */
        filteredInvestments = filterToPositiveInvestmentsByBusinessUnit(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT]
        );
      }
      // Convert IInvestmentBreakdownDatum list into IInvestmentChartData list
      const investmentChartList =
        createInvestmentChartList(filteredInvestments);
      /* 
        Sort and reduce the data to show the top 5 and all the remaining
        as 'Other' category
      */
      return sortAndReduceInvestmentCardData(
        investmentChartList,
        isSomething(selectedBusinessUnit)
      );
    }
  }, [investmentBreakdownGrouped, selectedBusinessUnit]);

  /*
    handleLegendClick function handles the click on left side legends
    On business unit level, we should show data grouped by funds.
    On funds level, the legends shouldn't change anything.
  */
  const handleLegendClick = useCallback(
    (legendItemName: string, event: React.PointerEvent) => {
      dispatch(setPointerType(event.pointerType));
      if (event.pointerType === "mouse") {
        setActiveIndex(undefined);
        const investmentChartData = investmentCardChartData?.find(
          (data) => data.businessUnitName === legendItemName
        );
        if (
          investmentChartData &&
          investmentChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER
        ) {
          setSelectedBusinessUnit(some(investmentChartData));
        }
      } else {
        const selectedIndex = investmentCardChartData?.findIndex(
          (data) =>
            data.businessUnitName === legendItemName ||
            data.fundShortName === legendItemName
        );
        setActiveIndex(selectedIndex);
      }
    },
    [dispatch, investmentCardChartData]
  );

  /*
  handleAllBusinessClick function handles the click on
  "All Business Unit" navigation crumb.
*/
  const handleAllBusinessClick = () => {
    if (isSomething(selectedBusinessUnit)) {
      setSelectedBusinessUnit(nothing);
    }
  };

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        xs={12}
        md={
          showTable
            ? MUIConstants.WIDGET_TREE_COLUMN_LEFT_ITEM_SIZE
            : MUIConstants.WIDGET_TREE_COLUMN_CENTER_ITEM_SIZE
        }
        className={styles.legends}
      >
        <Breadcrumbs className={styles.breadcrumbs}>
          <span onClick={handleAllBusinessClick}>
            <Typography
              variant="subtitle1"
              style={{
                cursor: isSomething(selectedBusinessUnit)
                  ? "pointer"
                  : "default",
              }}
            >
              {UnrealizedValueLabels.BUSINESS_UNITS}
            </Typography>
          </span>
          {isSomething(selectedBusinessUnit) && (
            <Typography variant="subtitle1" className={styles.funds}>
              {selectedBusinessUnit.value.businessUnitName}
            </Typography>
          )}
        </Breadcrumbs>
        {investmentCardChartData && investmentCardChartData.length > 0 && (
          <HoverableChartLegends
            legends={mapInvestmentDataToChartLegends(
              investmentCardChartData,
              handleLegendClick,
              isSomething(selectedBusinessUnit)
            )}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={
          showTable
            ? MUIConstants.WIDGET_TREE_COLUMN_CENTER_ITEM_SIZE
            : MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING
        }
        className={styles.chartContainer}
      >
        <InvestmentChart
          investmentChartData={investmentCardChartData || []}
          unrealizedInvestments={
            isSomething(selectedBusinessUnit)
              ? selectedBusinessUnit.value.unrealizedValue
                  .sbsAndMandatoryInvestments
              : unrealizedInvestments
          }
          verticalAlignCenter={!showTable}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          setSelectedBusinessUnit={setSelectedBusinessUnit}
        />
      </Grid>
      {showTable && (
        <Grid item xs={12} md={MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE}>
          <InvestmentCardTable
            remainingCapitalInvested={
              isSomething(selectedBusinessUnit)
                ? selectedBusinessUnit.value.unrealizedValue
                    .remainingCapitalInvested
                : remainingCapitalInvested
            }
            gainLoss={
              isSomething(selectedBusinessUnit)
                ? selectedBusinessUnit.value.unrealizedValue.gainLoss
                : gainLoss
            }
            total={
              isSomething(selectedBusinessUnit)
                ? selectedBusinessUnit.value.unrealizedValue
                    .sbsAndMandatoryInvestments
                : unrealizedInvestments
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
