import { Box } from "@mui/material";
import {
  AdminClientLabels,
  getEntityId,
  IAdminClient,
  IBaseStore,
  IInvestmentVehicleByClientByPeriod,
  isEntityLoaded,
  isInProgress,
  isLoading,
  isSomething,
  LoadingIndicator,
  NoDataAvailableError,
  PathFragment,
  PeriodHash,
  reqClientDistributionsData,
  reqClientEquityData,
  reqInternalInvestmentData,
  reqStockData,
  selectCanViewCommitmentsPage,
  selectCanViewDashboard,
  selectCanViewInvestmentsAndCarry,
  setActiveEquityDataId,
} from "common";
import { selectCanViewEquityPage } from "common/src/redux/selectors/equitySelectors";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { HasAnyEntitlementsContainer } from "../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import { RelativePath } from "../../constants/Paths";
import { AdminUIStore } from "../../redux/store";
import { AdminUIEntitlementStore } from "../../types/storeTypes";
import CommitmentsWrapper from "../Commitments/CommitmentsWrapper";
import DashboardWrapper from "../Dashboard/DashboardWrapper";
import EquityAdminWrapper from "../Equity/EquityAdminWrapper";
import InvestmentsAndCarryPageWrapper from "../InvestmentsAndCarry/InvestmentsAndCarryPageWrapper";
import { SubHeader } from "../SubHeader/SubHeader";
import styles from "./ClientData.module.scss";

const ClientDataLayout = () => {
  return (
    <>
      <SubHeader />
      <Outlet />
    </>
  );
};

export const ClientData = () => {
  const { internalInvestmentAdminEntitlementLoadStatus } = useSelector(
    (state: AdminUIStore) => state.entitlements
  );

  const { selectedEntity } = useSelector(
    (state: AdminUIStore) => state.viewData
  );

  const { internalInvestmentClients, equityClients } = useSelector(
    (state: AdminUIStore) => state.clients
  );

  const internalInvestmentData = useSelector(
    (state: AdminUIStore) => state.internalInvestmentData
  );

  const canViewDashboard = useSelector((store: IBaseStore) =>
    selectCanViewDashboard(store, true)
  );

  const canViewInvestmentsAndCarryPage = useSelector((store: IBaseStore) =>
    selectCanViewInvestmentsAndCarry(store, true)
  );

  const canViewCommitmentsPage = useSelector(selectCanViewCommitmentsPage);

  const canViewEquityPage = useSelector(selectCanViewEquityPage);

  const { equityAdminEntitlementLoadStatus } = useSelector(
    (state: AdminUIStore) => state.entitlements
  );

  const equityData = useSelector((state: AdminUIStore) => state.equityData);

  const dispatch = useDispatch();

  /*
  handle requesting internal investments data based on selected entity and 
    available clients
  */
  useEffect(() => {
    if (isSomething(selectedEntity)) {
      if (
        isSomething(internalInvestmentClients) &&
        internalInvestmentClients.value.length > 0
      ) {
        const matchingInvestmentClient = internalInvestmentClients.value.find(
          (client: IAdminClient) => client.id === selectedEntity.value.clientId
        );
        if (matchingInvestmentClient) {
          const payload = {
            ...selectedEntity.value,
            periodId: PeriodHash.LATEST,
          } as IInvestmentVehicleByClientByPeriod;

          const selectedId = getEntityId(
            selectedEntity.value.clientId,
            selectedEntity.value.investmentVehicleId
          );
          const isLoaded = isEntityLoaded(
            internalInvestmentData.entities,
            selectedId
          );

          if (!isLoaded) {
            dispatch(reqInternalInvestmentData(payload));
          }
        }
      }
    }
  }, [
    selectedEntity,
    internalInvestmentData.entities,
    internalInvestmentClients,
    dispatch,
  ]);

  /*
    handle requesting equity data based on selected entity and 
    available clients
  */
  useEffect(() => {
    if (isSomething(selectedEntity)) {
      if (isSomething(equityClients) && equityClients.value.length > 0) {
        const matchingEquityClient = equityClients.value.find(
          (client: IAdminClient) => client.id === selectedEntity.value.clientId
        );

        if (matchingEquityClient) {
          // request equity data
          dispatch(setActiveEquityDataId(selectedEntity.value.clientId));
          dispatch(reqClientEquityData(selectedEntity.value.clientId));
          dispatch(reqClientDistributionsData(selectedEntity.value.clientId));
          dispatch(reqStockData());
        }
      }
    }
  }, [selectedEntity, equityClients, dispatch]);

  const { pathname } = location;
  const clientId = pathname.split("/")[2];

  const SetRouteByData = useCallback(() => {
    if (
      // are the entitlements not requested or loading
      isInProgress(
        internalInvestmentAdminEntitlementLoadStatus,
        equityAdminEntitlementLoadStatus
      ) ||
      // is data still actively loading
      isLoading(
        internalInvestmentData.internalInvestmentDataLoadStatus,
        equityData.equityDataLoadStatus,
        equityData.distributionsDataLoadStatus,
        equityData.stockDataLoadStatus
      ) ||
      clientId === undefined
    ) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }

    return canViewDashboard || canViewEquityPage ? (
      <Navigate
        to={RelativePath.DASHBOARD.replace(":clientId", clientId.toString())}
      />
    ) : canViewInvestmentsAndCarryPage ? (
      <Navigate
        to={RelativePath.INVESTMENTS_AND_CARRY.replace(
          ":clientId",
          clientId.toString()
        )}
      />
    ) : canViewCommitmentsPage ? (
      <Navigate
        to={RelativePath.COMMITMENTS.replace(":clientId", clientId.toString())}
      />
    ) : canViewEquityPage ? (
      <Navigate
        to={RelativePath.CLIENT_EQUITY.replace(
          ":clientId",
          clientId.toString()
        )}
      />
    ) : (
      <NoDataAvailableError />
    );
  }, [
    internalInvestmentAdminEntitlementLoadStatus,
    equityAdminEntitlementLoadStatus,
    internalInvestmentData.internalInvestmentDataLoadStatus,
    equityData.equityDataLoadStatus,
    equityData.distributionsDataLoadStatus,
    equityData.stockDataLoadStatus,
    clientId,
    canViewEquityPage,
    canViewDashboard,
    canViewInvestmentsAndCarryPage,
    canViewCommitmentsPage,
  ]);

  // list of tuples of type [entitlementName, entitlementLoadStatusName]
  const entitlementsToCheck: Array<
    [
      name: keyof AdminUIEntitlementStore,
      loadStatus: keyof AdminUIEntitlementStore
    ]
  > = [
    [
      "hasInternalInvestmentAdminEntitlement",
      "internalInvestmentAdminEntitlementLoadStatus",
    ],
    ["hasEquityAdminEntitlement", "equityAdminEntitlementLoadStatus"],
  ];

  return (
    <>
      <HasAnyEntitlementsContainer entitlementsToCheck={entitlementsToCheck}>
        <Routes>
          <Route element={<ClientDataLayout />}>
            <Route
              path={"/:clientId/*"}
              element={
                <>
                  <Box className={styles.internalInvestmentsContent}>
                    <Routes>
                      {(canViewDashboard || canViewEquityPage) && (
                        <Route
                          path={PathFragment.DASHBOARD}
                          element={<DashboardWrapper />}
                        />
                      )}
                      {canViewInvestmentsAndCarryPage && (
                        <Route
                          path={`${PathFragment.INVESTMENTS_AND_CARRY}/*`}
                          element={<InvestmentsAndCarryPageWrapper />}
                        />
                      )}
                      {canViewCommitmentsPage && (
                        <Route
                          path={PathFragment.COMMITMENTS}
                          element={<CommitmentsWrapper />}
                        />
                      )}
                      {canViewEquityPage && (
                        <Route
                          path={`${PathFragment.EQUITY}/*`}
                          element={<EquityAdminWrapper />}
                        />
                      )}
                      <Route path={"/*"} element={SetRouteByData()} />
                    </Routes>
                  </Box>
                </>
              }
            />
            <Route
              path={""}
              element={
                <>
                  <Box className={styles.landingPage}>
                    <Box
                      component="img"
                      alt={"Landing Page background"}
                      src="/assets/images/AdminLanding.png"
                      className={styles.landingImage}
                    />
                    <h1 className={styles.landingText}>
                      {AdminClientLabels.PLEASE_SEARCH}
                    </h1>
                  </Box>
                </>
              }
            />
          </Route>
        </Routes>
      </HasAnyEntitlementsContainer>
    </>
  );
};
