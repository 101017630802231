import { IClientDto, IInvestmentVehicle } from "common";

export function combineClients(
  existingClients: IClientDto[],
  receivedClients: IClientDto[]
) {
  // update the mdmIds of any existing clients
  const updatedExisting = [...existingClients].map((existing: IClientDto) => {
    const existingClient: IClientDto = {
      ...existing,
      investmentVehicles: existing.investmentVehicles.map(
        (iv: IInvestmentVehicle) => {
          return {
            ...iv,
          };
        }
      ),
    } as IClientDto;

    const matchingNewClient = [...receivedClients].find(
      (newClient: IClientDto) => newClient.id === existingClient.id
    );

    let combinedIVs = existingClient.investmentVehicles;

    if (matchingNewClient) {
      const newIVs = matchingNewClient.investmentVehicles.filter(
        (iv: IInvestmentVehicle) =>
          !existingClient.investmentVehicles.some(
            (existingIV: IInvestmentVehicle) => existingIV.id === iv.id
          )
      );
      combinedIVs = [...combinedIVs, ...newIVs];
    }

    // per product, if the short name between clients varies, just use the alphabetically lowest one (so a not b).
    const selectedShortName =
      matchingNewClient &&
      matchingNewClient.shortName < existingClient.shortName
        ? matchingNewClient.shortName
        : existingClient.shortName;

    // per product, if the long name between clients varies, just use the alphabetically lowest one (so a not b).
    const selectedName =
      matchingNewClient && matchingNewClient.name < existingClient.name
        ? matchingNewClient.name
        : existingClient.name;

    // if the client has an mdm id, use that one
    //  otherwise, check if new match has one and use that if so
    const mdmOId =
      existingClient.mdmOId ??
      (matchingNewClient && matchingNewClient.mdmOId
        ? matchingNewClient.mdmOId
        : undefined);

    return {
      ...existingClient,
      name: selectedName,
      shortName: selectedShortName,
      investmentVehicles: combinedIVs,
      mdmOId: mdmOId,
    };
  });
  // add new clients to existing list
  const newClients = receivedClients.filter((client: IClientDto) =>
    existingClients.every(
      (existingClient: IClientDto) => existingClient.id !== client.id
    )
  );

  return [...updatedExisting, ...newClients].sort(
    (a: IClientDto, b: IClientDto) => a.name.localeCompare(b.name)
  );
}
