import ExcelJS from "exceljs";

import { InvestmentBreakdownKey, PeriodType } from "../../constants/enums";
import {
  InvestmentHistoryLabel,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { getQuarterFromDate } from "../../redux/sagas/internalInvestmentDataSagas";
import {
  ICashFlowRow,
  IPortfolioBalanceRow,
  IPortfolioPerformanceRow,
  WorksheetGeneratorParams,
} from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import {
  CASH_FLOW_KEYS,
  getKeysWithValues,
  PORTFOLIO_BALANCE_KEYS,
  PORTFOLIO_PERFORMANCE_KEYS,
  trimData,
} from "../investmentHistoryUtils";
import {
  DATE_STYLE,
  ExcelConstants,
  formatHeaderRows,
  getDateWithZeroOffset,
  GetPropertyValue,
} from "./excelUtils";

const CashFlowColumns: {
  [key in InvestmentBreakdownKey]?: Partial<ExcelJS.Column>;
} = {
  [InvestmentBreakdownKey.CAPITAL_INVESTED]: {
    header: InvestmentHistoryLabel.CAPITAL_INVESTED,
    key: InvestmentBreakdownKey.CAPITAL_INVESTED,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS]: {
    header: InvestmentHistoryLabel.REALIZED_PROCEEDS,
    key: InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.NET_CASH_FLOW]: {
    header: InvestmentHistoryLabel.NET_CASH_FLOW,
    key: InvestmentBreakdownKey.NET_CASH_FLOW,
    width: ExcelConstants.SIZES.MEDIUM,
  },
};

const PortfolioBalanceColumns: {
  [key in InvestmentBreakdownKey]?: Partial<ExcelJS.Column>;
} = {
  [InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST]: {
    header: InvestmentHistoryLabel.UNREALIZED_VALUE_CARRY,
    key: InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.SBS_AND_MANDATORY_INVESTMENTS_UNREALIZED]: {
    header: InvestmentHistoryLabel.UNREALIZED_VALUE_INVESTMENTS,
    key: InvestmentBreakdownKey.SBS_AND_MANDATORY_INVESTMENTS_UNREALIZED,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED]: {
    header: InvestmentHistoryLabel.REMAINING_INVESTMENT,
    key: InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
    width: ExcelConstants.SIZES.MEDIUM,
  },
};

const PortfolioPerformanceColumns: {
  [key in InvestmentBreakdownKey]?: Partial<ExcelJS.Column>;
} = {
  [InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED]: {
    header: InvestmentHistoryLabel.UNREALIZED_REMAINING_INVESTMENT,
    key: InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.UNREALIZED_GAIN_LOSS]: {
    header: InvestmentHistoryLabel.UNREALIZED_GAIN_LOSS,
    key: InvestmentBreakdownKey.UNREALIZED_GAIN_LOSS,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST]: {
    header: InvestmentHistoryLabel.UNREALIZED_CARRY,
    key: InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.RETURN_OF_CAPITAL]: {
    header: InvestmentHistoryLabel.REALIZED_RETURN_OF_CAPITAL,
    key: InvestmentBreakdownKey.RETURN_OF_CAPITAL,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.REALIZED_GAIN_LOSS]: {
    header: InvestmentHistoryLabel.REALIZED_GAIN_LOSS,
    key: InvestmentBreakdownKey.REALIZED_GAIN_LOSS,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  [InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST]: {
    header: InvestmentHistoryLabel.REALIZED_CARRY_INCENTIVE,
    key: InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST,
    width: ExcelConstants.SIZES.MEDIUM,
  },
};

const getInvestmentHistoryColumnDef = (
  columns: { [key in InvestmentBreakdownKey]?: Partial<ExcelJS.Column> },
  key: InvestmentBreakdownKey
) => {
  return {
    ...columns[key],
    header: `${columns[key]?.header}`,
    style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
  };
};

const addConditionalHistoricalSummaryFootnotes = (
  earliestDate: Date,
  periodType: PeriodType,
  worksheet: ExcelJS.Worksheet
) => {
  const earliestQuarter = getQuarterFromDate(earliestDate);
  if (periodType === PeriodType.YEARLY && earliestQuarter !== "Q1") {
    worksheet.addRow([]);
    const quarters = earliestQuarter === "Q4" ? "Q4" : `${earliestQuarter}-Q4`;
    worksheet.addRow([
      `*${earliestDate.getFullYear()} includes ${quarters} data`,
    ]);
  }
};

export const buildCashFlowWorksheet = (params: WorksheetGeneratorParams) => {
  const {
    workbook,
    historicalSummaryGrouped,
    internalInvestmentData,
    settings,
  } = params;
  if (isSomething(historicalSummaryGrouped)) {
    const valueDisplay = settings?.valueDisplay;
    const periodType = settings?.periodType;
    const showEmptyColumns = settings?.showEmptyColumns;

    const data = historicalSummaryGrouped.value[periodType];
    const relevantKeys = showEmptyColumns
      ? CASH_FLOW_KEYS
      : getKeysWithValues(CASH_FLOW_KEYS, data, valueDisplay);
    const trimmedData = trimData(data, CASH_FLOW_KEYS, valueDisplay);
    if (trimmedData.length <= 0) {
      return workbook;
    }
    const worksheet = workbook.addWorksheet(SectionHeader.CASH_FLOW, {
      views: [
        {
          state: "frozen",
          ySplit: ExcelConstants.HEADERS.DEFAULT_HEADER_ROWS,
        },
      ],
    });
    worksheet.columns = [
      {
        header: ExcelConstants.HEADERS.PERIOD_END_DATE,
        key: ExcelConstants.KEYS.PERIOD_END_DATE,
        width: ExcelConstants.SIZES.XSMALL,
        style: DATE_STYLE,
      },
      ...relevantKeys.map((key) =>
        getInvestmentHistoryColumnDef(CashFlowColumns, key)
      ),
    ];
    for (const datum of trimmedData.reverse()) {
      const row: ICashFlowRow = {
        periodEndDate: getDateWithZeroOffset(datum.asOfDate),
        totalRealizedProceeds: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS,
          valueDisplay
        ),
        capitalInvested: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.CAPITAL_INVESTED,
          valueDisplay
        ),
        netCashFlow: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.NET_CASH_FLOW,
          valueDisplay
        ),
      };
      worksheet.addRow(row);
    }
    addConditionalHistoricalSummaryFootnotes(
      internalInvestmentData.asOfDates.earliestAsOfDate,
      periodType,
      worksheet
    );
    formatHeaderRows(worksheet);
  }
  return workbook;
};

export const guildPortfolioBalanceWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const {
    workbook,
    historicalSummaryGrouped,
    internalInvestmentData,
    settings,
  } = params;
  if (isSomething(historicalSummaryGrouped)) {
    const valueDisplay = settings?.valueDisplay;
    const periodType = settings?.periodType;
    const showEmptyColumns = settings?.showEmptyColumns;

    const data = historicalSummaryGrouped.value[periodType];
    const relevantKeys = showEmptyColumns
      ? PORTFOLIO_BALANCE_KEYS
      : getKeysWithValues(PORTFOLIO_BALANCE_KEYS, data, valueDisplay);
    const trimmedData = trimData(data, relevantKeys, valueDisplay);
    if (trimmedData.length <= 0) {
      return workbook;
    }
    const worksheet = workbook.addWorksheet(SectionHeader.PORTFOLIO_BALANCE, {
      views: [
        {
          state: "frozen",
          ySplit: ExcelConstants.HEADERS.DEFAULT_HEADER_ROWS,
        },
      ],
    });
    worksheet.columns = [
      {
        header: ExcelConstants.HEADERS.PERIOD_END_DATE,
        key: ExcelConstants.KEYS.PERIOD_END_DATE,
        width: ExcelConstants.SIZES.XSMALL,
        style: DATE_STYLE,
      },
      ...relevantKeys.map((key) =>
        getInvestmentHistoryColumnDef(PortfolioBalanceColumns, key)
      ),
    ];
    for (const datum of trimmedData.reverse()) {
      const row: IPortfolioBalanceRow = {
        periodEndDate: getDateWithZeroOffset(datum.asOfDate),
        [InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST]: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST,
          valueDisplay
        ),
        [InvestmentBreakdownKey.SBS_AND_MANDATORY_INVESTMENTS_UNREALIZED]:
          GetPropertyValue(
            datum,
            InvestmentBreakdownKey.SBS_AND_MANDATORY_INVESTMENTS_UNREALIZED,
            valueDisplay
          ),
        [InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED]: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
          valueDisplay
        ),
      };
      worksheet.addRow(row);
    }
    addConditionalHistoricalSummaryFootnotes(
      internalInvestmentData.asOfDates.earliestAsOfDate,
      periodType,
      worksheet
    );
    formatHeaderRows(worksheet);
  }
  return workbook;
};

export const buildPortfolioPerformanceWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const {
    workbook,
    historicalSummaryGrouped,
    internalInvestmentData,
    settings,
  } = params;

  if (isSomething(historicalSummaryGrouped)) {
    const valueDisplay = settings?.valueDisplay;
    const periodType = settings?.periodType;
    const showEmptyColumns = settings?.showEmptyColumns;

    const data = historicalSummaryGrouped.value[periodType];
    const relevantKeys = showEmptyColumns
      ? PORTFOLIO_PERFORMANCE_KEYS
      : getKeysWithValues(PORTFOLIO_PERFORMANCE_KEYS, data, valueDisplay);
    const trimmedData = trimData(data, relevantKeys, valueDisplay);
    if (trimmedData.length <= 0) {
      return workbook;
    }
    const worksheet = workbook.addWorksheet(
      SectionHeader.PORTFOLIO_PERFORMANCE,
      {
        views: [
          {
            state: "frozen",
            ySplit: ExcelConstants.HEADERS.DEFAULT_HEADER_ROWS,
          },
        ],
      }
    );
    worksheet.columns = [
      {
        header: ExcelConstants.HEADERS.PERIOD_END_DATE,
        key: ExcelConstants.KEYS.PERIOD_END_DATE,
        width: ExcelConstants.SIZES.XSMALL,
        style: DATE_STYLE,
      },
      ...relevantKeys.map((key) =>
        getInvestmentHistoryColumnDef(PortfolioPerformanceColumns, key)
      ),
    ];
    for (const datum of trimmedData.reverse()) {
      const row: IPortfolioPerformanceRow = {
        periodEndDate: getDateWithZeroOffset(datum.asOfDate),
        realizedCarriedInterest: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST,
          valueDisplay
        ),
        realizedProfitLoss: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.REALIZED_GAIN_LOSS,
          valueDisplay
        ),
        returnOfCapital: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.RETURN_OF_CAPITAL,
          valueDisplay
        ),
        unrealizedCarriedInterest: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST,
          valueDisplay
        ),
        unrealizedGainLoss: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.UNREALIZED_GAIN_LOSS,
          valueDisplay
        ),
        remainingCapitalInvested: GetPropertyValue(
          datum,
          InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
          valueDisplay
        ),
      };
      worksheet.addRow(row);
    }
    addConditionalHistoricalSummaryFootnotes(
      internalInvestmentData.asOfDates.earliestAsOfDate,
      periodType,
      worksheet
    );
    formatHeaderRows(worksheet);
  }
  return workbook;
};
