import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import {
  BankAccountsResponseDto,
  getBankAccountCountries,
  getBankAccountCurrencies,
  getBankAccountsForClient,
  getBankAccountsForIV,
  ICountryResponseDto,
  ICurrencyResponseDto,
} from "../../services/bankAccountsService";
import { Entity } from "../../types/dataTypes";
import { LoadError, Maybe } from "../../types/typeUtils";
import { convertBankAccountsResponseDto } from "../../utils/converters";
import {
  errBankAccountCountries,
  errBankAccountCurrencies,
  errBankAccounts,
  recvBankAccountCountries,
  recvBankAccountCurrencies,
  recvBankAccounts,
  recvNoBankAccountCountries,
  recvNoBankAccountCurrencies,
  recvNoBankAccounts,
  ReqBankAccountBody,
} from "../reducers/bankAccountsReducer";

export function* fetchBankAccountsForClient(action: PayloadAction<Entity>) {
  try {
    const bankAccountsResponse: Maybe<BankAccountsResponseDto> = yield call(
      getBankAccountsForClient,
      parseInt(action.payload.clientId)
    );

    if (bankAccountsResponse === undefined) {
      put(recvNoBankAccounts());
    } else {
      yield put(
        recvBankAccounts(convertBankAccountsResponseDto(bankAccountsResponse))
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccounts());
    }
  }
}

export function* fetchBankAccountsForIV(
  action: PayloadAction<ReqBankAccountBody>
) {
  try {
    const bankAccountsResponse: Maybe<BankAccountsResponseDto> = yield call(
      getBankAccountsForIV,
      action.payload.id,
      action.payload.isAdmin
    );

    if (bankAccountsResponse === undefined) {
      put(recvNoBankAccounts());
    } else {
      yield put(
        recvBankAccounts(convertBankAccountsResponseDto(bankAccountsResponse))
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccounts());
    }
  }
}

export function* fetchBankAccountsCountries() {
  try {
    const bankAccountsCountriesResponse: Maybe<ICountryResponseDto[]> =
      yield call(getBankAccountCountries);

    if (bankAccountsCountriesResponse === undefined) {
      put(recvNoBankAccountCountries());
    } else {
      yield put(recvBankAccountCountries(bankAccountsCountriesResponse));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccountCountries());
    }
  }
}

export function* fetchBankAccountsCurrencies() {
  try {
    const bankAccountsCurrenciesResponse: Maybe<ICurrencyResponseDto[]> =
      yield call(getBankAccountCurrencies);

    if (bankAccountsCurrenciesResponse === undefined) {
      put(recvNoBankAccountCurrencies());
    } else {
      yield put(recvBankAccountCurrencies(bankAccountsCurrenciesResponse));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errBankAccountCurrencies());
    }
  }
}
