import { createAction } from "@reduxjs/toolkit";

import { DataLoadStatus, StockSymbol } from "../../constants/enums";
import {
  IAdminClient,
  IDistributionsDataBySymbol,
  IEmployee,
  IEquityDataBySymbol,
  IStockDataBySymbol,
} from "../../types/dataTypes";
import { Optional } from "../../types/typeUtils";

export const setActiveEquityDataId = createAction<string>(
  "SET_ACTIVE_EQUITY_DATA_ID"
);
export const reqEquityData = createAction<string>("REQ_EQUITY_DATA");
export const reqClientEquityData = createAction<string>(
  "REQ_CLIENT_EQUITY_DATA"
);
export const recvEquityData =
  createAction<IEquityDataBySymbol>("RECV_EQUITY_DATA");
export const noEquityData = createAction("NO_EQUITY_DATA");
export const errEquityData = createAction<DataLoadStatus>("ERR_EQUITY_DATA");

export const recvSupportedStocks = createAction<Optional<StockSymbol[]>>(
  "RECV_SUPPORTED_STOCKS"
);
export const reqSupportedStocks = createAction<string>("REQ_SUPPORTED_STOCKS");

export const reqStockData = createAction("REQ_STOCK_DATA");
export const recvStockData =
  createAction<IStockDataBySymbol>("RECV_STOCK_DATA");
export const noStockData = createAction("NO_STOCK_DATA");
export const errStockData = createAction<DataLoadStatus>("ERR_STOCK_DATA");

export const reqDistributionsData = createAction<string>(
  "REQ_DISTRIBUTIONS_DATA"
);
export const reqClientDistributionsData = createAction<string>(
  "REQ_CLIENT_DISTRIBUTIONS_DATA"
);
export const recvDistributionsData = createAction<IDistributionsDataBySymbol>(
  "RECV_DISTRIBUTIONS_DATA"
);
export const noDistributionsData = createAction("NO_DISTRIBUTIONS_DATA");
export const errDistributionsData = createAction<DataLoadStatus>(
  "ERR_DISTRIBUTIONS_DATA"
);

export const setSelectedStockSymbol = createAction<StockSymbol>(
  "SET_SELECTED_STOCK_SYMBOL"
);

export const resetSelectedEmployee = createAction<void>(
  "RESET_SELECTED_EMPLOYEE"
);

export const reqSelectedEmployee = createAction<IEmployee>(
  "REQ_SELECTED_EMPLOYEE"
);

export const reqEquityEmployees = createAction<void>("REQ_EQUITY_EMPLOYEES");

export const recvEquityEmployees = createAction<Optional<IEmployee[]>>(
  "RECV_EQUITY_EMPLOYEES"
);

export const errEquityEmployees = createAction<DataLoadStatus>(
  "ERR_EQUITY_EMPLOYEES"
);

export const reqEquityClients = createAction<void>("REQ_EQUITY_CLIENTS");

export const recvEquityClients = createAction<Optional<IAdminClient[]>>(
  "RECV_EQUITY_CLIENTS"
);

export const errEquityClients =
  createAction<DataLoadStatus>("ERR_EQUITY_CLIENTS");
