import { IClientDto, IInvestmentVehicle } from "common";

import { IInvestmentEntityDropdownItem } from "../types/investmentEntityDropdownTypes";

/**
 * Builds an investment vehicle id used for the UI so that,
 * the client id and investment vehicle id can be easily accessed
 * @param clientId A client id
 * @param investmentVehicleId An investment vehicle id
 * @returns A string id
 */
export const builInvestmentVehicleIdForUI = (
  clientId: string,
  investmentVehicleId: string
) => `${clientId}:${investmentVehicleId}`;

/**
 * Determines whether two strings are equivalent or not so that, investment entity dropdown item objects
 * can be sorted ascending
 * @param a An investment entity dropdown item object
 * @param b An investment entity dropdown item object
 * @returns A number
 */
export const sortByDisplayNameAsc = (
  a: IInvestmentEntityDropdownItem,
  b: IInvestmentEntityDropdownItem
): number => a.displayName.localeCompare(b.displayName);

/**
 * Determines whether two strings are equivalent or not so that, client name
 * can be sorted ascending
 * @param a A client object
 * @param b A client object
 * @returns A number
 */
export const sortByNameAsc = (a: IClientDto, b: IClientDto): number =>
  a.name.localeCompare(b.name);

/**
 * Builds a list of investment entity dropdown item objects with the metadata needed to select
 * a client or investment vehicle on the investment entity select component.
 * Client level id format as clientId
 * Investment Vehicle level id format as clientId:investmentVehicleId
 * Entities should be in alphabetical order
 * Wrap display name after 50 characters
 * @param clients A list of clients
 * @returns A  list of investment entity dropdown item objects
 */
export const getEntityDropdownItems = (
  clients: IClientDto[],
  clientsOnly: boolean | null = false
): IInvestmentEntityDropdownItem[] => {
  let entities: IInvestmentEntityDropdownItem[] = [];
  const sortedClients = clients.sort(sortByNameAsc);
  sortedClients.forEach((client) => {
    entities = entities.concat({
      id: client.id,
      activeDisplayName: client.name,
      displayName: client.name,
      isClient: true,
    } as IInvestmentEntityDropdownItem);

    // if the page has a clients-only entity selector, don't show list of IVs!
    // we only want clients
    if (!clientsOnly && client.investmentVehicles.length > 1) {
      const nestedEntities = client.investmentVehicles.map(
        (iv: IInvestmentVehicle) => {
          const clientIVName = `${client.name} / ${iv.name}`;
          return {
            id: builInvestmentVehicleIdForUI(client.id, iv.id),
            activeDisplayName: clientIVName,
            displayName: iv.name,
            isClient: false,
          } as IInvestmentEntityDropdownItem;
        }
      );
      const sortedNestedEntities = nestedEntities.sort(sortByDisplayNameAsc);
      entities = entities.concat(sortedNestedEntities);
    }
  });

  return entities;
};
