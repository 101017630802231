import { ApiPath } from "../constants/ApiPathConstants";
import {
  IInteralInvestmentClient,
  IInvestmentDataDto,
} from "../types/dataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { handleResponse } from "./utils";

/**
 * Builds an url string to fetch investment data of a client or investment vehicle
 * @param clientId - The client Id to get the entity info for.
 * @param investmentVehicleId - The InvestmentVehicle Id to get the entity info for.
 * @param periodId - The periodid or period Hash
 * @returns An url string.
 */
const buildInvestmentDataUrl = (
  clientId: string,
  investmentVehicleId: string | undefined,
  periodId: string
): string => {
  const baseUrl = ApiPath.INTERNAL_INVESTMENTS_URL_BASE;
  if (investmentVehicleId === undefined) {
    return `${baseUrl}/clients/${clientId}/data-sets/periods/${periodId}`;
  }

  return `${baseUrl}/clients/${clientId}/investment-vehicles/${investmentVehicleId}/data-sets/periods/${periodId}`;
};

export type IInvestmentSourceData = Json<IInvestmentDataDto>;

/**
 * Gets a full set of Investment Data for a single client/investment vehicle entity
 * @param clientId - The client Id to get the entity info for.
 * @param investmentVehicleId - The InvestmentVehicle Id to get the entity info for.
 * @param periodId - The periodid or period Hash
 * @returns An Investment Data Dto object that has a full set of Investment Data for a single client.
 */
export const getInvestmentDataforEntityByPeriod = async (
  clientId: string,
  investmentVehicleId: string | undefined,
  periodId: string
): Promise<Maybe<IInvestmentSourceData>> => {
  const user = getUser();
  const url = buildInvestmentDataUrl(clientId, investmentVehicleId, periodId);
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export type IInteralInvestmentClientsSource = Json<IInteralInvestmentClient>[];

export const getInternalInvestmentClients = async (): Promise<
  Maybe<IInteralInvestmentClientsSource>
> => {
  const user = getUser();
  const url = `${ApiPath.INTERNAL_INVESTMENTS_URL_BASE}/clients`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return await handleResponse(response);
};
