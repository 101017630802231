import { ApiPath } from "../constants/ApiPathConstants";
import {
  IElectionRoundSummariesResponseSource,
  IElectionSourceDataForClient,
} from "../redux/sagas/electionSagas";
import {
  ClientId,
  ElectionRoundId,
  IElectionClientMapping,
  IElectionInvestmentPortfolio,
  IElectionIVConfiguration,
  IElectionPermissions,
  IElectionRound,
  IElectionRoundConfiguration,
  IElectionWorkflowState,
  IUpdateWorkflowState,
  WorkflowTypeEntity,
} from "../types/electionDataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { deleteSucceeded, handleBlobResponse, handleResponse } from "./utils";

export type IElectionPermissionsSource = Json<IElectionPermissions>;
export type IElectionRoundConfigurationSource =
  Json<IElectionRoundConfiguration>;
export type IElectionIVConfigurationSource = Json<IElectionIVConfiguration>;
export type IInvestmentPortfolioSource = Json<IElectionInvestmentPortfolio>;
export type IElectionWorkflowStateSource = Json<IElectionWorkflowState>;
export type IUpdateWorkflowStateSource = Json<IUpdateWorkflowState>;
export type IElectionRoundSource = Json<IElectionRound>;

export const getElectionRoundConfigurationData = async (
  electionRoundId: string
): Promise<Maybe<IElectionRoundConfigurationSource>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/configurations/election-rounds/${electionRoundId}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const getElectionIVConfiguration = async (
  electionRoundId: string,
  investmentVehicleId: number
): Promise<Maybe<IElectionIVConfigurationSource>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/configurations/election-rounds/${electionRoundId}/investment-vehicles/${investmentVehicleId}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const getInvestmentPortfolio = async (
  clientId: ClientId
): Promise<Maybe<IInvestmentPortfolioSource>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/investment-portfolios/clients/${clientId}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const getElectionsForClient = async (
  clientId: ClientId
): Promise<Maybe<IElectionSourceDataForClient[]>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/summaries/clients/${clientId}?excludeUpcomingRounds=true`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const getElectionsForElectionRound = async (
  electionRoundId: ElectionRoundId
): Promise<Maybe<IElectionRoundSummariesResponseSource>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/summaries/v2/election-rounds/${electionRoundId}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const getElectionWorkflowState = async (
  electionRoundId: string,
  investmentVehicleId: number,
  workflowType: WorkflowTypeEntity
): Promise<Maybe<IElectionWorkflowStateSource>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/workflow-states/election-rounds/${electionRoundId}/investment-vehicles/${investmentVehicleId}?workflowType=${workflowType}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const putElectionWorkflowState = async (
  electionRoundId: string,
  investmentVehicleId: number,
  workflowType: WorkflowTypeEntity,
  targetState: IUpdateWorkflowStateSource
): Promise<Maybe<IElectionWorkflowStateSource>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/workflow-states/election-rounds/${electionRoundId}/investment-vehicles/${investmentVehicleId}?workflowType=${workflowType}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "PUT",
    body: JSON.stringify(targetState),
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const getElectionPermissions = async (): Promise<
  Maybe<IElectionPermissionsSource>
> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/permissions/me`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const getMultipleDocuments = async (
  electionRoundId: string,
  queryParams: string
): Promise<Maybe<Blob>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/documents/${electionRoundId}?${queryParams}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleBlobResponse(response);
};

export const getClientMappings = async (): Promise<
  Maybe<IElectionClientMapping[]>
> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/entities/clients`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const reopenWorkflowState = async (
  electionRoundId: string,
  investmentVehicleId: number
): Promise<Maybe<IElectionWorkflowStateSource>> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/workflow-states/election-rounds/${electionRoundId}/investment-vehicles/${investmentVehicleId}/reopening`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const removeElectionParticipant = async (
  electionRoundId: string,
  investmentVehicleId: number
): Promise<boolean> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/configurations/election-rounds/${electionRoundId}/investment-vehicles/${investmentVehicleId}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
    method: "DELETE",
  };

  const response = await fetch(url, request);
  return await deleteSucceeded(response.status);
};

export const getAllElectionRounds = async (): Promise<
  Maybe<IElectionRoundSource[]>
> => {
  const user = getUser();
  const url = `${ApiPath.ELECTIONS_URL_BASE}/configurations/election-rounds`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};
