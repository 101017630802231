import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";

import {
  Box,
  Card,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import {
  ColDef,
  ICellRendererParams,
  IsFullWidthRowParams,
  ITooltipParams,
  RowClassParams,
} from "ag-grid-community";
import {
  AgGridReact,
  CustomCellRendererProps,
  CustomTooltipProps,
} from "ag-grid-react";
import {
  AccountAssignmentRow,
  BankAccountAssignment,
  BankAccountConstants,
  BankAccountTooltips,
  Banner,
  BannerType,
  BreakpointConstants,
  changeContributionAccount,
  changeDistributionAccount,
  colors,
  Extractable,
  extractable,
  formatBankAccountForDropdown,
  GridHeaderWithTooltip,
  hasPendingContribution,
  hasPendingDistribution,
  IBankAccount,
  IBaseStore,
  isInProgress,
  isSomething,
  LoadingIndicator,
  nothing,
  Optional,
  optionalDateUTCDashForNullFormatter,
  optionalStringDashForNullFormatter,
  SelectedBankAccount,
  selectElegibleContributionAccounts,
  selectElegibleDistributionAccounts,
  some,
  useGridExtensions,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddOrEditBankAccountDialog } from "../BankAccountDialog/AddOrEditBankAccountDialog";
import { ReviewAndSignDialog } from "../ReviewAndSignDialog/ReviewAndSignDialog";
import styles from "./AccountAssignmentGrid.module.scss";
import { AddAccountCell } from "./Renderers/AddAccountCell";
import { AddAccountHeader } from "./Renderers/AddAccountHeader";

const DEFAULT_ROW_HEIGHT = 42;
const MOBILE_TOP_PINNED_ROW_HEIGHT = 60;
export interface IAddAccounCellProps
  extends ICellRendererParams<AccountAssignmentRow> {
  openBankAccountDialog: (assignmentType: BankAccountAssignment) => void;
}

export interface IAccountAssignmentGridProps {
  purposesWithError: BankAccountAssignment[];
  retryFunction: () => void;
  investmentVehicleIds: number[];
  renderAddBankAccountCell?: boolean;
  hasUSBankAccount?: Optional<boolean>;
}

export const AccountAssignmentGrid = (props: IAccountAssignmentGridProps) => {
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );
  const {
    purposesWithError,
    investmentVehicleIds,
    renderAddBankAccountCell = false,
    hasUSBankAccount,
  } = props;
  const { onGridReady, resizeColumns } = useGridExtensions();
  const dispatch = useDispatch();

  const { selectedBankAccounts, unsubmittedChanges, bankAccountsLoadStatus } =
    useSelector((store: IBaseStore) => store.bankAccounts);

  const eligibleContributionAccounts = useSelector(
    selectElegibleContributionAccounts
  );
  const eligibleDistributionAccounts = useSelector(
    selectElegibleDistributionAccounts
  );

  const hasPendingContributionAccount: {
    [investmentVehicleId: number]: boolean;
  } = useSelector(hasPendingContribution);
  const hasPendingDistributionAccount: {
    [investmentVehicleId: number]: boolean;
  } = useSelector(hasPendingDistribution);

  const hasUnsubmittedChanges = useMemo(() => {
    const ivUnsubmittedChanges = investmentVehicleIds.map(
      (investmentVehicleId: number) => {
        return Object.values(
          unsubmittedChanges[investmentVehicleId] ?? {}
        ).some(Boolean);
      }
    );
    return ivUnsubmittedChanges.some(
      (hasUnsubmittedChange: boolean) => hasUnsubmittedChange
    );
  }, [investmentVehicleIds, unsubmittedChanges]);

  const hasChangedInUseContributtionAccount = useMemo(
    () =>
      investmentVehicleIds.some(
        (investmentVehicleId) =>
          unsubmittedChanges[investmentVehicleId][
            BankAccountAssignment.CONTRIBUTION
          ] &&
          !isSomething(
            selectedBankAccounts[investmentVehicleId].contributionBankAccount
          )
      ),
    [investmentVehicleIds, selectedBankAccounts, unsubmittedChanges]
  );

  const [isAddBankAccountDialogOpen, setIsAddBankAccountDialogOpen] =
    useState<boolean>(false);
  const [isReviewAndSignDialogOpen, setIsReviewAndSignDialogOpen] =
    useState<boolean>(false);

  const [assignmentType, setAssignmentType] = useState<BankAccountAssignment>(
    BankAccountAssignment._
  );

  const openBankAccountDialog = useCallback(
    (assignmentType: BankAccountAssignment) => {
      setAssignmentType(assignmentType);
      setIsAddBankAccountDialogOpen(true);
    },
    [setAssignmentType, setIsAddBankAccountDialogOpen]
  );

  const topPinnedRowData: AccountAssignmentRow[] = useMemo(
    () => [
      {
        purpose: BankAccountAssignment._,
        bankName: some(""),
        bankAccount: some(""),
        eligibleAccounts: [],
        lastSubmitted: some(new Date()),
        disabled: true,
        unsubmitted: false,
        requestId: nothing,
        fullWidth: true,
      },
    ],
    []
  );

  const investmentVehicleCellRenderer = (
    props: CustomCellRendererProps<AccountAssignmentRow, BankAccountAssignment>
  ) => {
    const { investmentVehicle } = props.data ?? {};
    return investmentVehicle?.name;
  };

  const purposeCellRenderer = (
    props: CustomCellRendererProps<AccountAssignmentRow, BankAccountAssignment>
  ) => {
    const { purpose } = props.data ?? {};
    return purpose;
  };

  const bankAccountCellRenderer = useCallback(
    (
      props: CustomCellRendererProps<AccountAssignmentRow, Optional<string>> & {
        purposesWithError: BankAccountAssignment[];
      }
    ) => {
      const { purpose, hasUSBankAccount, investmentVehicle } = props.data ?? {};
      const isContribution = purpose === BankAccountAssignment.CONTRIBUTION;

      const selectedAccount = props.valueFormatted ?? "";

      const options = (props.data?.eligibleAccounts ?? [])
        .map(({ main: account, lastUpdated }) => ({
          value: account.bankAccountUniqueId,
          text: formatBankAccountForDropdown(account),
          disabled: false,
          lastUpdated:
            lastUpdated instanceof Date ? lastUpdated.getTime() : -Infinity,
        }))
        .sort((a, b) => {
          // Selected bank account should be first, so check if either value matches the selected bank account
          const aIsTarget =
            a.value.localeCompare(selectedAccount, undefined, {
              sensitivity: "base",
            }) === 0;
          const bIsTarget =
            b.value.localeCompare(selectedAccount, undefined, {
              sensitivity: "base",
            }) === 0;

          // Prioritize objects that match the target string
          if (aIsTarget && !bIsTarget) return -1;
          if (bIsTarget && !aIsTarget) return 1;

          // If neither or both match, sort bank accounts alphabetically
          const textComparison = a.text.localeCompare(b.text, undefined, {
            sensitivity: "base",
          });

          // If the text properties are the same, sort bank accounts by date (earliest to latest)
          if (textComparison === 0) {
            return a.lastUpdated - b.lastUpdated;
          }

          return textComparison; // Return the result of the text comparison
        });

      if (
        options.length === 0 &&
        isContribution &&
        hasUSBankAccount !== undefined
      ) {
        options.push({
          value: BankAccountConstants.NO_US_ACCOUNT,
          text: BankAccountConstants.NO_US_ACCOUNT,
          disabled: false,
          lastUpdated: -Infinity,
        });
      }

      options.push({
        value: BankAccountConstants.NO_ACCOUNT_ASSIGNED,
        text: BankAccountConstants.NO_ACCOUNT_ASSIGNED,
        disabled: true,
        lastUpdated: -Infinity,
      });

      const dispatcherFn = isContribution
        ? changeContributionAccount
        : changeDistributionAccount;

      const bankAccountUniqueId = () => {
        if (
          hasUSBankAccount &&
          isSomething(hasUSBankAccount) &&
          hasUSBankAccount.value === false
        ) {
          return BankAccountConstants.NO_US_ACCOUNT;
        }

        if (props.value && isSomething(props.value)) {
          return props.value.value;
        }
        return BankAccountConstants.NO_ACCOUNT_ASSIGNED;
      };

      const hasError =
        (purpose && props.purposesWithError.includes(purpose)) ||
        (isContribution &&
          investmentVehicle &&
          unsubmittedChanges[investmentVehicle.axiomId][
            BankAccountAssignment.CONTRIBUTION
          ] &&
          bankAccountUniqueId() === BankAccountConstants.NO_ACCOUNT_ASSIGNED);

      return (
        <Select
          color="secondary"
          error={hasError}
          value={bankAccountUniqueId()}
          fullWidth
          disabled={props.data?.disabled}
          onChange={(e: SelectChangeEvent) => {
            if (investmentVehicle) {
              dispatch(
                dispatcherFn({
                  bankAccountUniqueId: e.target.value,
                  eligibleFor: undefined,
                  investmentVehicleId: investmentVehicle?.axiomId,
                  isEditMode: false,
                })
              );
            }
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.text}
            </MenuItem>
          ))}
        </Select>
      );
    },
    [dispatch, unsubmittedChanges]
  );

  const constructRowData = useCallback(
    (ivSelectedBankAccount?: SelectedBankAccount): AccountAssignmentRow[] => {
      if (!ivSelectedBankAccount) {
        return [];
      }

      let extractableContribution: Extractable<IBankAccount> =
        extractable(nothing);
      let extractableDistribution: Extractable<IBankAccount> =
        extractable(nothing);
      if (isSomething(ivSelectedBankAccount.contributionBankAccount)) {
        extractableContribution = extractable(
          some(ivSelectedBankAccount.contributionBankAccount.value.main)
        );
      }
      if (isSomething(ivSelectedBankAccount.distributionBankAccount)) {
        extractableDistribution = extractable(
          some(ivSelectedBankAccount.distributionBankAccount.value.main)
        );
      }

      const lastSubmittedContributionDate: Optional<Optional<Date>> =
        extractable(ivSelectedBankAccount.contributionBankAccount).extractByKey(
          "lastUpdated"
        );
      const lastSubmittedDistributionDate: Optional<Optional<Date>> =
        extractable(ivSelectedBankAccount.distributionBankAccount).extractByKey(
          "lastUpdated"
        );

      return [
        {
          investmentVehicle: ivSelectedBankAccount.investmentVehicle,
          purpose: BankAccountAssignment.CONTRIBUTION,
          bankName: extractableContribution.extractByKey("bankName"),
          bankAccount: extractableContribution.extractByKey(
            "bankAccountUniqueId"
          ),
          eligibleAccounts:
            eligibleContributionAccounts[
              ivSelectedBankAccount.investmentVehicle?.axiomId
            ],
          lastSubmitted: isSomething(lastSubmittedContributionDate)
            ? lastSubmittedContributionDate.value
            : nothing,
          disabled:
            hasPendingContributionAccount[
              ivSelectedBankAccount.investmentVehicle?.axiomId
            ],
          unsubmitted:
            unsubmittedChanges[
              ivSelectedBankAccount.investmentVehicle?.axiomId
            ][BankAccountAssignment.CONTRIBUTION],
          requestId: isSomething(ivSelectedBankAccount.contributionBankAccount)
            ? ivSelectedBankAccount.contributionBankAccount.value.requestId
            : nothing,
          hasUSBankAccount,
        },
        {
          investmentVehicle: ivSelectedBankAccount.investmentVehicle,
          purpose: BankAccountAssignment.DISTRIBUTION,
          bankName: extractableDistribution.extractByKey("bankName"),
          bankAccount: extractableDistribution.extractByKey(
            "bankAccountUniqueId"
          ),
          eligibleAccounts:
            eligibleDistributionAccounts[
              ivSelectedBankAccount.investmentVehicle?.axiomId
            ],
          lastSubmitted: isSomething(lastSubmittedDistributionDate)
            ? lastSubmittedDistributionDate.value
            : nothing,
          disabled:
            hasPendingDistributionAccount[
              ivSelectedBankAccount.investmentVehicle?.axiomId
            ],
          unsubmitted:
            unsubmittedChanges[
              ivSelectedBankAccount.investmentVehicle?.axiomId
            ][BankAccountAssignment.DISTRIBUTION],
          requestId: isSomething(ivSelectedBankAccount.distributionBankAccount)
            ? ivSelectedBankAccount.distributionBankAccount.value.requestId
            : nothing,
        },
      ];
    },
    [
      eligibleContributionAccounts,
      eligibleDistributionAccounts,
      hasPendingContributionAccount,
      hasPendingDistributionAccount,
      hasUSBankAccount,
      unsubmittedChanges,
    ]
  );

  const rowData: AccountAssignmentRow[] = useMemo(() => {
    const data: AccountAssignmentRow[] = [];
    investmentVehicleIds.forEach((investmentVehicleId: number) => {
      data.push(...constructRowData(selectedBankAccounts[investmentVehicleId]));
    });
    return data.sort((a, b) =>
      (a.investmentVehicle?.name ?? "").localeCompare(
        b.investmentVehicle?.name ?? "",
        undefined,
        { sensitivity: "base" }
      )
    );
  }, [constructRowData, investmentVehicleIds, selectedBankAccounts]);

  const hideCaseIdColumn = useMemo(() => {
    return !rowData.some((row: AccountAssignmentRow) =>
      isSomething(row.requestId)
    );
  }, [rowData]);

  const columnDefs: ColDef<AccountAssignmentRow>[] = useMemo(() => {
    const hideInvestmentVehicleColumn = investmentVehicleIds.length === 1;
    return [
      {
        field: "investmentVehicle",
        minWidth: 200,
        maxWidth: 200,
        tooltipField: "investmentVehicle.name",
        hide: hideInvestmentVehicleColumn,
        cellRenderer: investmentVehicleCellRenderer,
        cellStyle: (params) => {
          if (params.data?.unsubmitted) {
            return { borderLeft: `1px solid ${colors.warning_yellow}` };
          }
        },
      },
      {
        field: "purpose",
        minWidth: 120,
        maxWidth: 120,
        resizable: false,
        cellRenderer: purposeCellRenderer,
        cellStyle: (params) => {
          if (params.data?.unsubmitted && hideInvestmentVehicleColumn) {
            return { borderLeft: `1px solid ${colors.warning_yellow}` };
          }
        },
      },
      {
        field: "bankAccount",
        resizable: false,
        minWidth: 220,
        valueFormatter: optionalStringDashForNullFormatter,
        cellRenderer: bankAccountCellRenderer,
        cellRendererParams: {
          purposesWithError,
        },
      },
      {
        field: "lastSubmitted",
        minWidth: 180,
        maxWidth: 180,
        valueFormatter: optionalDateUTCDashForNullFormatter,
        resizable: false,
      },
      {
        field: "requestId",
        hide: hideCaseIdColumn,
        headerName: BankAccountConstants.TREASURY_REQUEST_ID,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          tooltipContent: BankAccountTooltips.TREASURY_REQUEST_ID,
        },
        minWidth: 180,
        maxWidth: 180,
        valueFormatter: optionalStringDashForNullFormatter,
        resizable: false,
      },
      {
        colId: "submitChanges",
        minWidth: 156,
        headerComponent: AddAccountHeader,
        headerComponentParams: {
          openReviewAndSignDialog: () => setIsReviewAndSignDialogOpen(true),
          disabled:
            !hasUnsubmittedChanges || hasChangedInUseContributtionAccount,
        },
        cellRenderer: renderAddBankAccountCell ? AddAccountCell : () => <div />,
        cellRendererParams: {
          openBankAccountDialog: openBankAccountDialog,
        } as IAddAccounCellProps,
        cellStyle: (params) => {
          if (params.data?.unsubmitted) {
            return { borderRight: `1px solid ${colors.warning_yellow}` };
          }
        },
      },
    ];
  }, [
    investmentVehicleIds,
    bankAccountCellRenderer,
    purposesWithError,
    hasUnsubmittedChanges,
    hasChangedInUseContributtionAccount,
    renderAddBankAccountCell,
    openBankAccountDialog,
    hideCaseIdColumn,
  ]);

  const isFullWidthRow = useCallback(
    (params: IsFullWidthRowParams<AccountAssignmentRow, any>) => {
      return !!params.rowNode.data?.fullWidth;
    },
    []
  );

  const fullWidthCellRenderer = () => (
    <Banner bannerType={BannerType.WARNING}>
      {BankAccountConstants.SUBMIT_CHANGES_PROMPT}
    </Banner>
  );

  return (
    <Box className={`ag-theme-alpine`} id={styles.accountGrid} width="100%">
      <AgGridReact<AccountAssignmentRow>
        loading={isInProgress(bankAccountsLoadStatus)}
        isFullWidthRow={isFullWidthRow}
        fullWidthCellRenderer={fullWidthCellRenderer}
        getRowHeight={(params) =>
          params.data?.fullWidth && isMobile
            ? MOBILE_TOP_PINNED_ROW_HEIGHT
            : DEFAULT_ROW_HEIGHT
        }
        suppressContextMenu={true}
        tooltipMouseTrack={true}
        tooltipShowDelay={150}
        tooltipInteraction={true}
        defaultColDef={{
          tooltipValueGetter: (
            params: ITooltipParams<AccountAssignmentRow>
          ) => {
            const { data: { purpose, disabled } = {} } = params;
            if (purpose === BankAccountAssignment._) return;

            return disabled
              ? BankAccountTooltips.YOUR_ACCOUNT_IS_UNDER_REVIEW
              : "";
          },
          tooltipComponent: (props: CustomTooltipProps) => {
            return <Card className={styles.rowTooltip}>{props.value}</Card>;
          },
          resizable: false,
          sortable: false,
          suppressHeaderMenuButton: true,
          suppressMovable: true,
        }}
        pinnedTopRowData={hasUnsubmittedChanges ? topPinnedRowData : []}
        columnDefs={columnDefs}
        rowData={rowData}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        suppressRowDrag
        suppressCellFocus
        gridOptions={{
          getRowClass: (params: RowClassParams<AccountAssignmentRow>) => {
            if (params.node.isRowPinned()) {
              return styles.CustomRow;
            }
            if (params.data?.unsubmitted) {
              return styles.UnsubmittedChanges;
            }
          },
        }}
        loadingOverlayComponent={LoadingIndicator}
      />
      <AddOrEditBankAccountDialog
        open={isAddBankAccountDialogOpen}
        setOpen={setIsAddBankAccountDialogOpen}
        showAutoAssignSection={false}
        assignmentType={assignmentType}
        investmentVehicleIds={investmentVehicleIds}
      />
      {isReviewAndSignDialogOpen ? (
        <ReviewAndSignDialog
          setOpen={setIsReviewAndSignDialogOpen}
          retryFunction={props.retryFunction}
          hasUnsubmittedChanges={hasUnsubmittedChanges}
        />
      ) : null}
    </Box>
  );
};
