import { ArrowForwardIos } from "@mui/icons-material";
import { Breadcrumbs, Typography } from "@mui/material";
import {
  reqAllElectionRounds,
  SectionHeader,
  useFetchDatasetWithoutId,
} from "common";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RelativePath } from "../../../../constants/Paths";
import { selectCurrentElectionRoundName } from "../../../../redux/selectors/electionsSelector";
import { AdminUIStore } from "../../../../redux/store";
import ElectorViewGrid from "../ElectorViewGrid/ElectorViewGrid";
import styles from "./ElectorViewPage.module.scss";

export const ElectorViewPage = () => {
  const navigate = useNavigate();

  const handleElectionRoundsClick = () => {
    navigate(RelativePath.ELECTION_ADMIN);
  };

  const { allElectionRoundsLoadStatus } = useSelector(
    (store: AdminUIStore) => store.elections
  );

  useFetchDatasetWithoutId(reqAllElectionRounds, allElectionRoundsLoadStatus);

  const electionRoundName = useSelector(selectCurrentElectionRoundName);

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <Breadcrumbs
          className={styles.breadcrumbs}
          separator={<ArrowForwardIos className={styles.arrow} />}
        >
          <span onClick={handleElectionRoundsClick}>
            <Typography variant="subtitle1" className={styles.electionRounds}>
              {"Election Rounds"}
            </Typography>
          </span>
          <Typography variant="subtitle1" className={styles.roundName}>
            {electionRoundName}
          </Typography>
        </Breadcrumbs>
        <Typography variant="h1">{SectionHeader.ELECTOR_VIEW}</Typography>
      </div>
      <div className={styles.pageContent}>
        <ElectorViewGrid />
      </div>
    </div>
  );
};
