import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { FileDownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  DataLoadStatus,
  DocumentsLabel,
  DownloadRequestStatus,
  ErrorStringConstants,
  IInternalInvestmentClientDataDownload,
  isEntityLoaded,
  isSomething,
  openAlert,
  reqAllClientData,
  selectFilteredBy,
  setDownloadReqStatus,
  StringConstants,
} from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectActiveClientMetadata } from "../../../redux/selectors/clientsSelector";
import { AdminUIStore } from "../../../redux/store";
import styles from "./AdminDownloadButton.module.scss";
import { useAdminDownload } from "./adminDownloadExcel";

export const AdminDownloadButton = () => {
  const { internalInvestmentDataLoadStatus } = useSelector(
    (store: AdminUIStore) => store.internalInvestmentData
  );

  const filteredBy = useSelector(selectFilteredBy);
  const selectedClient = useSelector(selectActiveClientMetadata);

  const { entitiesData, entitiesDataLoadStatus, downloadRequestStatus } =
    useSelector((store: AdminUIStore) => store.viewData);

  const dispatch = useDispatch();
  const handleDownload = useAdminDownload();

  // effect tracks changes to data load status
  useEffect(() => {
    // triggers download if data load is successful
    if (
      entitiesDataLoadStatus === DataLoadStatus.SUCCESSFUL &&
      downloadRequestStatus === DownloadRequestStatus.REQUESTED &&
      isSomething(selectedClient)
    ) {
      handleDownload(
        selectedClient.value,
        entitiesData,
        () =>
          dispatch(
            openAlert({
              severity: "success",
              message: StringConstants.DOWNLOAD_COMPLETE,
            })
          ),
        () =>
          dispatch(
            openAlert({
              severity: "error",
              message: ErrorStringConstants.DOWNLOAD_FAILED,
            })
          )
      );

      dispatch(setDownloadReqStatus(DownloadRequestStatus.NOT_REQUESTED));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDownload, entitiesDataLoadStatus, downloadRequestStatus]);

  const handleClick = async () => {
    // check filtered by and if client has been selected
    if (isSomething(filteredBy) && isSomething(selectedClient)) {
      // set progress toast
      dispatch(
        openAlert({
          severity: "info",
          message: DocumentsLabel.PREPARING_DOCUMENTS,
        })
      );
      // set download requested value to REQUESTED
      dispatch(setDownloadReqStatus(DownloadRequestStatus.REQUESTED));

      // check if all data is loaded
      const isClientLoaded = isEntityLoaded(
        entitiesData,
        selectedClient.value.id
      )
        ? true
        : false;

      const unloadedIVs = selectedClient.value.investmentVehicles.filter(
        (iv: { id: string }) => !isEntityLoaded(entitiesData, iv.id)
      );
      // list of entity Ids for data load
      const entityIds: string[] = unloadedIVs.map(
        (iv: { id: string }) => iv.id
      );

      // load all relevent client & iv data
      if (!isClientLoaded || entityIds.length > 0) {
        // if data load is necessary set values needed for data load
        const selectedClientData: IInternalInvestmentClientDataDownload = {
          entityIds: entityIds,
          periodId: filteredBy.value.periodId,
          clientId: selectedClient.value.id,
          isClientLoaded: isClientLoaded,
        };

        dispatch(reqAllClientData(selectedClientData));
      }
    }
  };

  return (
    <>
      <Button
        className={styles.adminDownload}
        onClick={handleClick}
        variant="outlined"
        endIcon={<FileDownloadOutlined />}
        disabled={
          internalInvestmentDataLoadStatus !== DataLoadStatus.SUCCESSFUL
        }
      >
        {StringConstants.ADMIN_DOWNLOAD}
      </Button>
    </>
  );
};
