import {
  CarryDataKey,
  InvestmentBreakdownKey,
  InvestmentValueDisplay,
  PeriodType,
} from "../constants/enums";
import {
  IHistoricalSummaryDatum,
  IInvestment,
  IInvestmentBreakdownDatum,
  IInvestmentBreakdownValueSet,
  IRealizedProceeds,
  IUnrealizedValue,
} from "../types/dataTypes";
import { InvestmentHistoryValueItem } from "../types/investmentHistoryTypes";
import { isSomething } from "../types/typeGuards";
import { nothing, Optional, some } from "../types/typeUtils";
import { convertToQuarterDateObject } from "./converters";
import { getFormattedQuarterDate } from "./formatters";
import { InvestmentBreakDownValueSelectors } from "./investmentBreakdownUtils";

/*
 ** Tells if the key is a realized property
 */
export const isRealizedProperty = (key: InvestmentBreakdownKey) => {
  const realizedProperties = [
    InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST,
    InvestmentBreakdownKey.REALIZED_GAIN_LOSS,
    InvestmentBreakdownKey.RETURN_OF_CAPITAL,
    InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS,
    InvestmentBreakdownKey.CAPITAL_INVESTED,
    InvestmentBreakdownKey.NET_CASH_FLOW,
  ];

  return realizedProperties.some((realizedProp) => realizedProp == key);
};
/*
 ** Returns true if the property is defined otherwise, false
 */
const HasPropertyName = (
  key: InvestmentBreakdownKey,
  valueDisplay: InvestmentValueDisplay
) => {
  return (data: IHistoricalSummaryDatum) => {
    switch (valueDisplay) {
      case InvestmentValueDisplay.CUMULATIVE: {
        return InvestmentBreakDownValueSelectors[key](data.cumulative);
      }
      case InvestmentValueDisplay.NONCUMULATIVE: {
        return InvestmentBreakDownValueSelectors[key](data.nonCumulative);
      }
      case InvestmentValueDisplay.CUMULATIVE_UNREALIZED_NONCUMULATIVE_REALIZED: {
        return isRealizedProperty(key)
          ? InvestmentBreakDownValueSelectors[key](data.nonCumulative)
          : InvestmentBreakDownValueSelectors[key](data.cumulative);
      }
    }
  };
};

/*
 ** Gets summary data by value display enum
 */
const summaryDataByValueDisplay = (valueDisplay: InvestmentValueDisplay) => {
  return (data: IHistoricalSummaryDatum): IInvestmentBreakdownValueSet => {
    switch (valueDisplay) {
      case InvestmentValueDisplay.CUMULATIVE: {
        return {
          ...data.cumulative,
        };
      }
      case InvestmentValueDisplay.NONCUMULATIVE: {
        return {
          ...data.nonCumulative,
        };
      }
      case InvestmentValueDisplay.CUMULATIVE_UNREALIZED_NONCUMULATIVE_REALIZED: {
        return {
          unrealizedValue: { ...data.cumulative.unrealizedValue },
          realizedProceeds: { ...data.nonCumulative.realizedProceeds },
          investment: { ...data.nonCumulative.investment },
        };
      }
      default: {
        return {
          ...data.nonCumulative,
        };
      }
    }
  };
};

export const getMaxDate = (data: IHistoricalSummaryDatum[]) => {
  return data[data.length - 1]?.displayQuarterAsOfDate ?? new Date();
};

export const getMinDate = (data: IHistoricalSummaryDatum[]) => {
  let minDate = data[0]?.displayQuarterAsOfDate ?? new Date();
  // we never display data from earlier than 2013, even if present
  if (minDate.getUTCFullYear() < 2013) {
    minDate = convertToQuarterDateObject("2013-01-01");
  }
  return minDate;
};

const calculateNonCumulativeInvestment = (
  currentCumulativeInvestment: Optional<IInvestment>,
  previousCumulativeInvestment: Optional<IInvestment>
): Optional<IInvestment> => {
  if (!isSomething(currentCumulativeInvestment)) return nothing;
  if (!isSomething(previousCumulativeInvestment))
    return currentCumulativeInvestment;
  return some({
    asOfDate: currentCumulativeInvestment.value.asOfDate,
    capitalInvested:
      currentCumulativeInvestment.value.capitalInvested -
      previousCumulativeInvestment.value.capitalInvested,
    netCashFlow:
      currentCumulativeInvestment.value.netCashFlow -
      previousCumulativeInvestment.value.netCashFlow,
  });
};

const calculateNonCumulativeRealized = (
  currentCumulativeRealized: Optional<IRealizedProceeds>,
  previousCumulativeRealized: Optional<IRealizedProceeds>
): Optional<IRealizedProceeds> => {
  if (!isSomething(currentCumulativeRealized)) return nothing;
  if (!isSomething(previousCumulativeRealized))
    return currentCumulativeRealized;
  return some<IRealizedProceeds>({
    asOfDate: currentCumulativeRealized.value.asOfDate,
    sbsAndMandatoryInvestments:
      currentCumulativeRealized.value.sbsAndMandatoryInvestments -
      previousCumulativeRealized.value.sbsAndMandatoryInvestments,
    carriedInterest:
      currentCumulativeRealized.value.carriedInterest -
      previousCumulativeRealized.value.carriedInterest,
    gainLoss:
      currentCumulativeRealized.value.gainLoss -
      previousCumulativeRealized.value.gainLoss,
    returnOfCapital:
      currentCumulativeRealized.value.returnOfCapital -
      previousCumulativeRealized.value.returnOfCapital,
    total:
      currentCumulativeRealized.value.total -
      previousCumulativeRealized.value.total,
  });
};

const calculateNonCumulativeUnrealized = (
  currentCumulativeUnrealized: Optional<IUnrealizedValue>,
  previousCumulativeUnrealized: Optional<IUnrealizedValue>
): Optional<IUnrealizedValue> => {
  if (!isSomething(currentCumulativeUnrealized)) return nothing;
  if (!isSomething(previousCumulativeUnrealized))
    return currentCumulativeUnrealized;
  return some<IUnrealizedValue>({
    asOfDate: currentCumulativeUnrealized.value.asOfDate,
    sbsAndMandatoryInvestments:
      currentCumulativeUnrealized.value.sbsAndMandatoryInvestments -
      previousCumulativeUnrealized.value.sbsAndMandatoryInvestments,
    carriedInterest:
      currentCumulativeUnrealized.value.carriedInterest -
      previousCumulativeUnrealized.value.carriedInterest,
    gainLoss:
      currentCumulativeUnrealized.value.gainLoss -
      previousCumulativeUnrealized.value.gainLoss,
    remainingCapitalInvested:
      currentCumulativeUnrealized.value.remainingCapitalInvested -
      previousCumulativeUnrealized.value.remainingCapitalInvested,
    total:
      currentCumulativeUnrealized.value.total -
      previousCumulativeUnrealized.value.total,
  });
};

export const calculateNonCumulativeValues = (
  currentCumulative: IInvestmentBreakdownDatum,
  previousCumulative: IInvestmentBreakdownDatum
): IInvestmentBreakdownValueSet => {
  if (previousCumulative === undefined) {
    return {
      investment: currentCumulative.investment,
      realizedProceeds: currentCumulative.realizedProceeds,
      unrealizedValue: currentCumulative.unrealizedValue,
    };
  }

  const nonCumulativeInvestment = calculateNonCumulativeInvestment(
    currentCumulative.investment,
    previousCumulative.investment
  );
  const nonCumulativeRealized = calculateNonCumulativeRealized(
    currentCumulative.realizedProceeds,
    previousCumulative.realizedProceeds
  );
  const nonCumulativeUnrealized = calculateNonCumulativeUnrealized(
    currentCumulative.unrealizedValue,
    previousCumulative.unrealizedValue
  );

  return {
    investment: nonCumulativeInvestment,
    realizedProceeds: nonCumulativeRealized,
    unrealizedValue: nonCumulativeUnrealized,
  };
};

export const trimData = (
  data: IHistoricalSummaryDatum[],
  dataTypes: InvestmentBreakdownKey[],
  valueDisplay: InvestmentValueDisplay
) => {
  const mappedData = data.map(summaryDataByValueDisplay(valueDisplay));
  const firstIndexWithValue = mappedData.findIndex((datum) =>
    dataTypes.some(
      (item) => InvestmentBreakDownValueSelectors[item](datum) !== 0
    )
  );
  return firstIndexWithValue == -1 ? [] : data.slice(firstIndexWithValue);
};

/*
gets 3 trailing years worth of data (i.e. last 12 quarters)
*/
export const getTrailingThreeYearsData = (
  data: IHistoricalSummaryDatum[],
  dataTypes: InvestmentBreakdownKey[]
) => {
  const trailingThreeYearsData = data.length > 12 ? data.slice(-12) : data;

  const firstIndexWithValue = trailingThreeYearsData
    .map((datum) => datum.nonCumulative)
    .findIndex((datum) =>
      dataTypes.some(
        (item) => InvestmentBreakDownValueSelectors[item](datum) !== 0
      )
    );

  return trailingThreeYearsData.slice(firstIndexWithValue);
};

export const getPeriodLabel = (date: Date, periodType: PeriodType) => {
  return periodType === PeriodType.QUARTERLY
    ? getFormattedQuarterDate(date)
    : String(date.getUTCFullYear());
};

export const getKeysWithValues = (
  possibleKeys: InvestmentBreakdownKey[],
  data: IHistoricalSummaryDatum[],
  valueDisplay: InvestmentValueDisplay
) => {
  return possibleKeys.filter((key) => {
    return data.some(HasPropertyName(key, valueDisplay));
  });
};

export const getFieldName = (
  dataType: InvestmentBreakdownKey,
  isCumulative: boolean
) => {
  return isCumulative ? `cumulative.${dataType}` : `nonCumulative.${dataType}`;
};

export const CARRY_DATA_KEYS: CarryDataKey[] = [
  CarryDataKey.REALIZED_CARRY,
  CarryDataKey.UNREALIZED_CARRY,
];

export const PORTFOLIO_PERFORMANCE_KEYS: InvestmentBreakdownKey[] = [
  InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
  InvestmentBreakdownKey.UNREALIZED_GAIN_LOSS,
  InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST,
  InvestmentBreakdownKey.RETURN_OF_CAPITAL,
  InvestmentBreakdownKey.REALIZED_GAIN_LOSS,
  InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST,
];

export const PORTFOLIO_BALANCE_KEYS: InvestmentBreakdownKey[] = [
  InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST,
  InvestmentBreakdownKey.SBS_AND_MANDATORY_INVESTMENTS_UNREALIZED,
  InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
];

export const CASH_FLOW_KEYS: InvestmentBreakdownKey[] = [
  InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS,
  InvestmentBreakdownKey.CAPITAL_INVESTED,
  InvestmentBreakdownKey.NET_CASH_FLOW,
];

export const initializeChartDataAndToolbar = (
  data: IHistoricalSummaryDatum[],
  dataTypes: InvestmentHistoryValueItem[],
  setDataTypes: React.Dispatch<
    React.SetStateAction<InvestmentHistoryValueItem[]>
  >,
  setGraphInitialized: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const presentDataTypes = dataTypes.filter((dataType) => {
    return data.some((datum) =>
      InvestmentBreakDownValueSelectors[dataType.key](datum.cumulative)
    );
  });

  setDataTypes(presentDataTypes);
  setGraphInitialized(true);
};
