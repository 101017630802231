import {
  DataLoadStatus,
  LoadingIndicator,
  NoDataAvailableError,
  reqAllEntitlements,
} from "common";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../../redux/store";
import { AdminUIEntitlementStore } from "../../../types/storeTypes";

export interface IHasEntitlementContainerProperties {
  children: ReactNode;
  entitlementName: keyof AdminUIEntitlementStore;
  entitlementLoadStatusName: keyof AdminUIEntitlementStore;
  noDataChildren?: ReactNode;
}

export interface IHasAnyEntitlementsContainerProperties {
  children: ReactNode;
  entitlementsToCheck: Array<
    [
      name: keyof AdminUIEntitlementStore,
      loadStatus: keyof AdminUIEntitlementStore
    ]
  >;
  noDataChildren?: ReactNode;
}

export const HasEntitlementContainer = ({
  children,
  entitlementName,
  entitlementLoadStatusName,
  noDataChildren,
}: IHasEntitlementContainerProperties) => {
  const dispatch = useDispatch();

  const entitlements = useSelector((store: AdminUIStore) => store.entitlements);

  const entitlementToCheck = entitlements[entitlementName] as boolean;
  const entitlementLoadStatus = entitlements[
    entitlementLoadStatusName
  ] as DataLoadStatus;

  useEffect(() => {
    async function checkData() {
      if (entitlementLoadStatus === DataLoadStatus.NOT_REQUESTED) {
        dispatch(reqAllEntitlements());
      }
    }
    checkData();
  }, [entitlementLoadStatus, dispatch]);

  const nonSuccessComponent =
    entitlementLoadStatus === DataLoadStatus.UNSUCCESSFUL ||
    entitlementLoadStatus === DataLoadStatus.EMPTY_RESPONSE ||
    !entitlementToCheck ? (
      <NoDataAvailableError />
    ) : (
      <LoadingIndicator />
    );

  return entitlementToCheck &&
    entitlementLoadStatus === DataLoadStatus.SUCCESSFUL
    ? children
    : noDataChildren ?? nonSuccessComponent;
};

// checks if a user has any of the provided entitlements to check
// i.e. given [A, B] user only needs to be entitled to A or B for this to return the children nodes
export const HasAnyEntitlementsContainer = ({
  children,
  entitlementsToCheck,
  noDataChildren,
}: IHasAnyEntitlementsContainerProperties) => {
  const dispatch = useDispatch();

  const entitlements = useSelector((store: AdminUIStore) => store.entitlements);

  // Check if all entitlements have been requested loaded
  const areAnyEntitlementsNotRequested = entitlementsToCheck.some(
    (tuple) => entitlements[tuple[1]] === DataLoadStatus.NOT_REQUESTED
  );

  useEffect(() => {
    if (areAnyEntitlementsNotRequested) {
      dispatch(reqAllEntitlements());
    }
  }, [areAnyEntitlementsNotRequested, dispatch]);

  // check if no entitlement is granted
  const noDataAvailable = entitlementsToCheck.every(([name, status]) => {
    const entitlementToCheck = entitlements[name] as boolean;
    const loadStatus = entitlements[status] as DataLoadStatus;
    return (
      !entitlementToCheck ||
      loadStatus === DataLoadStatus.UNSUCCESSFUL ||
      loadStatus === DataLoadStatus.EMPTY_RESPONSE
    );
  });

  // check if any entitlement is good to go
  const entitledToSeeData = entitlementsToCheck.some(([name, status]) => {
    const entitlementToCheck = entitlements[name] as boolean;
    const loadStatus = entitlements[status] as DataLoadStatus;

    return entitlementToCheck && loadStatus === DataLoadStatus.SUCCESSFUL;
  });

  const nonSuccessComponent = noDataAvailable ? (
    <NoDataAvailableError />
  ) : (
    <LoadingIndicator />
  );

  return entitledToSeeData ? children : noDataChildren ?? nonSuccessComponent;
};
