export const BANK_ACCOUNT_PERMISSIONS = {
  READ: {
    BANK_ACCOUNTS: "read-bank-accounts",
  },
  WRITE: {
    BANK_ACCOUNTS: "write-bank-accounts",
  },
  VIEW: {
    SBS_BANK_ACCOUNTS: "sbs-view-bank-accounts",
  },
};
