import { ClientLevelPageName, PageName } from "../constants/Pages/Pages";

export const isPageVisible = (
  pageName: PageName,
  isInternalInvestmentsAdmin: boolean,
  isEquityAdmin: boolean,
  isElectionsAdmin: boolean
) => {
  switch (pageName) {
    case PageName.ELECTION:
    case PageName.ELECTION_ADMIN_PAGE:
    case PageName.ELECTOR_VIEW_PAGE:
    case PageName.ELECTION_EDITOR_PAGE:
    case PageName.ELECTION_REVIEW:
      return isElectionsAdmin;
    case PageName.CLIENT_DATA:
      return isInternalInvestmentsAdmin || isEquityAdmin;
    default:
      return true;
  }
};

export const isClientPageVisible = (
  pageName: ClientLevelPageName,
  canViewDashboardPage: boolean,
  canViewInvestmentsAndCarryPage: boolean,
  canViewCommitmentsPage: boolean,
  canViewEquityPage: boolean
) => {
  switch (pageName) {
    case ClientLevelPageName.DASHBOARD:
      return canViewDashboardPage || canViewEquityPage;
    case ClientLevelPageName.INVESTMENTS_AND_CARRY:
      return canViewInvestmentsAndCarryPage;
    case ClientLevelPageName.COMMITMENTS:
      return canViewCommitmentsPage;
    case ClientLevelPageName.EQUITY:
      return canViewEquityPage;
    default:
      return true;
  }
};
