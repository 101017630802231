import { createAction } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import {
  ClientId,
  ElectionRoundId,
  ElectionWorkflowStageId,
  IAccordionUpdate,
  IBankAccountStage,
  IElectionDecision,
  IElectionInvestmentPortfolio,
  IElectionIVConfiguration,
  IElectionRound,
  IElectionRoundConfiguration,
  IElectionRoundSummariesResponse,
  IElectionsFilter,
  IElectionsForClient,
  IElectionWorkflowState,
  IElectStageWarning,
  IEligibleStatus,
  IGetElectionDetailsRequestPayload,
  IPermittedClient,
  IResidentialInformation,
  IWriteElectionWorkflowStatePayload,
} from "../../types/electionDataTypes";
import { PageSize } from "../../types/pageSizeTypes";
import { Optional } from "../../types/typeUtils";

export const reqElectionRoundConfiguration = createAction<ElectionRoundId>(
  "REQ_ELECTION_CONFIG"
);

export const recvElectionRoundConfiguration = createAction<
  Optional<IElectionRoundConfiguration>
>("RECV_ELECTION_CONFIG");

export const errElectionRoundConfiguration = createAction<DataLoadStatus>(
  "ERR_ELECTION_CONFIG"
);

export const reqElectionWorkflowState =
  createAction<IGetElectionDetailsRequestPayload>(
    "REQ_ELECTION_WORKFLOW_STATE"
  );

export const recvLocalElectionWorkflowState = createAction<
  Optional<IElectionWorkflowState>
>("RECV_ELECTION_WORKFLOW_STATE_LOCAL");

export const recvApiElectionWorkflowState = createAction<
  Optional<IElectionWorkflowState>
>("RECV_ELECTION_WORKFLOW_STATE_API");

export const errElectionWorkflowState = createAction<DataLoadStatus>(
  "ERR_ELECTION_WORKFLOW_STATE"
);

export const recvElectionWorkflowStateUpdate = createAction<
  Optional<IElectionWorkflowState>
>("RECV_ELECTIONS_WORKFLOW_UPDATE");

export const errElectionWorkflowStateUpdate = createAction<DataLoadStatus>(
  "ERR_ELECTIONS_WORKFLOW_UPDATE"
);

export const reqPutElectionWorkflowState =
  createAction<IWriteElectionWorkflowStatePayload>(
    "PUT_ELECTION_WORKFLOW_STATE"
  );

export const reqResetElectionWorkflowState =
  createAction<IWriteElectionWorkflowStatePayload>(
    "RESET_ELECTION_WORKFLOW_STATE"
  );

export const reqElectionsForClient = createAction<IPermittedClient>(
  "REQ_ELECTIONS_FOR_CLIENT"
);

export const recvElectionsForClient = createAction<
  Optional<IElectionsForClient[]>
>("RECV_ELECTIONS_FOR_CLIENT");

export const errElectionsForClient = createAction<DataLoadStatus>(
  "ERR_ELECTIONS_FOR_CLIENT"
);

export const reqElectionsForElectionRound = createAction<ElectionRoundId>(
  "REQ_ELECTIONS_FOR_ELECTION_ROUND"
);

export const recvElectionsForElectionRound = createAction<
  Optional<IElectionRoundSummariesResponse>
>("RECV_ELECTIONS_FOR_ELECTION_ROUND");

export const errElectionsForElectionRound = createAction<DataLoadStatus>(
  "ERR_ELECTIONS_FOR_ELECTION_ROUND"
);

export const reqElectionIVConfiguration =
  createAction<IGetElectionDetailsRequestPayload>(
    "REQ_ELECTION_IV_CONFIGURATION"
  );

export const recvElectionIVConfiguration = createAction<
  Optional<IElectionIVConfiguration>
>("RECV_ELECTION_IV_CONFIGURATION");

export const errElectionIVConfiguration = createAction<DataLoadStatus>(
  "ERR_ELECTION_IV_CONFIGURATION"
);

export const reqElectionsInvestmentPortfolio = createAction<ClientId>(
  "REQ_INVESTMENT_PORTFOLIO"
);

export const recvElectionsInvestmentPortfolio = createAction<
  Optional<IElectionInvestmentPortfolio>
>("RECV_INVESTMENT_PORTFOLIO");

export const errElectionsInvestmentPortfolio = createAction<DataLoadStatus>(
  "ERR_INVESTMENT_PORTFOLIO"
);

export const updateOptionalElectionByStrategy = createAction<IElectionDecision>(
  "UPDATE_OPT_ELECTION_BY_STRATEGY"
);

export const updateAllElectionDecisions = createAction<IElectionDecision[]>(
  "UPDATE_ALL_ELECTION_DECISIONS"
);

export const updateResidentialInformation =
  createAction<IResidentialInformation>("UPDATE_RESIDENTIAL_INFORMATION;");

export const updateReallocationState = createAction<Optional<boolean>>(
  "UPDATE_REALLOCATION_STATE"
);

export const updateStage =
  createAction<ElectionWorkflowStageId>("UPDATE_STAGE");

export const recvPermittedElectionClients = createAction<
  Optional<IPermittedClient[]>
>("RECV_PERMITTED_ELECTION_CLIENTS");

export const errPermittedElectionClients = createAction<DataLoadStatus>(
  "ERR_PERMITTED_ELECTION_CLIENTS"
);

export const setActiveElectionClient = createAction<Optional<IPermittedClient>>(
  "SET_ACTIVE_ELECTION_CLIENT"
);

export const setHasAcceptedLegalAttestation = createAction<boolean>(
  "SET_HAS_ACCEPTED_LEGAL_ATTESTATION"
);

export const updateEligibilityStatus = createAction<IEligibleStatus>(
  "UPDATE_ELIGIBILITY_STATUS;"
);

export const setAccordionStatus = createAction<IAccordionUpdate>(
  "SET_ACCORDION_STATUS"
);

export const requestStageValidation = createAction("REQUEST_STAGE_VALIDATION");

export const resetStageValidation = createAction("RESET_STAGE_VALIDATION");

export const setActiveElection =
  createAction<IGetElectionDetailsRequestPayload>(
    "SET_ELECTION_DETAILS_REQUEST_PAYLOAD"
  );

export const setIsSBSElectionSaveEnabled = createAction<boolean>(
  "SET_IS_SBS_ELECTION_SAVE_ENABLED"
);

export const setReviewAndSignSignature = createAction<Optional<string>>(
  "SET_REVIEW_AND_SIGN_SIGNATURE"
);

export const setUnacknowledgedElectStageWarnings = createAction<
  Optional<IElectStageWarning>
>("SET_OPTIONAL_ELECT_STAGE_WARNING");

export const reqClearActiveElection = createAction("REQ_CLEAR_ACTIVE_ELECTION");

export const setSubmissionRequested = createAction<boolean>(
  "SET_SUBMISSION_REQUESTED"
);

export const updateWorkflowStageOrder = createAction<ElectionWorkflowStageId[]>(
  "UPDATE_WORKFLOW_STAGE_ORDER"
);

export const resetWorkflowStageOrder = createAction(
  "RESET_WORKFLOW_STAGE_ORDER"
);

export const updateBankAccountStage = createAction<IBankAccountStage>(
  "UPDATE_BANK_ACCOUNT_INFORMATION"
);

export const updateElectorsGridSearchTerm = createAction<Optional<string>>(
  "UPDATE_ELECTORS_GRID_SEARCH_TERM"
);

export const updateElectorsGridPage = createAction<number>(
  "UPDATE_ELECTORS_GRID_PAGE"
);

export const updateElectorsGridPageSize = createAction<PageSize>(
  "UPDATE_ELECTORS_GRID_PAGE_SIZE"
);

export const setElectionsGridFilters = createAction<IElectionsFilter>(
  "SET_ELECTIONS_GRID_FILTERS"
);

export const clearElectionGridFilters = createAction(
  "CLEAR_ELECTIONS_GRID_FILTERS"
);

export const reqAllElectionRounds = createAction("REQ_ALL_ELECTION_ROUNDS");

export const recvAllElecitonRounds = createAction<IElectionRound[]>(
  "RECV_ALL_ELECION_ROUNDS"
);

export const errAllElectionRounds = createAction<DataLoadStatus>(
  "ERR_ALL_ELECTION_ROUNDS"
);
