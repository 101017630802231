export const ApiPath = {
  EQUITY_URL_BASE: "/api/equity-data",
  EQUITY_V2_URL_BASE: "/api/client-equity-data",
  DOCUMENTS_URL_BASE: "/api/documents",
  ELECTIONS_URL_BASE: "/api/sbs-elections",
  USER_AGREEMENTS_URL_BASE: "/api/user-data",
  BANK_ACCOUNTS_URL_BASE: "/api/bank-accounts",
  INTERNAL_INVESTMENTS_URL_BASE: "/api/internal-investments",
  AUTHORIZATION_URL_BASE: "/api/authorization",
};
