import { Dashboard, EnvironmentResolver } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { AdminUIStore } from "../../redux/store";

const DashboardWrapper = () => {
  const { hasInternalInvestmentAdminEntitlement, hasEquityAdminEntitlement } =
    useSelector((store: AdminUIStore) => store.entitlements);

  return (
    <Dashboard
      hasViewInternalInvestments={hasInternalInvestmentAdminEntitlement}
      hasViewStockEntitlement={false}
      hasViewEquity={hasEquityAdminEntitlement}
      hasViewRestrictedEquity={
        EnvironmentResolver.ENV.RESTRICTED_EQUITY_ADMIN_FEATURE_FLAG
      }
      hasViewV2DashboardEquityCardEntitlement={true}
    />
  );
};

export default DashboardWrapper;
