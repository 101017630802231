import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { StockSymbol } from "common";
import React from "react";

import styles from "./StockSymbolToggle.module.scss";

interface StockSymbolToggleProps {
  availableStockSymbols: StockSymbol[];
  selectedStockSymbol: StockSymbol;
  onChange: (newSymbol: StockSymbol) => void;
}

export const StockSymbolToggle = (props: StockSymbolToggleProps) => {
  const { availableStockSymbols, onChange, selectedStockSymbol } = props;

  return (
    <ToggleButtonGroup
      value={selectedStockSymbol}
      onChange={(_, newSymbol) => {
        if (newSymbol !== null) onChange(newSymbol);
      }}
      exclusive
      className={styles.symbolToggle}
    >
      {availableStockSymbols.map((type) => (
        <ToggleButton
          key={type}
          value={type}
          className={styles.symbolToggleButton}
        >
          {type}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
