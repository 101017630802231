import {
  combineReducers,
  configureStore,
  createSerializableStateInvariantMiddleware,
  isPlain,
  Tuple,
} from "@reduxjs/toolkit";
import {
  authReducer,
  bankAccountsReducer,
  electionsReducer as commonElectionsReducer,
  equityDataReducer,
  fetchAdminEntitlements,
  fetchAllClientData,
  fetchAllElectionRounds,
  fetchBankAccountsCountries,
  fetchBankAccountsCurrencies,
  fetchBankAccountsForIV,
  fetchClientDistributionsData,
  fetchClientEquityData,
  fetchClientMappings,
  fetchDistributionsData,
  fetchElectionConfiguration,
  fetchElectionIVConfiguration,
  fetchElectionsForElectionRound,
  fetchElectionWorkflowState,
  fetchEquityClients,
  fetchEquityData,
  fetchEquityEmployees,
  fetchInternalInvestmentClients,
  fetchInternalInvestmentData,
  fetchInvestmentPortfolio,
  fetchStockData,
  internalInvestmentDataReducer,
  reqAllClientData,
  reqAllElectionRounds,
  reqAllEntitlements,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  reqBankAccounts,
  reqClientDistributionsData,
  reqClientEquityData,
  reqDistributionsData,
  reqElectionClientMappings,
  reqElectionIVConfiguration,
  reqElectionRoundConfiguration,
  reqElectionsForElectionRound,
  reqElectionsInvestmentPortfolio,
  reqElectionWorkflowState,
  reqEquityClients,
  reqEquityData,
  reqEquityEmployees,
  reqInternalInvestmentClients,
  reqInternalInvestmentData,
  reqPutElectionWorkflowState,
  reqResetElectionWorkflowState,
  reqStockData,
  resetElectionWorkflowState,
  writeElectionWorkflowState,
} from "common";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { takeLeading } from "redux-saga/effects";

import { electionsReducer as adminElectionsReducer } from "./reducers/electionsReducer";
import {
  adminUIClientsReducer,
  adminUIEmployeesReducer,
  adminUIEntitlementReducer,
  adminUISelectedEmployeeReducer,
  adminUIViewDataReducer,
} from "./reducers/reducers";

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield takeLeading(reqAllEntitlements, fetchAdminEntitlements);
  yield takeLeading(
    reqInternalInvestmentClients,
    fetchInternalInvestmentClients
  );
  yield takeLeading(reqEquityEmployees, fetchEquityEmployees);
  yield takeLeading(reqEquityClients, fetchEquityClients);
  yield takeLeading(reqInternalInvestmentData, fetchInternalInvestmentData);
  yield takeLeading(reqAllClientData, fetchAllClientData);
  yield takeLeading(reqEquityData, fetchEquityData);
  yield takeLeading(reqClientEquityData, fetchClientEquityData);
  yield takeLeading(reqDistributionsData, fetchDistributionsData);
  yield takeLeading(reqClientDistributionsData, fetchClientDistributionsData);
  yield takeLeading(reqStockData, fetchStockData);
  yield takeLeading(reqElectionRoundConfiguration, fetchElectionConfiguration);
  yield takeLeading(reqElectionIVConfiguration, fetchElectionIVConfiguration);
  yield takeLeading(reqElectionWorkflowState, fetchElectionWorkflowState);
  yield takeLeading(reqElectionsInvestmentPortfolio, fetchInvestmentPortfolio);
  yield takeLeading(reqPutElectionWorkflowState, writeElectionWorkflowState);
  yield takeLeading(reqResetElectionWorkflowState, resetElectionWorkflowState);
  yield takeLeading(reqBankAccounts, fetchBankAccountsForIV);
  yield takeLeading(reqBankAccountCountries, fetchBankAccountsCountries);
  yield takeLeading(reqBankAccountCurrencies, fetchBankAccountsCurrencies);
  yield takeLeading(reqElectionClientMappings, fetchClientMappings);
  yield takeLeading(
    reqElectionsForElectionRound,
    fetchElectionsForElectionRound
  );
  yield takeLeading(reqAllElectionRounds, fetchAllElectionRounds);
}

const rootReducer = combineReducers({
  entitlements: adminUIEntitlementReducer,
  viewData: adminUIViewDataReducer,
  clients: adminUIClientsReducer,
  employees: adminUIEmployeesReducer,
  internalInvestmentData: internalInvestmentDataReducer,
  equityData: equityDataReducer,
  bankAccounts: bankAccountsReducer,
  selectedEmployee: adminUISelectedEmployeeReducer,
  elections: adminElectionsReducer,
  selectedElection: commonElectionsReducer,
  auth: authReducer,
});

export type AdminUIStore = ReturnType<typeof rootReducer>;

const getCustomMiddleware = () => {
  // Allow dates even though they are not JSON serializable.
  // NOTE: This means we have to be vigilant! Don't let someone
  // secretly mutate the store with "date.setDate(5)" or similar!
  const customSerializableMiddleware =
    createSerializableStateInvariantMiddleware({
      isSerializable: (value: unknown) =>
        isPlain(value) || value instanceof Date,
    });

  if (process.env.REACT_APP_SHOW_CONSOLE_LOGGING === "true") {
    return new Tuple(sagaMiddleware, customSerializableMiddleware, logger);
  }

  return new Tuple(sagaMiddleware, customSerializableMiddleware);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      getCustomMiddleware()
    ),
});

sagaMiddleware.run(rootSaga);
