import { Text, View } from "@react-pdf/renderer";
import {
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardGridRow,
  zeroIfNothing,
} from "common";
import React, { useMemo } from "react";

import { sharedStyles } from "../../SharedStyleSheet";
import { styles } from "./ElectionAmountTableRowTotalStyleSheet";

interface IElectionAmountTableTotalProps {
  item: IElectCardGridRow;
  hasForecastedInvestment: boolean;
  hasAnticipatedPriorPeriodsCapitalCalls: boolean;
  hasMandatoryCommitment: boolean;
}

export const ElectionAmountTableRowTotal = (
  props: IElectionAmountTableTotalProps
) => {
  const {
    item,
    hasForecastedInvestment,
    hasAnticipatedPriorPeriodsCapitalCalls,
    hasMandatoryCommitment,
  } = props;

  const strategyStyle = useMemo(() => {
    if (!hasAnticipatedPriorPeriodsCapitalCalls && !hasMandatoryCommitment) {
      return styles.strategyNoMandatoryOrRemainingCommitment;
    }
    if (!hasAnticipatedPriorPeriodsCapitalCalls) {
      return styles.strategyNoRemainingCommitment;
    }
    if (!hasMandatoryCommitment) {
      return styles.strategyNoMandatoryCommitment;
    }
    return styles.strategy;
  }, [hasAnticipatedPriorPeriodsCapitalCalls, hasMandatoryCommitment]);

  return (
    <View style={[styles.row, styles.boldFont]}>
      <Text style={[sharedStyles.textColumn, strategyStyle]}>{item.name}</Text>
      {hasAnticipatedPriorPeriodsCapitalCalls && (
        <>
          <Text style={[sharedStyles.textColumn, styles.remainingCommitment]}>
            {getCurrencyFormattedValueWithZeroDefault(item.remainingCommitment)}
          </Text>
          <Text style={styles.signColumn}></Text>
        </>
      )}
      {hasMandatoryCommitment && (
        <>
          <Text style={[sharedStyles.textColumn, styles.mandatoryCommitment]}>
            {getCurrencyFormattedValueWithZeroDefault(item.mandatoryCommitment)}
          </Text>
          <Text style={styles.signColumn}></Text>
        </>
      )}
      <Text style={[sharedStyles.textColumn, styles.election]}>
        {getCurrencyFormattedValueWithZeroDefault(
          zeroIfNothing(item.optionalElection)
        )}
      </Text>
      <Text style={styles.signColumn}></Text>
      <Text style={[sharedStyles.textColumn, styles.totalCommitment]}>
        {getCurrencyFormattedValueWithZeroDefault(item.totalCommitment)}
      </Text>
      {hasForecastedInvestment && (
        <Text
          style={[
            sharedStyles.textColumn,
            styles.forecastedInvestment,
            sharedStyles.grayBackgroundColor,
          ]}
        >
          {getCurrencyFormattedValueWithZeroDefault(
            item.totalForecastInvestment
          )}
        </Text>
      )}
    </View>
  );
};
