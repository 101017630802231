import { BankAccountDocumentError } from "../types/bankAccountDataTypes";

export const BankAccountConstants = {
  ADD_ACCOUNT: "Add Account",
  AUTHORIZATION:
    "I hereby authorize Blackstone, hereinafter called COMPANY, to initiate debit entries to my account indicated below at the depository financial institution named below, hereafter called DEPOSITORY, and to credit the same to such account.",
  NO_ACCOUNT_ASSIGNED: "No Bank Account Assigned",
  NO_US_ACCOUNT: "I do not have a U.S. domiciled account",
  SUBMIT_CHANGES: "Submit Changes",
  UNDER_REVIEW_WARNING_1: "Your bank details are under review by Treasury.",
  UNDER_REVIEW_WARNING_2:
    'To finalize your election, click "Next" to proceed to the next stage.',
  NO_US_ACCOUNT_WARNING:
    "Blackstone requires all participants in the Side-by-Side Program with a US domiciled USD bank account engage in direct debit for contributions. If you do not have a US domiciled bank account for contributions, you must provide one within 60 days. For those without US bank account, you must still provide distribution details which accepts USD.",
  ACCOUNT_HOLDER: "Account Holder",
  ACCOUNT_HOLDER_PLACEHOLDER: "Enter Account Holder",
  ACCOUNT_TYPE: "Account Type",
  ACCOUNT_ID_TYPE: "Account Id Type",
  ACCOUNT_ID_NUMBER: "Account Id #",
  ACCOUNT_ID_NUMBER_PLACEHOLDER: "Enter Account Id",
  CONFIRM_ACCOUNT_ID: "Confirm Account Id #",
  ENTER_NUMERICAL_AMOUNT: "Enter numerical amount",
  ENTER_NUMERIC_VALUE: "Enter numeric value",
  COUNTRY: "Country",
  CURRENCY: "Currency",
  BANK_ID_TYPE: "Bank Id Type",
  BANK_ID_NUMBER: "Bank Id #",
  BANK_ID_NUMBER_PLACEHOLDER: "Enter Bank Id",
  BANK_NAME: "Bank Name",
  ENTER_BANK_NAME: "Enter Bank Name",
  US_COUNTRY_ID_NUMBER: 236,
  USD_CURRENCY_ID_NUMBER: 1,
  ADD_NEW_BANK_ACCOUNT: "Add New Bank Account",
  THIRD_PARTY_FINANCING_WARNING:
    "If you plan on using third-party financing and want to use this loan bank account for contributions and/or distributions, you may not have the details until after the election deadline. Please use an existing account to assign as a contribution and/or distribution account to proceed with your election. Once your loan bank account details are available, you can update this account information on the Bank Accounts page.\n",
  BANK_ACCOUNT_ADDED_BOTH_PURPOSES_MESSAGE:
    "Bank account successfully added and available under both purposes.",
  BANK_ACCOUNT_ADDED_MESSAGE: "Bank Account successfully added.",
  BANK_ACCOUNT_ADDED_MULTIPLE_IVS_MESSAGE:
    "Bank account successfully added and available under all your Investment Vehicles.",
  SUBMIT_CHANGES_PROMPT:
    "You have unsaved changes. To ensure you don’t lose progress, submit changes before continuing on.",
  LINK_ACCOUNT:
    "Link intermediary or correspondent bank account information to this account",
  UNLINK_ACCOUNT:
    "Remove linked intermediary or correspondent bank account information from this account",
  CONFIRM_CLOSE_WARNING:
    "Are you sure you want to cancel? Your bank account details will not be saved.",
  PLEASE_COMPLETE_REQUIRED_FIELD: "Please complete required field.",
  PLEASE_COMPLETE_REQUIRED_FIELDS: "Please complete required field(s).",
  MUST_MATCH_ACCOUNT_ID: "Must match Account Id",
  MUST_BE_NINE_DIGITS: "Must be 9 digits",
  MUST_BE_8_11_ALPHANUMERIC: "Must be between 8-11 alphanumeric characters",
  MUST_BE_SIX_DIGITS: "Must be 6 digits",
  MUST_BE_LESS_THAN_150_CHAR: "Value must be less than 150 characters",
  NON_US_CURRENCY_WARNING_MESSAGE:
    "Warning: You will incur a currency FX Gain/Loss which may not be beneficial to you.",
  NO_UPDATE: "No Update",
  E_SIGNATURE: "E-Signature",
  E_SIGN_DISCLOSURES: "E-Sign Disclosures",
  SIGNING_IN_MY_CAPACITY_AS: "Signing in My Capacity As",
  SIGNING_IN_MY_CAPACITY_AS_PLACEHOLDER: "Self, Trustee, Executor",
  SIGN_HERE: "Sign Here",
  PHONE: "Phone",
  INVESTMENT_VEHICLE: "Investment Vehicle",
  CONTRIBUTION_DEBIT_ACCOUNT: "Contribution Debit Account",
  DISTRIBUTION_CREDIT_ACCOUNT: "Distribution Deposit Account",
  REVIEW_AND_SIGN_BANK_ACCOUNT: "Review & Sign Bank Account Agreement",
  SUBMISSION_FAILED: "Submission Failed",
  SUBMISSION_FAILED_MESSAGE: "Your submission failed due to a technical issue.",
  PLEASE_TRY_AGAIN: "Please try again shortly or contact ",
  FOR_ASSISTANCE: " for assistance.",
  AGREE_AND_SUBMIT_TO_TREASURY: "I Agree, Submit to Treasury",
  REVIEW_AND_SIGN_FIRST_PARAGRAPH:
    "I hereby authorize Blackstone hereinafter called COMPANY, to initiate debit/deposit entries to my account indicated below at the depository financial institution named below, hereafter called DEPOSITORY, and to credit the same to such account.",
  REVIEW_AND_SIGN_SECOND_PARAGRAPH:
    "This authorization is to remain in full force and effect until COMPANY has received written notification of its termination in such time and in such manner so as to afford COMPANY and DEPOSITORY a reasonable opportunity to act on it.",
  TERMS_AND_CONDITIONS_CHECKBOX:
    "Yes, I have read the Terms and Conditions regarding Electronic Disclosures which are shown below and consent to receiving such disclosures from COMPANY electronically. By checking the box, I am also certifying that I have the ability to receive disclosures electronically.",
  TERMS_AND_CONDITIONS: "Terms and Conditions",
  TERMS_AND_CONDITIONS_SIGNATURE_TEXT_LINE:
    "By typing my name below and clicking “I agree”, I am electronically signing this authorization.",
  BANK_ACCOUNT_AGREEMENT: "Bank Account Agreement",
  BANK_ACCOUNT_PROCESSING_REQUEST_LEFT:
    "We are processing your request to update your bank account. Please email ",
  BANK_ACCOUNT_PROCESSING_REQUEST_RIGHT:
    " with any questions. To download your Bank Account Agreement, click the download button.",
  PLEASE_SUBMIT_BEFORE_CONTINUING: "Please submit changes before continuing.",
  PLEASE_SUBMIT_CHANGES_TO_PROCEED: "Please submit changes to proceed.",
  SIGN_AUTHORIZATION_ON_BEHALF_OF:
    "I hereby represent and warrant that I am duly authorized to sign this authorization on behalf of ",
  ACCOUNT_ENDING_IN: "Account Ending In",
  TYPE: "Type",
  PHONE_PLACEHOLDER: "Call-back number to verify request",
  ACCOUNT_ASSIGNED_WARNING:
    "There is an account assigned to this purpose. If you submit, you will overwrite it.",
  EDIT_ACCOUNT: "Edit Account",
  VIEW_ACCOUNT: "View Account",
  CORRESPONDENT: "Correspondent",
  INTERMEDIARY: "Intermediary",
  CONTRIBUTION_ASSIGNED_ACCOUNT_WARNING:
    "Your account will be unassigned as Contribution because: ",
  SUPPORTING_DOCUMENTS: "Supporting Documents",
  PLEASE_ATTACH_SUPPORTING_DOCS:
    "Please attach any additional supporting documentation if applicable.",
  SUPPORTING_MATERIALS: "Supporting Materials",
  TREASURY_REQUEST_ID: "Treasury Request Id",
};

export const BankAccountTooltips = {
  ACCOUNT_HOLDER:
    "For AML compliance purposes, the account must be in the name of the investor. The use of Trust accounts and LLC’s is not permitted.",
  ACCOUNT_TYPE:
    "Checking accounts can be assigned as both contribution and/or distribution accounts. Savings accounts cannot be assigned as contribution accounts.",
  INTERMEDIARY_ACCOUNT_TYPE: [
    "• An Intermediary account acts as a bridge between the originating and beneficiary banks, facilitating the transfer of funds across different countries or currencies.",
    "• A Correspondent account is maintained by a domestic bank to handle foreign transactions on behalf of another bank.",
    "Please contact your bank if you are uncertain if your account should have an intermediary or correspondent.",
  ],
  CONTRIBUTION_UNDER_REVIEW: (caseId: number) =>
    `Contribution account is under review by Treasury (Request Id: ${caseId}).`,
  DISTRIBUTION_UNDER_REVIEW: (caseId: number) =>
    `Distribution account is under review by Treasury (Request Id: ${caseId}).`,
  ACCOUNT_UNDER_REVIEW: (caseId: number) =>
    `Your account is under review by Treasury (Request Id: ${caseId})`,
  YOUR_ACCOUNT_IS_UNDER_REVIEW: "Your account is under review by Treasury.",
  ACCOUNT_MUST_BE_CHECKING: "Account Type must be Checking.",
  MUST_BE_US: "Country must be United States.",
  MUST_BE_USD: "Currency must be USD.",
  LINKED_ACC_CANNOT_BE_CONTRIBUTION:
    "Accounts with linked accounts cannot be used for Contributions.",
  TREASURY_REQUEST_ID:
    "Id of the approval request for the Treasury team. Until approved, all transactions will be made using previous accounts. You will receive an email response when the review process is complete.",
};

export const BankAccountDocumentUploadErrorMessages: {
  [x in BankAccountDocumentError]: string;
} = {
  [BankAccountDocumentError.INVALID_FILE_TYPE]: "File type not supported.",
  [BankAccountDocumentError.MAX_FILE_SIZE]: "File size exceeded 20MB.",
  [BankAccountDocumentError.UNKNOWN]:
    "Document failed upload due to unknown error",
};
