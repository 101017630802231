import { Typography } from "@mui/material";
import { ElectionsLabels } from "common";
import { ElectionDialog } from "common/src/features/Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import React from "react";

interface IElectorGridDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  dialogContent: string;
  handleNext: () => void;
}

export const ElectorGridDialog = ({
  isOpen,
  handleClose,
  title,
  dialogContent,
  handleNext,
}: IElectorGridDialogProps) => {
  return (
    <ElectionDialog
      title={title}
      content={<Typography>{dialogContent}</Typography>}
      handleNext={handleNext}
      open={isOpen}
      handleClose={handleClose}
      nextButtonLabel={ElectionsLabels.YES}
      cancelButtonLabel={ElectionsLabels.NO}
    />
  );
};
