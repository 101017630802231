import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  AdminClientLabels,
  DropdownSearchBar,
  Entity,
  IAdminClient,
  isEntityLoaded,
  isSomething,
  reqClientDistributionsData,
  reqClientEquityData,
  reqInternalInvestmentData,
  selectFilteredBy,
  setActiveEquityDataId,
  setSelectedEntity,
} from "common";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AdminDownloadButton } from "../../common/components/AdminDownload/AdminDownloadButton";
import { HasAnyEntitlementsContainer } from "../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import { RelativePath } from "../../constants/Paths";
import { selectActiveClients } from "../../redux/selectors/clientsSelector";
import { AdminUIStore } from "../../redux/store";
import { AdminUIEntitlementStore } from "../../types/storeTypes";
import {
  findInternalInvestmentClientForClientId,
  getClientOptionLabel,
  searchAndSortClients,
} from "../../utils/adminClientUtils";
import { NavigationTabs } from "./NavigationTabs/NavigationTabs";
import styles from "./SubHeader.module.scss";

export const SubHeader = () => {
  const { periodId, entities } = useSelector(
    (store: AdminUIStore) => store.internalInvestmentData
  );
  const filteredBy = useSelector(selectFilteredBy);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Show both equity and internal investments clients if applicable
  const searchableClients = useSelector(selectActiveClients);

  const { internalInvestmentClients, equityClients } = useSelector(
    (state: AdminUIStore) => state.clients
  );
  const { activeEquityDataId } = useSelector(
    (store: AdminUIStore) => store.equityData
  );

  // Extract the clientId from the pathname
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const clientFromPath = findInternalInvestmentClientForClientId(
    clientId,
    searchableClients
  );

  /*
   * On user searching for a client,
   * Request all data for client and navigate to dashboard.
   */
  const onSearch = (selectedClient: IAdminClient | null) => {
    if (selectedClient) {
      // should is loaded include equity entities as well?
      // should we update the data type to hold equity data?
      const isLoaded = isEntityLoaded(entities, selectedClient.id);
      const client: Entity = {
        clientId: selectedClient.id,
        investmentVehicleId: undefined,
        mdmClientId: selectedClient.mdmOId,
      };

      if (!isLoaded && isSomething(internalInvestmentClients)) {
        dispatch(
          reqInternalInvestmentData({
            ...client,
            periodId: periodId,
          })
        );
      }

      const isEquityLoaded =
        isSomething(activeEquityDataId) &&
        selectedClient.id == activeEquityDataId.value;

      // load all equity data if there are equity clients and the data is not already loaded
      if (isSomething(equityClients) && !isEquityLoaded) {
        dispatch(setActiveEquityDataId(selectedClient.id));
        dispatch(reqClientEquityData(selectedClient.id));
        dispatch(reqClientDistributionsData(selectedClient.id));
      }

      dispatch(setSelectedEntity(client));
      navigate(
        RelativePath.CLIENT_DEFAULT.replace(":clientId", selectedClient.mdmOId)
      );
    }
  };

  // list of tuples of type [entitlementName, entitlementLoadStatusName]
  const entitlementsToCheck: Array<
    [
      name: keyof AdminUIEntitlementStore,
      loadStatus: keyof AdminUIEntitlementStore
    ]
  > = [
    [
      "hasInternalInvestmentAdminEntitlement",
      "internalInvestmentAdminEntitlementLoadStatus",
    ],
    ["hasEquityAdminEntitlement", "equityAdminEntitlementLoadStatus"],
  ];

  return (
    <>
      <div className={styles.subnavbar}>
        <HasAnyEntitlementsContainer
          entitlementsToCheck={entitlementsToCheck}
          noDataChildren={<></>}
        >
          <Box className={styles.toolbar} alignItems="center">
            <Typography variant="h5" className={styles.searchText}>
              {AdminClientLabels.SEARCH_FOR}
            </Typography>
            <DropdownSearchBar<IAdminClient>
              placeholder={AdminClientLabels.SEARCH_PLACEHOLDER}
              dropdownOptions={
                isSomething(searchableClients) ? searchableClients.value : []
              }
              onChange={onSearch}
              getOptionLabel={getClientOptionLabel}
              filterAndSortDropdownOptions={searchAndSortClients}
              maxDropdownOptions={7} // this is the max before a scrollbar shows, per product reqs
              getNoOptionsMessage={AdminClientLabels.NO_RESULTS}
              className={`${styles.searchBar} ${styles.fullWidth}`}
              initialValue={clientFromPath}
            />
            {isSomething(filteredBy) && <AdminDownloadButton />}
          </Box>
        </HasAnyEntitlementsContainer>
      </div>
      <NavigationTabs />
    </>
  );
};
