import {
  addBankAccount,
  AddBankAccountState,
  BankAccountAssignment,
  BankAccountConstants,
  BankAccountDialogType,
  changeContributionAccount,
  changeDistributionAccount,
  determinePurposeIneligibility,
  editBankAccount,
  getDefaultAddAccountValues,
  hasPendingContribution,
  hasPendingDistribution,
  IBaseStore,
  ICountry,
  isSomething,
  nothing,
  openAlert,
  Optional,
  SelectableBankAccount,
  selectBankAccountCountries,
  selectBankAccountCurrencies,
  some,
  UserAddedBankAccount,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { ElectionDialog } from "../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import { BankAccountForm } from "./BankAccountForm/BankAccountForm";

export interface IAddOrEditBankAccountDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showAutoAssignSection: boolean;
  assignmentType: BankAccountAssignment;
  investmentVehicleIds: number[];
  existingState?: AddBankAccountState;
}

export const AddOrEditBankAccountDialog = (
  props: IAddOrEditBankAccountDialogProps
) => {
  const {
    open,
    setOpen,
    showAutoAssignSection,
    assignmentType,
    investmentVehicleIds,
    existingState,
  } = props;

  const bankAccountCountries = useSelector((state: IBaseStore) =>
    selectBankAccountCountries(state)
  );

  const bankAccountCurrencies = useSelector((state: IBaseStore) =>
    selectBankAccountCurrencies(state)
  );

  const usCountryCode = useMemo(() => {
    const usCountry = bankAccountCountries.find(
      (country) => country.id === BankAccountConstants.US_COUNTRY_ID_NUMBER
    );
    return usCountry ? usCountry.countryCode : "";
  }, [bankAccountCountries]);

  const usCurrencyCode = useMemo(() => {
    const usCurrency =
      bankAccountCurrencies.find(
        (currency) =>
          currency.id === BankAccountConstants.USD_CURRENCY_ID_NUMBER
      ) ?? "";
    return usCurrency ? usCurrency.currencyCode : "";
  }, [bankAccountCurrencies]);

  const hasPendingContributionAccount = useSelector(hasPendingContribution);
  const hasPendingDistributionAccount = useSelector(hasPendingDistribution);

  const { selectedBankAccounts } = useSelector(
    (store: IBaseStore) => store.bankAccounts
  );

  const [pendingContributionRequestId, pendingDistributionRequestId] =
    useMemo(() => {
      const optionalContribution: Optional<SelectableBankAccount> =
        investmentVehicleIds.length === 1 &&
        hasPendingContributionAccount[investmentVehicleIds[0]]
          ? selectedBankAccounts[investmentVehicleIds[0]]
              .contributionBankAccount
          : nothing;
      const contribution = isSomething(optionalContribution)
        ? optionalContribution.value.requestId
        : nothing;
      const optionalDistribution: Optional<SelectableBankAccount> =
        investmentVehicleIds.length === 1 &&
        hasPendingDistributionAccount[investmentVehicleIds[0]]
          ? selectedBankAccounts[investmentVehicleIds[0]]
              .distributionBankAccount
          : nothing;
      const distribution = isSomething(optionalDistribution)
        ? optionalDistribution.value.requestId
        : nothing;
      return [contribution, distribution];
    }, [
      hasPendingContributionAccount,
      hasPendingDistributionAccount,
      investmentVehicleIds,
      selectedBankAccounts,
    ]);

  const defaultAddAccountValues: AddBankAccountState = useMemo(() => {
    if (existingState) {
      return existingState;
    }
    return getDefaultAddAccountValues(
      usCountryCode,
      usCurrencyCode,
      showAutoAssignSection,
      assignmentType,
      pendingContributionRequestId,
      pendingDistributionRequestId
    );
  }, [
    existingState,
    usCountryCode,
    usCurrencyCode,
    showAutoAssignSection,
    assignmentType,
    pendingContributionRequestId,
    pendingDistributionRequestId,
  ]);

  const addBankAccountForm = useForm<AddBankAccountState>({
    mode: "onSubmit",
    defaultValues: defaultAddAccountValues,
  });

  // needed to ensure default values are set to country and currency properly
  useEffect(() => {
    addBankAccountForm.reset(defaultAddAccountValues);
  }, [addBankAccountForm, defaultAddAccountValues]);

  const dispatch = useDispatch();

  // needed to ensure isDirty field is tracked properly
  const addedAccount = addBankAccountForm.watch();

  const [confirmClose, setConfirmClose] = useState<boolean>(false);

  // Only runs when there are pending changes, and
  // the user cancels the confirmation clicking "No"
  const cancelClose = () => {
    setConfirmClose(false);
  };

  const closeDialog = useCallback(() => {
    setOpen(false);
    // add timeout to ensure modal closes before values update
    setTimeout(() => {
      addBankAccountForm.reset(defaultAddAccountValues);
    }, 1000);
    setConfirmClose(false);
  }, [addBankAccountForm, setOpen, defaultAddAccountValues]);

  // If there are any changes, confirmation state triggers and the modal
  // requires the user to confirm its closing. Otherwise it closes immedately.
  const handleClose = useCallback(() => {
    if (Object.keys(addBankAccountForm.formState.dirtyFields).length > 0) {
      if (confirmClose) {
        closeDialog();
      } else {
        setConfirmClose(true);
      }
    } else {
      closeDialog();
    }
  }, [addBankAccountForm.formState.dirtyFields, closeDialog, confirmClose]);

  // track reasons why a purpose is ineligible for an account
  const purposeIneligibility = useMemo(() => {
    return determinePurposeIneligibility(
      addedAccount,
      pendingContributionRequestId,
      pendingDistributionRequestId,
      usCountryCode,
      usCurrencyCode
    );
  }, [
    addedAccount,
    usCountryCode,
    usCurrencyCode,
    pendingContributionRequestId,
    pendingDistributionRequestId,
  ]);

  const getHasBothPurposesEnabled = useCallback(() => {
    const hasContributionDropdownEnabled = investmentVehicleIds.some(
      (iv) => !hasPendingContributionAccount[iv]
    );
    const hasDistributionDropdownEnabled = investmentVehicleIds.some(
      (iv) => !hasPendingDistributionAccount[iv]
    );

    return hasContributionDropdownEnabled && hasDistributionDropdownEnabled;
  }, [
    hasPendingContributionAccount,
    hasPendingDistributionAccount,
    investmentVehicleIds,
  ]);

  const getSuccessMessage = useCallback(
    (eligiblePurposes: BankAccountAssignment[]) => {
      if (investmentVehicleIds.length > 1) {
        return BankAccountConstants.BANK_ACCOUNT_ADDED_MULTIPLE_IVS_MESSAGE;
      }

      return eligiblePurposes.length === 2 && getHasBothPurposesEnabled()
        ? BankAccountConstants.BANK_ACCOUNT_ADDED_BOTH_PURPOSES_MESSAGE
        : BankAccountConstants.BANK_ACCOUNT_ADDED_MESSAGE;
    },
    [getHasBothPurposesEnabled, investmentVehicleIds]
  );

  const handleContinue = useCallback(async () => {
    // helper function for converting form state to new account
    const convertAddedAccount = (
      userAddedAccount: AddBankAccountState,
      eligibleFor: BankAccountAssignment[]
    ): UserAddedBankAccount => {
      const country: ICountry | undefined = bankAccountCountries.find(
        (country: ICountry) => country.countryCode === userAddedAccount.country
      );
      return {
        main: {
          ...userAddedAccount,
          country: country ? country.countryCode : "",
          bankAccountUniqueId: existingState
            ? userAddedAccount.bankAccountUniqueId
            : crypto.randomUUID(),
        },
        intermediary: userAddedAccount.hasIntermediaryAccount
          ? some({
              ...userAddedAccount.intermediaryAccount,
              country: country ? country.countryCode : "",
            })
          : nothing,
        assignedTo: Object.keys(userAddedAccount.autoAssign)
          .filter(
            (k: string) =>
              userAddedAccount.autoAssign[k as BankAccountAssignment]
                .isChecked &&
              userAddedAccount.autoAssign[k as BankAccountAssignment].isEligible
          )
          .map((k: string) => k as BankAccountAssignment),
        eligibleFor: eligibleFor,
      };
    };

    // check form validation
    const valid = await addBankAccountForm.trigger();

    if (!valid) {
      return;
    }

    const addedBankAccount: AddBankAccountState =
      addBankAccountForm.getValues();

    const eligiblePurposes: BankAccountAssignment[] = [];
    if (purposeIneligibility[BankAccountAssignment.CONTRIBUTION].length === 0)
      eligiblePurposes.push(BankAccountAssignment.CONTRIBUTION);
    if (purposeIneligibility[BankAccountAssignment.DISTRIBUTION].length === 0)
      eligiblePurposes.push(BankAccountAssignment.DISTRIBUTION);

    const convertPendingAccount = convertAddedAccount(
      addedBankAccount,
      eligiblePurposes
    );

    // prior state means we are editting an account
    if (existingState) {
      dispatch(
        editBankAccount({
          userAddedBankAccount: convertPendingAccount,
        })
      );
      // ensure we mark unsubmitted changes for any IVs this account was in use for
      investmentVehicleIds.forEach((ivId: number) => {
        const selectedContribution =
          selectedBankAccounts[ivId].contributionBankAccount;
        if (
          isSomething(selectedContribution) &&
          selectedContribution.value.main.bankAccountUniqueId ===
            convertPendingAccount.main.bankAccountUniqueId
        ) {
          dispatch(
            changeContributionAccount({
              bankAccountUniqueId:
                convertPendingAccount.main.bankAccountUniqueId,
              eligibleFor: eligiblePurposes,
              investmentVehicleId: ivId,
              isEditMode: true,
            })
          );
        }
        const selectedDistribution =
          selectedBankAccounts[ivId].distributionBankAccount;
        if (
          isSomething(selectedDistribution) &&
          selectedDistribution.value.main.bankAccountUniqueId ===
            convertPendingAccount.main.bankAccountUniqueId
        ) {
          dispatch(
            changeDistributionAccount({
              bankAccountUniqueId:
                convertPendingAccount.main.bankAccountUniqueId,
              eligibleFor: eligiblePurposes,
              investmentVehicleId: ivId,
              isEditMode: true,
            })
          );
        }
      });
    }
    // when no existing state, we are adding a new bank account
    else {
      dispatch(
        addBankAccount({
          userAddedBankAccount: convertPendingAccount,
        })
      );

      // assign as necessary
      convertPendingAccount.assignedTo.forEach(
        (purpose: BankAccountAssignment) => {
          const dispatcherFn =
            purpose === BankAccountAssignment.CONTRIBUTION
              ? changeContributionAccount
              : changeDistributionAccount;

          dispatch(
            dispatcherFn({
              bankAccountUniqueId:
                convertPendingAccount.main.bankAccountUniqueId,
              eligibleFor: eligiblePurposes,
              investmentVehicleId:
                investmentVehicleIds.length === 1
                  ? investmentVehicleIds[0]
                  : undefined,
              isEditMode: false,
            })
          );
        }
      );
    }

    setOpen(false);
    // add timeout to ensure modal closes before values update
    setTimeout(() => {
      addBankAccountForm.reset(defaultAddAccountValues);
    }, 1000);
    dispatch(
      openAlert({
        severity: "success",
        message: getSuccessMessage(eligiblePurposes),
      })
    );
  }, [
    addBankAccountForm,
    purposeIneligibility,
    existingState,
    setOpen,
    dispatch,
    getSuccessMessage,
    bankAccountCountries,
    investmentVehicleIds,
    selectedBankAccounts,
    defaultAddAccountValues,
  ]);

  return (
    <ElectionDialog
      title={
        existingState
          ? BankAccountConstants.EDIT_ACCOUNT
          : BankAccountConstants.ADD_NEW_BANK_ACCOUNT
      }
      content={
        <BankAccountForm
          addBankAccountForm={addBankAccountForm}
          assignmentType={assignmentType}
          isConfirmClose={confirmClose}
          showAutoAssign={showAutoAssignSection}
          purposeIneligibility={purposeIneligibility}
          bankAccountDialogType={
            existingState
              ? BankAccountDialogType.EDIT
              : BankAccountDialogType.ADD
          }
        />
      }
      open={open}
      handleClose={handleClose}
      handleNext={confirmClose ? cancelClose : handleContinue}
      cancelButtonLabel={confirmClose ? "Yes, cancel" : "Cancel"}
      nextButtonLabel={
        confirmClose ? "No" : existingState ? "Save Changes" : "Add Account"
      }
      // determine if save changes button should be disabled
      // IsDirty is not always in sync with the actual dirtyFields, so we're checking dirtyFields.length instead
      // https://github.com/orgs/react-hook-form/discussions/7860
      nextButtonDisabled={
        existingState &&
        Object.keys(addBankAccountForm.formState.dirtyFields).length === 0
      }
    />
  );
};
