export const ElectorStatusExcelConstants = {
  SIZES: {
    BIG: 35,
    MEDIUM: 25,
    SMALL: 15,
  },
  KEYS: {
    IV_ID: "mdmInvestmentVehicleId",
    IV_NAME: "name",
    STEP: "step",
    SUBMITTED_DATE: "submissionDateTime",
    REOPENED_DATE: "reopenedDate",
    PERMISSIONED_USER_NAME: "permissionedUserName",
    PERMISSIONED_USER_EMAIL: "permissionedUserEmail",
  },
};
