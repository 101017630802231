import { StyleSheet } from "@react-pdf/renderer";

import { ColumnWidths } from "../../ElectionAgreementDocumentStyleSheet";

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    width: 531,
    alignItems: "center",
    fontFamily: "Guardian Sans Table",
    justifyContent: "flex-end",
  },
  election: {
    width: ColumnWidths.ELECTION,
    fontFamily: "Guardian Sans",
  },
  totalCommitment: {
    width: ColumnWidths.TOTAL_COMMITMENT,
  },
  forecastedInvestment: {
    width: ColumnWidths.FORECASTED_INVESTMENT,
  },
});
