import { ApiPath } from "../constants/ApiPathConstants";
import { DataSource } from "../constants/enums";
import { IBankAccountsCapabilities } from "../types/bankAccountDataTypes";
import { IDocumentClients, IPermittedEntities } from "../types/dataTypes";
import { IElectionPermissions } from "../types/electionDataTypes";
import {
  AdminEntitlementSummary,
  ViewElectionResultPage,
} from "../types/storeTypes";
import { Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { handleEntitlementResponse } from "./utils";

const urlsByDataSource: Record<DataSource, string> = {
  [DataSource.INTERNAL_INVESTMENTS]: `${ApiPath.INTERNAL_INVESTMENTS_URL_BASE}/my/permitted-entities`,
  [DataSource.EQUITY]: `${ApiPath.EQUITY_URL_BASE}/my/permissions`,
  [DataSource.FEATURE_FLAGS]: `${ApiPath.AUTHORIZATION_URL_BASE}/my/enabled-feature-flags`,
  [DataSource.ELECTIONS]: `${ApiPath.ELECTIONS_URL_BASE}/permissions/me`,
  [DataSource.DOCUMENTS]: `${ApiPath.DOCUMENTS_URL_BASE}/my/clients?clientType=documents`,
};

const getEntitlements = async <T>(url: string): Promise<Maybe<T>> => {
  const user = getUser();

  const params = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  };

  try {
    const response = await fetch(url, params);
    return handleEntitlementResponse<T>(response);
  } catch (e) {
    console.error(`Could not get entitlements for user at url: ${url}`, e);
    return undefined;
  }
};

const getUserHasCapability = async (brn: string, capabilityName: string) => {
  const path = ApiPath.AUTHORIZATION_URL_BASE + "/has-capability";
  const url =
    path +
    `?capabilityName=${encodeURIComponent(
      capabilityName
    )}&brn=${encodeURIComponent(brn)}`;
  return await getEntitlements<boolean>(url);
};

const getEntitlementsForDataSource = <T>(
  dataSource: DataSource
): Promise<Maybe<T>> => {
  const url = urlsByDataSource[dataSource];
  return getEntitlements(url);
};

export const getAllEntitlements = async (): Promise<
  Maybe<
    string[] | IPermittedEntities | IElectionPermissions | IDocumentClients
  >[]
> => {
  const promises = Promise.all<
    Maybe<
      IPermittedEntities | string[] | IElectionPermissions | IDocumentClients
    >
  >(
    Object.values(DataSource).map((dataSource) => {
      switch (dataSource) {
        case DataSource.INTERNAL_INVESTMENTS:
          return getEntitlementsForDataSource<IPermittedEntities>(dataSource);
        case DataSource.ELECTIONS:
          return getEntitlementsForDataSource<IElectionPermissions>(dataSource);
        case DataSource.DOCUMENTS:
          return getEntitlementsForDataSource<IDocumentClients>(dataSource);
        default:
          return getEntitlementsForDataSource<string[]>(dataSource);
      }
    })
  );

  return promises;
};

// Separate bank accounts entitlements from get all to avoid load issues and doc issues
export const getBankAccountEntitlements = async (): Promise<
  Maybe<IBankAccountsCapabilities>
> => {
  const bankAccountsEntitlement = getEntitlements<IBankAccountsCapabilities>(
    `${ApiPath.BANK_ACCOUNTS_URL_BASE}/permissions/me`
  );

  return await bankAccountsEntitlement;
};

export const getAdminEntitlements = async (): Promise<
  Maybe<
    | AdminEntitlementSummary
    | ViewElectionResultPage
    | IElectionPermissions
    | string[]
  >[]
> => {
  const electionPermissions = getEntitlements<IElectionPermissions>(
    `${ApiPath.ELECTIONS_URL_BASE}/permissions/me`
  );

  const canViewAllInternalInvestmentData = getUserHasCapability(
    "bx:bxt:investor-portal:data:internal-investments",
    "view-data"
  );

  const canViewAllEquityData = getUserHasCapability(
    "bx:bxt:investor-portal:data:equity-data",
    "view"
  );

  const canViewAllBankAccounts = getUserHasCapability(
    "bx:bxt:investor-portal:bank-accounts",
    "read-bank-accounts"
  );

  const canViewElectionResultPage = getEntitlements<boolean>(
    `${ApiPath.ELECTIONS_URL_BASE}/permissions/temp-admin-page`
  );

  const featureFlags = getEntitlementsForDataSource<string[]>(
    DataSource.FEATURE_FLAGS
  );

  return [
    {
      canViewAllInternalInvestmentData:
        (await canViewAllInternalInvestmentData) ?? false,
      canViewAllEquityData: (await canViewAllEquityData) ?? false,
      canViewAllBankAccounts: (await canViewAllBankAccounts) ?? false,
    },
    {
      canViewElectionResultPage: (await canViewElectionResultPage) ?? false,
    },
    await electionPermissions,
    await featureFlags,
  ];
};
