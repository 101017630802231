import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import {
  clearElectionGridFilters,
  FilterButton,
  IElectionsFilter,
  nothing,
  setElectionsGridFilters,
} from "common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../../../redux/store";
import { ElectorViewGridFilterContent } from "./ElectorViewGridFilterContent/ElectorViewGridFilterContent";

export interface IElectorViewGridFilterProps {
  disabled: boolean;
  totalFilters: number;
}
export const ElectorViewGridFilter = ({
  disabled,
  totalFilters,
}: IElectorViewGridFilterProps) => {
  const [currentFilters, setCurrentFilters] = useState<IElectionsFilter>({
    currentStage: nothing,
    wasReopened: nothing,
  });
  const dispatch = useDispatch();
  const { electorsGridOptions } = useSelector(
    (state: AdminUIStore) => state.elections
  );

  useEffect(() => {
    const copy = structuredClone(electorsGridOptions.filters);
    setCurrentFilters(copy);
  }, [electorsGridOptions]);

  const handleApplyFilters = () => {
    dispatch(setElectionsGridFilters(currentFilters));
  };
  const handleResetFilters = () => {
    dispatch(clearElectionGridFilters());
  };
  const hasErrors = false;
  const hasWarnings = false;

  return (
    <FilterButton
      disabled={disabled}
      countActiveFilters={totalFilters}
      handleApplyFilters={handleApplyFilters}
      handleResetFilters={handleResetFilters}
      hasErrors={hasErrors}
      hasWarnings={hasWarnings}
    >
      <ElectorViewGridFilterContent
        currentFilters={currentFilters}
        updateFilters={setCurrentFilters}
      />
    </FilterButton>
  );
};
