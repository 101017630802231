import { Box } from "@mui/material";
import { ElectorViewLabels, InfoTooltip } from "common";
import React from "react";

export interface IPermissionedUsersRowProperties {
  permissionedUserNames: string[];
}
export const PermissionedUsersRow = ({
  permissionedUserNames,
}: IPermissionedUsersRowProperties) => {
  const permissionedUserElement = (name: string, key?: string) => (
    <Box key={key} component="span">
      {name}
    </Box>
  );

  if (permissionedUserNames.length === 0) {
    return <></>;
  }

  if (permissionedUserNames.length === 1) {
    return permissionedUserElement(permissionedUserNames[0]);
  }

  const permissionedUserElements = (
    <>
      {permissionedUserNames.map((name, index) =>
        permissionedUserElement(name, index.toString())
      )}
    </>
  );

  return (
    <Box component="span">
      {ElectorViewLabels.MULTIPLE}{" "}
      <InfoTooltip tooltipContent={permissionedUserElements}></InfoTooltip>
    </Box>
  );
};
