import { Stack, Typography } from "@mui/material";
import {
  DayOnlyDateWithDatetimeTooltip,
  ElectionsLabels,
  formatDateMMMDDYYYYOrNull,
  FormCardHeader,
  isSomething,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { selectElectionRoundConfiguration } from "../../../../../redux/selectors";
import { ElectionCard } from "../../Shared/ElectionCard";
import { DaysLeftChip } from "./DaysLeftChip/DaysLeftChip";
import styles from "./ElectionPeriodAndDeadlinesCard.module.scss";

export const ElectionPeriodAndDeadlinesCard = () => {
  const {
    electionSubmissionDeadline,
    taxFormsDueDate,
    annualElectionStartDate,
    annualElectionEndDate,
  } = useSelector(selectElectionRoundConfiguration);

  return (
    <ElectionCard>
      <FormCardHeader
        title={ElectionsLabels.ELECTION_PERIOD_AND_DEADLINE}
        bottomBorder
        actionElement={<DaysLeftChip />}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        className={styles.dateRow}
      >
        <Typography className={styles.dateName}>
          {ElectionsLabels.ELECTION_SUBMISSION_DEADLINE}
        </Typography>
        <DayOnlyDateWithDatetimeTooltip date={electionSubmissionDeadline} />
      </Stack>
      {isSomething(taxFormsDueDate) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          className={styles.dateRow}
        >
          <Typography className={styles.dateName}>
            {ElectionsLabels.TAX_FORMS_DEADLINE}
          </Typography>
          <Typography>
            {formatDateMMMDDYYYYOrNull(taxFormsDueDate.value)}
          </Typography>
        </Stack>
      )}
      {isSomething(annualElectionStartDate) &&
        isSomething(annualElectionEndDate) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            className={styles.dateRow}
          >
            <Typography className={styles.dateName}>
              {ElectionsLabels.ANNUAL_ELECTION_PERIOD}
            </Typography>
            <Stack direction="row">
              <Typography>
                {formatDateMMMDDYYYYOrNull(annualElectionStartDate.value)}
              </Typography>
              <Typography className={styles.separator}>{" - "}</Typography>
              <Typography>
                {formatDateMMMDDYYYYOrNull(annualElectionEndDate.value)}
              </Typography>
            </Stack>
          </Stack>
        )}
    </ElectionCard>
  );
};
