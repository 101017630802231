import { ApiPath } from "../constants/ApiPathConstants";
import { IEmployeeSource, IEquityData } from "../types/dataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { handleResponse } from "./utils";

export type IEquitySourceData = Json<IEquityData>;

// Gets Employee Equity Data
export const getEquityData = async (
  employeeNumber: string
): Promise<Maybe<IEquitySourceData[]>> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_URL_BASE}/employees/${employeeNumber}/equity`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return handleResponse(response);
};

export type IEmployeeSources = Json<IEmployeeSource>[];

export const getEquityEmployees = async (): Promise<
  Maybe<IEmployeeSources>
> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_URL_BASE}/employees`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return await handleResponse(response);
};
