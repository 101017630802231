import { View } from "@react-pdf/renderer";
import { IElectCardData } from "common";
import React, { useMemo } from "react";

import { ElectionAmountTableFinancingRows } from "./ElectionAmountTableFinancingRows/ElectionAmountTableFinancingRows";
import { ElectionAmountTableHeader } from "./ElectionAmountTableHeader/ElectionAmountTableHeader";
import { ElectionAmountTableRows } from "./ElectionAmountTableRows/ElectionAmountTableRows";
import { ElectionAmountTableRowTotal } from "./ElectionAmountTableRowTotal/ElectionAmountTableRowTotal";
import { styles } from "./ElectionAmountTableStyleSheet";

interface IElectionAmountTableProps {
  electionAmount: IElectCardData;
  canUseFinancing: boolean;
  hasForecastedInvestment: boolean;
}

export const ElectionAmountTable = (props: IElectionAmountTableProps) => {
  const { electionAmount, canUseFinancing, hasForecastedInvestment } = props;

  const [hasAnticipatedPriorPeriodsCapitalCalls, hasMandatoryCommitment] =
    useMemo(() => {
      return [
        electionAmount.totalRow.remainingCommitment > 0,
        electionAmount.totalRow.mandatoryCommitment > 0,
      ];
    }, [electionAmount.totalRow]);

  return (
    <View style={styles.container}>
      <ElectionAmountTableHeader
        hasForecastedInvestment={hasForecastedInvestment}
        hasAnticipatedPriorPeriodsCapitalCalls={
          hasAnticipatedPriorPeriodsCapitalCalls
        }
        hasMandatoryCommitment={hasMandatoryCommitment}
      />
      <ElectionAmountTableRows
        items={electionAmount.electCardData}
        hasForecastedInvestment={hasForecastedInvestment}
        hasAnticipatedPriorPeriodsCapitalCalls={
          hasAnticipatedPriorPeriodsCapitalCalls
        }
        hasMandatoryCommitment={hasMandatoryCommitment}
      />
      <ElectionAmountTableRowTotal
        item={electionAmount.totalRow}
        hasForecastedInvestment={hasForecastedInvestment}
        hasAnticipatedPriorPeriodsCapitalCalls={
          hasAnticipatedPriorPeriodsCapitalCalls
        }
        hasMandatoryCommitment={hasMandatoryCommitment}
      />
      {canUseFinancing && (
        <ElectionAmountTableFinancingRows
          financing={electionAmount.financing}
          hasForecastedInvestment={hasForecastedInvestment}
        />
      )}
    </View>
  );
};
