import { Grid } from "@mui/material";
import React, { useDispatch, useSelector } from "react-redux";

import { PerformanceDetails } from "../../components/PerformanceDetails/PerformanceDetails";
import { SectionHeader } from "../../constants/LabelAndTooltipConstants";
import { useFetchDatasetIfIdDefined } from "../../hooks/dataFetchHooks";
import { useExcelDownload } from "../../hooks/useExcelDownload";
import {
  reqInternalInvestmentData,
  setPerformanceDetailsFilter,
} from "../../redux/actions/internalInvestmentActions";
import {
  selectAsOfDates,
  selectFilteredBy,
  selectInternalInvestmentData,
  selectInvestmentBreakdownGroupedAndSorted,
} from "../../redux/selectors";
import { IBaseStore } from "../../redux/store";
import { worksheetGenerators } from "../../utils/excelUtils/excelGenerationUtils";
import styles from "./PerformanceDetailsPage.module.scss";

interface IPerformanceDetailsPage {
  hideHeader?: boolean;
}

export const PerformanceDetailsPage = (props: IPerformanceDetailsPage) => {
  const { performanceDetailsFilter } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const { internalInvestmentDataLoadStatus } = useSelector(
    (state: IBaseStore) => selectInternalInvestmentData(state)
  );
  const filteredBy = useSelector(selectFilteredBy);

  const investmentBreakdownGrouped = useSelector((store: IBaseStore) =>
    selectInvestmentBreakdownGroupedAndSorted(store)
  );

  const asOfDate = useSelector((store: IBaseStore) => selectAsOfDates(store));
  const dispatch = useDispatch();

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    filteredBy,
    internalInvestmentDataLoadStatus
  );

  const handleChangeFilter = (searchTerm: string) => {
    dispatch(setPerformanceDetailsFilter(searchTerm));
  };

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    await handleExcelDownload(
      worksheetGenerators.InvestmentBreakdown,
      SectionHeader.INVESTMENT_BREAKDOWN
    );
  };

  return (
    <Grid id={styles.performanceDetailsPage}>
      {!props.hideHeader && <h1>{SectionHeader.PERFORMANCE_DETAILS}</h1>}
      <PerformanceDetails
        data={investmentBreakdownGrouped}
        dataLoadStatus={internalInvestmentDataLoadStatus}
        filter={performanceDetailsFilter}
        onChangeFilter={handleChangeFilter}
        retryRequest={retryFunction}
        onDownload={handleDownloadClick}
        dateRange={asOfDate}
      />
    </Grid>
  );
};
