import {
  ELECTION_STEPPER_BUTTON_CLASSNAMES,
  ELECTION_STEPPER_BUTTON_CONSTRAINTS,
} from "../constants/ElectionWorkflow";
import {
  ElectionStatus,
  ElectionStepperButton,
  ElectionStepperButtonState,
} from "../constants/enums";
import { ElectionsLabels } from "../constants/LabelAndTooltipConstants";
import {
  ElectionAccordionObject,
  ElectionWorkflowStageId,
  ElectStageWarningType,
  IElectCardData,
  IElectCardFinancing,
  IElectCardFinancingValues,
  IElectCardGridRow,
  IElectionDecision,
  IElectionIVConfiguration,
  IElectionRoundConfiguration,
  IElectionRoundConfigurationModel,
  IElectionsForClient,
  IElectionWorkflowState,
  IElectStageWarning,
  IFundModel,
  IGetElectionDetailsRequestPayload,
  IIVStrategy,
  IStrategy,
  IStrategyElection,
  IStrategyForecastedDeploymentPercentage,
  IStrategyModel,
  WorkflowTypeEntity,
} from "../types/electionDataTypes";
import { isSomething } from "../types/typeGuards";
import { nothing, Optional, some } from "../types/typeUtils";
import { emptyIfNothing, zeroIfNaN, zeroIfNothing } from "./formatters";

export const PATRIA_STRATEGY_ID = 8179555;

/*
Function to compute a user's cash amount, financing amount, and projected loan
  balance based on their election, financing percent, loan limit, and existing loan
  balance. There are two ways this can go. Steps as follows:

  - compute expected financing based on financing percent and user's election
  - expected projected loan balance is the expected financing + existing loan balance
    - if this value is under the loan limit, then these are the final values
    - if this value is greater than the loan limit, we should cap it there and
      adjust the financing to be (loan limit - existing loan balance) as well as the cash
      value to be (election - newFinancing)

Additional caveat!!
  Some legacy elections contain funds for the Patria strategy. In these elections, the Patria
  strategy had 0% financing, even if an IV was ocnfigured for financing on other funds. To
  account for this, this function takes a nonPatria elected amount and a patria elected amount.
  We only apply financing to the non-Patria amount, but include both amounts in the totals.

  i.e. if a user elected 75,000 to Patria and 75,000 to Other and has .7 financing value, then
    we expect:
      financing value = .7 * 75,000 = 52,500 (only includes Other strategy)
      cash = (75,000 + 75,000) - 52,500 = 97,500 (total amount minus the above)
*/
export const getFinancingValues = (
  nonPatriaStrategyValue: number,
  patriaStrategyValue: number,
  useFinancing: boolean,
  electionIVConfiguration: IElectionIVConfiguration
): IElectCardFinancingValues => {
  const totalValue = nonPatriaStrategyValue + patriaStrategyValue;
  const financingPct = useFinancing
    ? zeroIfNothing(electionIVConfiguration.financingPercentage)
    : 0;
  const loanBalance = zeroIfNothing(electionIVConfiguration.loanBalance);
  const loanLimit = zeroIfNothing(electionIVConfiguration.loanLimit);
  const computedFinancingAmount = financingPct * nonPatriaStrategyValue;
  const projectedLoanBalance = computedFinancingAmount + loanBalance;
  if (loanLimit > 0 && projectedLoanBalance > loanLimit) {
    // when projected loan balance exceeds the limit
    const loanDifference = loanLimit - loanBalance;
    return {
      cash: totalValue - loanDifference,
      financing: loanDifference,
      existingLoanBalance: loanBalance,
      projectedLoanBalance: loanLimit,
      loanLimit: loanLimit,
    };
  } else {
    // when projected loan balance is under the loan limit
    return {
      cash: totalValue - computedFinancingAmount,
      financing: computedFinancingAmount,
      existingLoanBalance: loanBalance,
      projectedLoanBalance: projectedLoanBalance,
      loanLimit: loanLimit,
    };
  }
};

export const getElectCardData = (
  electionRoundConfigStrategies: IStrategyModel[],
  electionIVConfiguration: IElectionIVConfiguration,
  electionWorkflowState: IElectionWorkflowState,
  hideOptionalElectionData?: boolean
): IElectCardData => {
  const electCardRows: IElectCardGridRow[] = [];
  let totalStrategyMaximum = 0;
  let totalRemainingCommitment = 0;
  let totalMandatoryCommitment = 0;
  let totalOptionalElection = 0;
  let totalNonPatriaCommitment = 0;
  let totalPatriaCommitment = 0;
  let totalNonPatriaForecastDeployment = 0;
  let totalPatriaForecastDeployment = 0;
  let showTotalForcastedInvestment = false;

  electionRoundConfigStrategies.forEach((strategy: IStrategyModel) => {
    const strategyConfigForIV = electionIVConfiguration.strategies.filter(
      (strat: IIVStrategy) => strategy.strategyId === strat.strategyId
    );
    const decisionForStrategy =
      electionWorkflowState.electionStages.elect.elections.filter(
        (electionDecision: IElectionDecision) =>
          electionDecision.strategyId === strategy.strategyId
      );
    if (strategyConfigForIV.length === 1 && decisionForStrategy.length === 1) {
      const strategyConfig = strategyConfigForIV[0];
      // Review and Revise page shouldn't show electedAmount from closed and unsubmitted elections.
      const optionalElection = hideOptionalElectionData
        ? nothing
        : decisionForStrategy[0].electedAmount;
      const totalCommitment =
        zeroIfNothing(strategyConfig.remainingCommitment) +
        zeroIfNothing(strategyConfig.mandatoryCommitment) +
        zeroIfNothing(optionalElection);
      const totalForecastedDeployment =
        zeroIfNothing(strategyConfig.remainingCommitment) +
        zeroIfNothing(strategyConfig.mandatoryCommitment) +
        zeroIfNothing(optionalElection) *
          strategy.forecastedDeploymentPercentage;
      showTotalForcastedInvestment =
        showTotalForcastedInvestment ||
        strategy.forecastedDeploymentPercentage > 0;
      const electCardRow: IElectCardGridRow = {
        strategyId: strategy.strategyId,
        name: strategy.name,
        strategyMin: strategyConfig.min,
        strategyMax: strategyConfig.max,
        remainingCommitment: zeroIfNothing(strategyConfig.remainingCommitment),
        mandatoryCommitment: zeroIfNothing(strategyConfig.mandatoryCommitment),
        optionalElection: optionalElection,
        optionalElectionPercentage: 0.0,
        totalCommitment: totalCommitment,
        totalForecastInvestment: totalForecastedDeployment,
        firstOperator: nothing,
        secondOperator: nothing,
        thirdOperator: nothing,
        fourthOperator: nothing,
      };
      electCardRows.push(electCardRow);
      totalStrategyMaximum += zeroIfNothing(strategyConfig.max);
      totalRemainingCommitment += zeroIfNothing(
        strategyConfig.remainingCommitment
      );
      totalMandatoryCommitment += zeroIfNothing(
        strategyConfig.mandatoryCommitment
      );
      totalOptionalElection += zeroIfNothing(optionalElection);
      // differentiate Patria strategy elections from Non-Patria strategies
      if (strategyConfig.strategyId === PATRIA_STRATEGY_ID) {
        totalPatriaCommitment += totalCommitment;
        totalPatriaForecastDeployment += totalForecastedDeployment;
      } else {
        totalNonPatriaCommitment += totalCommitment;
        totalNonPatriaForecastDeployment += totalForecastedDeployment;
      }
    }
  });

  const sortedElectCardRows = electCardRows.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const finalizedElectCardRows = computeElectionPercentages(
    sortedElectCardRows,
    some(totalOptionalElection)
  );

  const totalRow: IElectCardGridRow = {
    strategyId: 0,
    name: ElectionsLabels.TOTAL,
    strategyMin: nothing,
    strategyMax: some(totalStrategyMaximum),
    remainingCommitment: totalRemainingCommitment,
    mandatoryCommitment: totalMandatoryCommitment,
    optionalElection: some(totalOptionalElection),
    optionalElectionPercentage: totalOptionalElection === 0 ? 0 : 100,
    totalCommitment: totalNonPatriaCommitment + totalPatriaCommitment,
    totalForecastInvestment:
      totalNonPatriaForecastDeployment + totalPatriaForecastDeployment,
    firstOperator: nothing,
    secondOperator: nothing,
    thirdOperator: nothing,
    fourthOperator: nothing,
  };

  const useFinancing = electionIVConfiguration.canUseFinancing;

  const financingValues: IElectCardFinancing = {
    financingPercent: zeroIfNothing(
      electionIVConfiguration.financingPercentage
    ),
    totalCommitment: getFinancingValues(
      totalNonPatriaCommitment,
      totalPatriaCommitment,
      useFinancing,
      electionIVConfiguration
    ),
    totalForecastedInvestment: getFinancingValues(
      totalNonPatriaForecastDeployment,
      totalPatriaForecastDeployment,
      useFinancing,
      electionIVConfiguration
    ),
    thirdOperator: {
      cash: nothing,
      financing: nothing,
      existingLoanBalance: some(ElectionsLabels.PLUS_SIGN),
      projectedLoanBalance: some(ElectionsLabels.EQUAL_SIGN),
      loanLimit: nothing,
    },
    fourthOperator: {
      cash: nothing,
      financing: nothing,
      existingLoanBalance: nothing,
      projectedLoanBalance: nothing,
      loanLimit: nothing,
    },
  };

  return {
    electCardData: finalizedElectCardRows,
    showTotalForcastedInvestment: showTotalForcastedInvestment,
    totalRow: totalRow,
    financing: financingValues,
    canRequestAdditional: electionIVConfiguration.canRequestAdditional,
  };
};

/*
  replaces the old election decision for a given strategy with an updated decision
*/
export const getNewElectionDecisions = (
  newElectionDecision: IElectionDecision,
  prevDecisions: IElectionDecision[]
): IElectionDecision[] => {
  const newElectionDecisions = prevDecisions.filter(
    (decision: IElectionDecision) =>
      decision.strategyId !== newElectionDecision.strategyId
  );
  newElectionDecisions.push(newElectionDecision);
  return newElectionDecisions;
};

export const computeElectionPercentages = (
  elections: IElectCardGridRow[],
  totalElection: Optional<number>
) => {
  return elections.map((row: IElectCardGridRow) => {
    // avoid divide by zero error:
    //  if total election is 0 or the optionalElection is 0, every percentage should be 0
    const optionalElection = zeroIfNothing(row.optionalElection);
    const pct =
      !isSomething(totalElection) ||
      totalElection.value === 0 ||
      optionalElection === 0
        ? 0
        : (optionalElection / totalElection.value) * 100.0;
    return {
      ...row,
      optionalElectionPercentage: pct,
    };
  });
};

/*
validates that a user's input is within the strategy minimum and maximum values
Extra notes:
  - user is allowed to opt out of a strategy (i.e. 0 value should not be an error)
  - if user can request additional, going over maximum should have no error
*/
export const validateElectionForStrategy = (
  data: number,
  strategyMin: Optional<number>,
  strategyMax: Optional<number>,
  canRequestAdditional: boolean
) => {
  const finalVal = Math.round(data);
  if (isSomething(strategyMin)) {
    if (finalVal > 0 && finalVal < strategyMin.value) {
      return ElectionsLabels.STRATEGY_MIN_ERROR_MESSAGE(strategyMin.value);
    }
  }
  if (isSomething(strategyMax)) {
    if (
      strategyMax.value > 0 &&
      finalVal > strategyMax.value &&
      !canRequestAdditional
    ) {
      return ElectionsLabels.STRATEGY_MAX_ERROR_MESSAGE(strategyMax.value);
    }
  }
};

export const convertElectionInputToNumber = (input: string) => {
  const floatInput = parseFloat(input.replaceAll(",", ""));
  const finalVal = zeroIfNaN(floatInput);
  return finalVal;
};

export const convertElectionInputToRoundedNumber = (input: string) => {
  // when leaving cell, we want to round the value to nearest dollar
  return Math.round(convertElectionInputToNumber(input));
};

export const getBannerAccordionIdFromWorkflowStageId = (
  stageId: ElectionWorkflowStageId
): ElectionAccordionObject => {
  switch (stageId) {
    case ElectionWorkflowStageId.OVERVIEW:
      return ElectionAccordionObject.OVERVIEW_BANNER;
    case ElectionWorkflowStageId.ELIGIBILITY:
      return ElectionAccordionObject.ELIGIBILITY_BANNER;
    case ElectionWorkflowStageId.STRATEGIES:
      return ElectionAccordionObject.STRATEGIES_BANNER;
    case ElectionWorkflowStageId.ELECT:
      return ElectionAccordionObject.ELECT_BANNER;
    case ElectionWorkflowStageId.BANK_ACCOUNT:
      return ElectionAccordionObject.BANK_ACCOUNT_BANNER;
    case ElectionWorkflowStageId.REVIEW_AND_SIGN:
      return ElectionAccordionObject.REVIEW_AND_SIGN_BANNER;
    default:
      return ElectionAccordionObject.NO_BANNER;
  }
};

export const validateFullElectionGrid = (
  elections: IElectionDecision[],
  electionMinimum: number,
  offerAmount: Optional<number>,
  canRequestAdditional: boolean,
  additionalRequestLimit: Optional<number>
) => {
  const totalElectAmount = elections.reduce(
    //If the current electedAmount is undefined we will use 0 instead.
    (sum, current) => sum + zeroIfNothing(current.electedAmount),
    0
  );
  if (totalElectAmount > 0 && totalElectAmount < electionMinimum) {
    return ElectionsLabels.INVALID_ELECTION_MINIMUM(electionMinimum);
  }
  if (!canRequestAdditional && totalElectAmount > zeroIfNothing(offerAmount)) {
    return ElectionsLabels.YOU_ARE_EXCEEDING_OFFER_AMOUNT;
  }
  if (
    canRequestAdditional &&
    isSomething(additionalRequestLimit) &&
    totalElectAmount > zeroIfNothing(additionalRequestLimit)
  ) {
    return ElectionsLabels.YOU_ARE_EXCEEDING_ADDITIONAL_LIMIT(
      zeroIfNothing(additionalRequestLimit)
    );
  }
};

/*
based on user's election configuration and elected amounts, determines if any warnings
  should be displayed when going to necxt stage
*/
export const getElectGridWarnings = (
  elections: IElectionDecision[],
  ivStrategyConfigs: IIVStrategy[],
  canRequestAdditional: boolean,
  offerAmount: Optional<number>,
  financingPercent: Optional<number>,
  existingLoanBalance: Optional<number>,
  loanLimit: Optional<number>
): Optional<IElectStageWarning> => {
  const totalElectAmount = elections.reduce(
    //If the current electedAmount is undefined we will use 0 instead.
    (sum, current) => sum + zeroIfNothing(current.electedAmount),
    0
  );
  const totalRemainingAndMandatory = ivStrategyConfigs.reduce(
    (sum, current) =>
      sum +
      zeroIfNothing(current.mandatoryCommitment) +
      zeroIfNothing(current.remainingCommitment),
    0
  );
  const totalCommitment = totalElectAmount + totalRemainingAndMandatory;

  const exceededStrategies = ivStrategyConfigs
    .map((strategy: IIVStrategy) => {
      const strategyElection = elections.find(
        (election: IElectionDecision) =>
          election.strategyId === strategy.strategyId
      );
      return {
        strategyId: strategy.strategyId,
        strategyName: emptyIfNothing(strategy.strategyName),
        electedAmount: strategyElection?.electedAmount,
        max: zeroIfNothing(strategy.max),
      } as IStrategyElection;
    })
    .filter((x) => x.electedAmount)
    .filter((strategy: IStrategyElection) => {
      //If the strategy electedAmount is undefined we will use 0 instead.
      const electedAmount = zeroIfNothing(strategy.electedAmount);
      return strategy.max > 0 && electedAmount > strategy.max;
    })
    .sort((a, b) => a.strategyName.localeCompare(b.strategyName));

  const warnings: ElectStageWarningType[] = [];
  const offerAmountOrZero = zeroIfNothing(offerAmount);
  if (canRequestAdditional) {
    if (exceededStrategies.length > 0 && totalElectAmount > offerAmountOrZero) {
      warnings.push(
        ElectStageWarningType.EXCEEDS_OFFER_AMOUNT_AND_STRATEGY_MAX
      );
    } else if (exceededStrategies.length > 0) {
      warnings.push(ElectStageWarningType.EXCEEDS_STRATEGY_MAX);
    } else if (totalElectAmount > offerAmountOrZero) {
      warnings.push(ElectStageWarningType.EXCEEDS_OFFER_AMOUNT);
    }
  }

  const zeroIfNothingLoanLimit = zeroIfNothing(loanLimit);
  const zeroIfNothingFinancingPercent = zeroIfNothing(financingPercent);
  const zeroIfNothingLoanBalance = zeroIfNothing(existingLoanBalance);
  const currentProjectedLoanBalance =
    totalCommitment * zeroIfNothingFinancingPercent + zeroIfNothingLoanBalance;

  if (
    zeroIfNothingLoanLimit > 0 &&
    currentProjectedLoanBalance > zeroIfNothingLoanLimit
  ) {
    warnings.push(ElectStageWarningType.EXCEEDS_LOAN_LIMIT);
  }

  return warnings.length > 0
    ? some({
        warningTypes: warnings,
        exceededStrategies: exceededStrategies,
      })
    : nothing;
};

export const getStrategyAllocationPercentages = (
  strategyConfigs: IStrategy[]
): IStrategyForecastedDeploymentPercentage[] => {
  const strategiesWithForecastedDeplpoyment = strategyConfigs.map(
    (strategy) => ({
      ...strategy,
      forecastedDeployment: strategy.funds.reduce(
        (acc, fund) => (acc += zeroIfNothing(fund.forecastedDeployment)),
        0
      ),
    })
  );
  const totForecastedDeployment = strategiesWithForecastedDeplpoyment.reduce(
    (sum, current) => sum + current.forecastedDeployment,
    0
  );

  return strategiesWithForecastedDeplpoyment
    .map((strategy) => {
      return {
        strategyId: strategy.strategyId,
        strategyName: strategy.name,
        percentage:
          (strategy.forecastedDeployment / totForecastedDeployment) * 100,
      };
    })
    .sort((a, b) => a.strategyName.localeCompare(b.strategyName));
};

/**
 * Returns the name of a investment vehicle name joined a election round name
 * @param ivConfig - A election investment vehicle configuration object
 * @param electionRound - A election round object
 * @returns string value
 */
export const buildIVByElectionRoundTitle = (
  ivConfig: Optional<IElectionIVConfiguration>,
  electionRound: Optional<IElectionRoundConfiguration>
): string => {
  const defaultString = "-";
  const investmentVehicleName = isSomething(ivConfig)
    ? ivConfig.value.investmentVehicle.name
    : defaultString;
  const electionRoundName = isSomething(electionRound)
    ? electionRound.value.name
    : defaultString;

  return `${investmentVehicleName} / ${electionRoundName}`;
};

/**
 * Returns true if a given election for a client is in review status otherwise, false
 * @param electionsForClient - A list of elections for a client
 * @param activeElection - An active election
 * @returns boolean value
 */
export const isElectionForClientInReviewStatus = (
  electionsForClient: IElectionsForClient[],
  activeElection: Optional<IGetElectionDetailsRequestPayload>
): boolean => {
  if (!isSomething(activeElection)) {
    return false;
  }

  const electionForClient = electionsForClient.find(
    (election: IElectionsForClient) => {
      return (
        election.electionRoundId === activeElection.value.electionRoundId &&
        election.investmentVehicle.investmentVehicleId ===
          activeElection.value.investmentVehicleId
      );
    }
  );

  return electionForClient?.currentStage === ElectionWorkflowStageId.COMPLETED;
};

export const mapElectionRoundConfigurationToModel = (
  source: IElectionRoundConfiguration
): IElectionRoundConfigurationModel => {
  const strategies = source.strategies.map((strategy) => {
    const funds = strategy.funds;
    const aggregations = funds.reduce(
      (acc, fund) => ({
        forecastedDeployment:
          acc.forecastedDeployment + zeroIfNothing(fund.forecastedDeployment),
        totalSubjectToElection:
          acc.totalSubjectToElection +
          zeroIfNothing(fund.totalSubjectToElection),
      }),
      { forecastedDeployment: 0, totalSubjectToElection: 0 }
    );
    return {
      ...strategy,
      forecastedDeployment: some(aggregations.forecastedDeployment),
      totalSubjectToElection: some(aggregations.totalSubjectToElection),
      forecastedDeploymentPercentage:
        aggregations.totalSubjectToElection > 0
          ? aggregations.forecastedDeployment /
            aggregations.totalSubjectToElection
          : 0,
      funds: strategy.funds.map(
        (fund) =>
          ({
            ...fund,
            forecastedDeploymentPercentage:
              zeroIfNothing(fund.forecastedDeployment) /
              (isSomething(fund.totalSubjectToElection)
                ? fund.totalSubjectToElection.value
                : 1),
          } as IFundModel)
      ),
    } as IStrategyModel;
  });

  return { ...source, strategies };
};

export const getElectionStepperButtonsStates = (
  activeStageId: ElectionWorkflowStageId
) => {
  const defaultState = ElectionStepperButtonState.Show;
  return [
    ElectionStepperButton.SAVE,
    ElectionStepperButton.BACK,
    ElectionStepperButton.NEXT,
  ].reduce((acc, curr) => {
    const ButtonState =
      ELECTION_STEPPER_BUTTON_CONSTRAINTS[activeStageId]?.[curr] ??
      defaultState;
    return {
      ...acc,
      [curr]: ButtonState,
    };
  }, {}) as Record<ElectionStepperButton, ElectionStepperButtonState>;
};

export const getElectionStepperButtonClass = (
  styles: {
    readonly [key: string]: string;
  },
  index?: ElectionStepperButtonState
) => {
  if (typeof index === "undefined") return "";
  return styles[ELECTION_STEPPER_BUTTON_CLASSNAMES[index]];
};

export const isElectionStepperButtonDisabled = (
  index: ElectionStepperButtonState
) => {
  return index === ElectionStepperButtonState.Disable;
};

export const electionIsInProgess = (
  stage: ElectionWorkflowStageId
): boolean => {
  return (
    stage === ElectionWorkflowStageId.OVERVIEW ||
    stage === ElectionWorkflowStageId.ELIGIBILITY ||
    stage === ElectionWorkflowStageId.STRATEGIES ||
    stage === ElectionWorkflowStageId.ELECT ||
    stage === ElectionWorkflowStageId.BANK_ACCOUNT ||
    stage === ElectionWorkflowStageId.REVIEW_AND_SIGN
  );
};

export const getStatusByStage = (
  stage: ElectionWorkflowStageId,
  submissionDeadline: Date,
  systemOpenDate: Date,
  systemClosedDate: Date,
  workflowType: WorkflowTypeEntity
): ElectionStatus => {
  const now = new Date();
  if (submissionDeadline < now && stage === ElectionWorkflowStageId.COMPLETED) {
    return ElectionStatus.SUBMITTED;
    // show election as closed if close date has passed,
    // but also if election deadline has passed and user hasn't started
  } else if (
    (systemOpenDate > now && workflowType !== WorkflowTypeEntity.Admin) ||
    systemClosedDate < now ||
    (submissionDeadline < now &&
      (stage === ElectionWorkflowStageId.NOT_STARTED ||
        stage === ElectionWorkflowStageId.UNKNOWN))
  ) {
    return ElectionStatus.CLOSED;
  }
  if (
    stage === ElectionWorkflowStageId.NOT_STARTED ||
    stage === ElectionWorkflowStageId.UNKNOWN
  ) {
    return ElectionStatus.READY_TO_START;
  }
  if (electionIsInProgess(stage)) {
    return ElectionStatus.IN_PROGRESS;
  }
  if (stage === ElectionWorkflowStageId.COMPLETED) {
    return ElectionStatus.READY_FOR_REVIEW;
  }
  return ElectionStatus.CLOSED;
};
