import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import { ColDef } from "ag-grid-community";
import {
  dateFormatterWithDash,
  DEFAULT_COLUMN_DEF,
  GridAsTable,
  IElectionRound,
  isInProgress,
  LoadingIndicator,
  NoDataAvailableError,
  reqAllElectionRounds,
  SectionHeader,
  useFetchDatasetWithoutId,
  useGridExtensions,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RelativePath } from "../../../constants/Paths";
import { AdminUIStore } from "../../../redux/store";
import styles from "./ElectionAdminPage.module.scss";

export type IElectionRoundListRow = IElectionRound;

const ElectionAdminPage = () => {
  const navigate = useNavigate();

  const { allElectionRounds, allElectionRoundsLoadStatus } = useSelector(
    (store: AdminUIStore) => store.elections
  );

  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();

  useFetchDatasetWithoutId(reqAllElectionRounds, allElectionRoundsLoadStatus);

  const columnDefs: ColDef<IElectionRoundListRow>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 300,
      },
      {
        field: "systemOpenDate",
        headerName: "System Open Date",
        cellRenderer: dateFormatterWithDash,
      },
      {
        field: "systemCloseDate",
        headerName: "System Close Date",
        cellRenderer: dateFormatterWithDash,
      },
      {
        field: "createdDate",
        headerName: "Created Date",
        cellRenderer: dateFormatterWithDash,
      },
      {
        headerName: "Elector View",
        maxWidth: 120,
        cellRenderer: (params: {
          value: string;
          data: IElectionRoundListRow;
        }) => (
          <div className={styles.electorViewIcon}>
            <IconButton
              onClick={() =>
                navigate(
                  RelativePath.ELECTOR_VIEW_PAGE.replace(
                    ":electionRoundId",
                    params.data.electionRoundId
                  ) as RelativePath
                )
              }
            >
              <AssignmentIndRoundedIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    [navigate]
  );

  const rowData = useMemo(() => {
    return [...allElectionRounds]
      .sort((a: IElectionRound, b: IElectionRound) =>
        a.name.localeCompare(b.name)
      )
      .sort((a: IElectionRound, b: IElectionRound) =>
        a.createdDate < b.createdDate ? 1 : -1
      );
  }, [allElectionRounds]);

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <Typography variant="h1">
          {SectionHeader.ELECTION_ADMIN_PAGE}
        </Typography>
      </div>
      <div className={styles.pageContent}>
        {isInProgress(allElectionRoundsLoadStatus) ? (
          <div>
            <LoadingIndicator />
          </div>
        ) : (
          <Stack className="ag-theme-alpine" id={styles.dataGrid}>
            <GridAsTable<IElectionRoundListRow>
              cacheQuickFilter={true}
              columnDefs={columnDefs}
              defaultColDef={DEFAULT_COLUMN_DEF}
              domLayout="autoHeight"
              enableCellTextSelection={true}
              onColumnResized={setHeaderHeight}
              onDisplayedColumnsChanged={resizeColumns}
              onFirstDataRendered={setHeaderHeight}
              onGridReady={onGridReady}
              onGridSizeChanged={resizeColumns}
              onModelUpdated={resizeColumns}
              onRowDataUpdated={resizeColumns}
              // only show rows for the given page
              rowData={rowData}
              noRowsOverlayComponent={NoDataAvailableError}
              scrollbarWidth={10}
              suppressAggFuncInHeader={true}
              suppressBrowserResizeObserver={true}
              suppressCellFocus={true}
              suppressContextMenu={true}
              suppressDragLeaveHidesColumns={true}
              suppressMenuHide={true}
              suppressMovableColumns={true}
              tooltipShowDelay={0}
            />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default ElectionAdminPage;
