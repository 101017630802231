import { createSelector } from "@reduxjs/toolkit";
import {
  combineClients,
  IAdminClient,
  isSomething,
  nothing,
  Optional,
  selectViewDataStore,
  some,
} from "common";

import { AdminUIStore } from "../store";

// selects the internal investment data state
// it contains: filteredBy, entities, internalInvesmentDataLoadStatus
export const selectClientsStore = (state: AdminUIStore) => state.clients;

export const selectActiveClientMetadata = createSelector(
  [selectClientsStore, selectViewDataStore],
  (
    { internalInvestmentClients },
    { selectedEntity }
  ): Optional<IAdminClient> => {
    if (isSomething(selectedEntity) && isSomething(internalInvestmentClients)) {
      const matchingClient: IAdminClient | undefined =
        internalInvestmentClients.value.find(
          (client: IAdminClient) => client.id === selectedEntity.value.clientId
        );
      if (matchingClient) {
        return some(matchingClient);
      }
    }
    return nothing;
  }
);

export const selectActiveClients = createSelector(
  [selectClientsStore],
  ({ internalInvestmentClients, equityClients }): Optional<IAdminClient[]> => {
    const clients: IAdminClient[] = combineClients(
      isSomething(internalInvestmentClients)
        ? internalInvestmentClients.value
        : [],
      isSomething(equityClients) ? equityClients.value : []
    ).filter((client): client is IAdminClient => client.mdmOId != null);

    return clients.length > 0 ? some(clients) : nothing;
  }
);
