import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import React from "react";

import { ErrorStringConstants } from "../../constants/ErrorStringConstants";
import { StringConstants } from "../../constants/StringConstants";
import styles from "./NoPermissionPage.module.scss";

export const NoPermissionPage = () => {
  return (
    <div className={styles.error}>
      <ErrorOutlineOutlinedIcon className={styles.warningIcon} />
      <div className={styles.message}>
        <div>{`${ErrorStringConstants.NO_PERMISSION}`}</div>
        <div>
          {`${ErrorStringConstants.HELP_MESSAGE} `}
          <a
            className={styles.link}
            href={`${StringConstants.MAIL_TO_BXWEALTH_SUPPORT_EMAIL}`}
          >
            {ErrorStringConstants.BXWEALTH_SUPPORT}
          </a>
        </div>
      </div>
    </div>
  );
};
