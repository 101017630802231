import dayjs from "dayjs";
import Cookies from "js-cookie";
import { User } from "oidc-client-ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useStore } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { DataLoadStatus } from "../constants/enums";
import { RelativePath, SearchParam } from "../constants/Paths";
import { StringConstants } from "../constants/StringConstants";
import { reqAllEntitlements } from "../redux/actions/entitlementActions";
import { refreshFailure, resetAuthState } from "../redux/reducers/authReducer";
import { IBaseStore } from "../redux/store";
import {
  AuthProviderName,
  EnvironmentResolver,
} from "../services/environmentResolver";
import { isNotRequested } from "./dataLoadUtils";

const AUTH_TOKEN_COOKIE_NAME = "authToken";
const AUTH_PROVIDER_COOKIE_NAME = "authProvider";
const NEXT_AUTH_PROVIDER_COOKIE_NAME = "nextAuthProvider";
const LOGIN_REDIRECT_PATH_COOKIE_NAME = "loginRedirectPath";

// Little bit of one-time magic to be used at start up.
// Get the nextAuthProvider that was in a previous login and set it to current.
// This gives a stable Cookie to work with for this session, and we set a new
// nextAuthProvider cookie when we load the user's feature flags again.
export const getNewAuthProviderForSession = () => {
  const nextProvider = Cookies.get(NEXT_AUTH_PROVIDER_COOKIE_NAME);
  if (nextProvider) {
    Cookies.set(AUTH_PROVIDER_COOKIE_NAME, nextProvider);
    Cookies.remove(NEXT_AUTH_PROVIDER_COOKIE_NAME);
  }
};

export const setNextAuthProvider = (nextProvider: AuthProviderName) => {
  Cookies.set(NEXT_AUTH_PROVIDER_COOKIE_NAME, nextProvider);
};

export const getActiveOIDCProviderDefinition = () => {
  if (
    EnvironmentResolver.ENV.AUTH_PROVIDER === "COGNITO" || // global flag is set to Cognito
    Cookies.get(AUTH_PROVIDER_COOKIE_NAME) === "COGNITO" // OR session is set to Cognito
  ) {
    return EnvironmentResolver.ENV.COGNITO_OIDC_PROVIDER;
  } else {
    return EnvironmentResolver.ENV.OKTA_OIDC_PROVIDER;
  }
};

export const useRefresh = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const initiateRefresh = useCallback(async () => {
    const user = await auth.signinSilent();
    if (user) {
      setAccessTokenAsCookie(user.access_token);
    } else {
      console.error("Could not refresh token for user");
      dispatch(refreshFailure());
      // if the refresh attempt failed, we want to remove the user and force
      // a new login.
      auth.removeUser();
    }
  }, [auth, dispatch]);

  return initiateRefresh;
};

export const useAutoRefresh = () => {
  const auth = useAuth();
  const initiateRefresh = useRefresh();

  const handleRefresh = useCallback(async () => {
    const expiration = auth.user?.expires_at;
    const twoMinutesFromNow = dayjs().add(2, "minutes").unix();
    if (expiration && expiration < twoMinutesFromNow) {
      await initiateRefresh();
    }
  }, [auth.user?.expires_at, initiateRefresh]);

  useEffect(() => {
    const interval = window.setInterval(() => handleRefresh(), 60_000);
    return () => window.clearInterval(interval);
  }, [handleRefresh]);
};

export const onSigninCallback = (user: void | User) => {
  if (user) {
    setAccessTokenAsCookie(user.access_token);
  }
};

export const setAccessTokenAsCookie = (accessToken: string) => {
  Cookies.set(AUTH_TOKEN_COOKIE_NAME, `Bearer ${accessToken}`, {
    sameSite: "Strict",
    secure: true,
  });
};

export const storePathForLoginRedirect = (pathName: string) => {
  Cookies.set(LOGIN_REDIRECT_PATH_COOKIE_NAME, pathName, {
    sameSite: "Strict",
    secure: true,
    // don't want this value to linger if something goes wrong, clear after 2 minutes
    expires: dayjs().add(2, "minute").toDate(),
  });
};

export const retrievePathForLoginRedirect = () => {
  const redirectPath = Cookies.get(LOGIN_REDIRECT_PATH_COOKIE_NAME);
  // clear cookie so it doesn't stick around to affect future behavior
  Cookies.remove(LOGIN_REDIRECT_PATH_COOKIE_NAME);
  return redirectPath;
};

export const userIsValid = (user: User | null | undefined) => {
  if (user === null || user === undefined) return false;
  return !user.expired;
};

export const useFetchEntitlements = (
  user: User | null | undefined,
  requiredEntitlementLoadStatuses: DataLoadStatus[]
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // User may be undefined on initial context load.
    // We don't want to do anything until we know more.
    if (user === undefined) {
      return;
    }
    if (!userIsValid(user) && location.pathname !== RelativePath.LOGIN) {
      // Send user back to login page if user access token expired or no user.
      storePathForLoginRedirect(location.pathname + location.search);
      navigate(RelativePath.LOGIN);
    } else if (
      // Trigger a request to get entitlements if not already fetched.
      userIsValid(user) &&
      isNotRequested(...requiredEntitlementLoadStatuses)
    ) {
      dispatch(reqAllEntitlements());
    }
  }, [user, requiredEntitlementLoadStatuses, location, navigate, dispatch]);
};

interface LoginTools {
  isAuthInProgress: boolean;
  isLogout: boolean;
  initiateSeamlessSSO: () => void;
  logoutUser: () => void;
  navigateAwayFromLogin: () => void;
}

export const useLogin = (): LoginTools => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const store = useStore<IBaseStore>();
  const dispatch = useDispatch();

  const [isAuthInProgress, setIsAuthInProgress] = useState(
    auth.isLoading || auth.activeNavigator !== undefined
  );
  const isLogout = useMemo(
    () => searchParams.get(SearchParam.FROM_LOGOUT) === "true",
    [searchParams]
  );

  const logoutUser = useCallback(async () => {
    setIsAuthInProgress(false);
    Cookies.remove(AUTH_TOKEN_COOKIE_NAME);
    await auth.removeUser();
  }, [auth]);

  const navigateAwayFromLogin = useCallback(() => {
    if (auth.isAuthenticated) {
      const redirectPath = retrievePathForLoginRedirect();
      navigate(redirectPath ?? RelativePath.HOME, { replace: true });
    }
  }, [auth.isAuthenticated, navigate]);

  const initiateSeamlessSSO = useCallback(async () => {
    const shouldForceSSO = store.getState().auth.forceAttemptSSO;
    if (
      auth.isLoading || // we're loading user info from storage
      auth.activeNavigator !== undefined // user is authenticating now
    ) {
      setIsAuthInProgress(true);
    } else if (
      !auth.isAuthenticated && // skip if user is already authenticated
      !isLogout && //skip if user just logged out
      (!auth.error || shouldForceSSO) //skip if login generated error UNLESS we want to force an attempt. We do that when hiding refresh errors.
    ) {
      setIsAuthInProgress(true);
      // we only to force SSO once, so clean this up after we're done.
      if (shouldForceSSO) {
        dispatch(resetAuthState());
      }
      await auth.signinRedirect({
        extraQueryParams: {
          identity_provider: StringConstants.BLACKSTONE_IDP_NAME,
        },
      });
    } else {
      // otherwise nothing is doing, we haven't started any auth yet
      setIsAuthInProgress(false);
    }
  }, [auth, dispatch, isLogout, store]);

  return {
    isAuthInProgress,
    isLogout,
    initiateSeamlessSSO,
    logoutUser,
    navigateAwayFromLogin,
  };
};

export const usePassiveCheck = () => {
  const [passiveCheckStatus, setPassiveCheckStatus] = useState<DataLoadStatus>(
    DataLoadStatus.NOT_REQUESTED
  );

  useEffect(() => {
    const hitPassiveCheck = async () => {
      if (passiveCheckStatus === DataLoadStatus.NOT_REQUESTED) {
        setPassiveCheckStatus(DataLoadStatus.LOADING);
        try {
          const passiveResponse = await fetch(
            EnvironmentResolver.ENV.REACT_APP_PASSIVE_CHECK_URL
          );
          setPassiveCheckStatus(
            passiveResponse.ok
              ? DataLoadStatus.SUCCESSFUL
              : DataLoadStatus.UNSUCCESSFUL
          );
        } catch {
          setPassiveCheckStatus(DataLoadStatus.UNSUCCESSFUL);
        }
      }
    };

    hitPassiveCheck();
  }, [passiveCheckStatus]);

  return passiveCheckStatus;
};
