import { StyleSheet } from "@react-pdf/renderer";

import { ColumnWidths } from "../../ElectionAgreementDocumentStyleSheet";

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    width: 531,
    fontFamily: "Guardian Sans Table",
    justifyContent: "space-between",
  },
  signColumn: {
    width: ColumnWidths.SIGN_COLUMN,
  },
  strategy: {
    width: ColumnWidths.STRATEGY,
    textAlign: "left",
    fontFamily: "Guardian Sans",
  },
  strategyNoRemainingCommitment: {
    width:
      ColumnWidths.STRATEGY +
      ColumnWidths.REMAINING_COMMITMENT +
      ColumnWidths.SIGN_COLUMN,
    textAlign: "left",
    fontFamily: "Guardian Sans",
  },
  strategyNoMandatoryCommitment: {
    width:
      ColumnWidths.STRATEGY +
      ColumnWidths.MANDATORY_COMMITMENT +
      ColumnWidths.SIGN_COLUMN,
    textAlign: "left",
    fontFamily: "Guardian Sans",
  },
  strategyNoMandatoryOrRemainingCommitment: {
    width:
      ColumnWidths.STRATEGY +
      ColumnWidths.MANDATORY_COMMITMENT +
      ColumnWidths.SIGN_COLUMN +
      ColumnWidths.REMAINING_COMMITMENT +
      ColumnWidths.SIGN_COLUMN,
    textAlign: "left",
    fontFamily: "Guardian Sans",
  },
  remainingCommitment: {
    width: ColumnWidths.REMAINING_COMMITMENT,
  },
  mandatoryCommitment: {
    width: ColumnWidths.MANDATORY_COMMITMENT,
  },
  election: {
    width: ColumnWidths.ELECTION,
  },
  totalCommitment: {
    width: ColumnWidths.TOTAL_COMMITMENT,
  },
  forecastedInvestment: {
    width: ColumnWidths.FORECASTED_INVESTMENT,
  },
});
