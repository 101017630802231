import { createSelector } from "@reduxjs/toolkit";
import { isSomething } from "common";

import { builInvestmentVehicleIdForUI } from "../../utils/investmentEntityDropdownUtils";
import {
  selectIsClientDataOnly,
  selectViewDataStore,
} from "./baseStoreSelectors";

export const selectActiveInvestmentEntityDropdownItemId = createSelector(
  [selectViewDataStore, selectIsClientDataOnly],
  ({ selectedEntity }, isClientDataOnly) => {
    if (!isSomething(selectedEntity)) {
      return "";
    }

    const clientId = selectedEntity.value.clientId;
    const investmentVehicleId = selectedEntity.value.investmentVehicleId;

    return investmentVehicleId === undefined || isClientDataOnly
      ? clientId
      : builInvestmentVehicleIdForUI(clientId, investmentVehicleId);
  }
);
