import { Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { DashboardCard } from "../../../components/DashboardCard/DashboardCard";
import { LoansCard } from "../../../components/LoansCard/LoansCard";
import { RealizedProceedsOverview } from "../../../components/OverviewCards/RealizedProceedsOverview";
import { UnrealizedValueOverview } from "../../../components/OverviewCards/UnrealizedValueOverview";
import { SegregatedTrustBalance } from "../../../components/SegregatedTrustBalance/SegregatedTrustBalance";
import {
  DataLoadStatus,
  InvestmentValueDisplay,
  PeriodType,
} from "../../../constants/enums";
import {
  getAsOfLine,
  getDateRangeHeader,
  SectionHeader,
} from "../../../constants/LabelAndTooltipConstants";
import { useFetchDatasetIfIdDefined } from "../../../hooks/dataFetchHooks";
import { reqEquityData } from "../../../redux/actions/equityActions";
import { reqInternalInvestmentData } from "../../../redux/actions/internalInvestmentActions";
import {
  selectActiveInvestmentEntityData,
  selectAsOfDates,
  selectCallout,
  selectFilteredBy,
  selectHistoricalSumaryGrouped,
  selectInternalInvestmentData,
} from "../../../redux/selectors";
import { IBaseStore } from "../../../redux/store";
import { IBalancePerformanceData } from "../../../types/dataTypes";
import {
  cashFlowDataTypes,
  InvestmentHistoryValueItem,
} from "../../../types/investmentHistoryTypes";
import { isSomething } from "../../../types/typeGuards";
import { nothing, Optional, some } from "../../../types/typeUtils";
import { hasAnyRealizedData } from "../../../utils/calloutUtils";
import { getFirstDayOfQuarter } from "../../../utils/formatters";
import { trimData } from "../../../utils/investmentHistoryUtils";
import { LoansCardUtilization, showLoanCard } from "../../../utils/loanUtils";
import { CashFlow } from "../CashFlow/CashFlow";
import { PortfolioBalance } from "../PortfolioBalance/PortfolioBalance";
import { PortfolioPerformance } from "../PortfolioPerformance/PortfolioPerformance";
import styles from "./InvestmentsAndCarryOverview.module.scss";

interface OverviewWidget {
  renderFunction: (index: number) => JSX.Element;
  shouldShow: boolean;
}

export const InvestmentsAndCarryOverview = () => {
  const activeEquityDataId = useSelector(
    (state: IBaseStore) => state.equityData.activeEquityDataId
  );

  const { internalInvestmentDataLoadStatus } = useSelector(
    (state: IBaseStore) => selectInternalInvestmentData(state)
  );
  const filteredBy = useSelector(selectFilteredBy);

  const { financingBalance, segregatedTrustBalance } = useSelector(
    (store: IBaseStore) => selectActiveInvestmentEntityData(store)
  );

  const asOfDate = useSelector((store: IBaseStore) => selectAsOfDates(store));

  const { historicalSummaryGrouped } = useSelector((store: IBaseStore) =>
    selectHistoricalSumaryGrouped(store)
  );

  const { calloutData } = useSelector((store: IBaseStore) =>
    selectCallout(store)
  );

  const { equityDataLoadStatus } = useSelector(
    (store: IBaseStore) => store.equityData
  );

  const [investmentsAndCarryData, setInvestmentsAndCarryData] =
    useState<Optional<IBalancePerformanceData>>(nothing);

  const [showFinancingBalance, setShowFinancingBalance] =
    useState<boolean>(false);

  //fetch vesting data to show/hide equity
  // TODO: remove this when policy server has a way of hiding permissions as this is a temporary workaround
  useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  useEffect(() => {
    const investmentsAndCarryData: Optional<IBalancePerformanceData> = some({
      historicalSummaryGrouped,
      asOfDate,
    });
    setInvestmentsAndCarryData(investmentsAndCarryData);
  }, [calloutData, historicalSummaryGrouped, asOfDate]);

  const unrealizedOverview = useMemo(
    () => calloutData.unrealized,
    [calloutData.unrealized]
  );
  const realizedOverview = useMemo(() => {
    if (
      isSomething(calloutData.realized) &&
      hasAnyRealizedData(calloutData.realized)
    ) {
      return some({
        callouts: calloutData.realized.value,
        asOfDates: asOfDate,
      });
    }
    return nothing;
  }, [asOfDate, calloutData.realized]);

  useEffect(() => {
    if (isSomething(financingBalance)) {
      showLoanCard(
        financingBalance.value,
        setShowFinancingBalance,
        LoansCardUtilization.INCLUDE_UTILIZATION
      );
    } else {
      setShowFinancingBalance(false);
    }
  }, [financingBalance]);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    filteredBy,
    internalInvestmentDataLoadStatus
  );

  const valueDisplay = InvestmentValueDisplay.NONCUMULATIVE;

  const showCashFlow = useMemo(() => {
    if (
      isSomething(investmentsAndCarryData) &&
      isSomething(investmentsAndCarryData.value.historicalSummaryGrouped)
    ) {
      const trimmed = trimData(
        investmentsAndCarryData.value.historicalSummaryGrouped.value[
          PeriodType.QUARTERLY
        ],
        cashFlowDataTypes.map((type: InvestmentHistoryValueItem) => type.key),
        valueDisplay
      );
      return trimmed.length > 0;
    }
    return false;
  }, [investmentsAndCarryData, valueDisplay]);

  const widgets: OverviewWidget[] = [
    {
      renderFunction: (index: number) => (
        <div className={`${styles.splitCallout}`} key={index}>
          <div className={styles.overviews}>
            <DashboardCard
              cardContentComponent={UnrealizedValueOverview}
              retryRequest={retryFunction}
              data={unrealizedOverview}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              id={styles.unrealizedOverview}
              showHeaderWhenNoData={true}
              header={SectionHeader.UNREALIZED_VALUE_OVERVIEW}
              subHeader={
                isSomething(unrealizedOverview)
                  ? getAsOfLine(
                      unrealizedOverview.value.netUnrealizedValue.asOfDate
                    )
                  : undefined
              }
              headerClassName={styles.overviewHeader}
            />
            <DashboardCard
              cardContentComponent={RealizedProceedsOverview}
              retryRequest={retryFunction}
              data={realizedOverview}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              id={styles.realizedOverview}
              showHeaderWhenNoData={true}
              header={SectionHeader.REALIZED_PROCEEDS_OVERVIEW}
              subHeader={
                isSomething(realizedOverview)
                  ? getDateRangeHeader(
                      getFirstDayOfQuarter(
                        realizedOverview.value.asOfDates.earliestAsOfDate
                      ),
                      realizedOverview.value.asOfDates.latestAsOfDate
                    )
                  : undefined
              }
              headerClassName={styles.overviewHeader}
            />
          </div>
          <div className={styles.bxWealthCard}>
            <DashboardCard
              cardContentComponent={PortfolioBalance}
              retryRequest={retryFunction}
              data={investmentsAndCarryData}
              dataLoadStatus={internalInvestmentDataLoadStatus}
              id={styles.cashFlowChart}
            />
          </div>
        </div>
      ),
      shouldShow: true,
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          cardContentComponent={CashFlow}
          retryRequest={retryFunction}
          data={investmentsAndCarryData}
          dataLoadStatus={internalInvestmentDataLoadStatus}
        />
      ),
      shouldShow: showCashFlow,
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          cardContentComponent={PortfolioPerformance}
          retryRequest={retryFunction}
          data={investmentsAndCarryData}
          dataLoadStatus={internalInvestmentDataLoadStatus}
        />
      ),
      shouldShow: false, // temporarily hiding as per BXAC-10266
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          header={SectionHeader.LOANS}
          subHeader={getAsOfLine(asOfDate.latestAsOfDate)}
          cardContentComponent={LoansCard}
          retryRequest={retryFunction}
          data={financingBalance}
          dataLoadStatus={internalInvestmentDataLoadStatus}
          hideOnEmpty={true}
          hide={!showFinancingBalance}
        />
      ),
      // hide when no data available
      shouldShow:
        internalInvestmentDataLoadStatus !== DataLoadStatus.EMPTY_RESPONSE &&
        isSomething(financingBalance),
    },
    {
      renderFunction: (index: number) => (
        <DashboardCard
          key={index}
          header={SectionHeader.SEG_TRUST_BALANCE}
          subHeader={getAsOfLine(
            isSomething(asOfDate.segTrustBalanceDate)
              ? asOfDate.segTrustBalanceDate.value
              : new Date()
          )}
          cardContentComponent={SegregatedTrustBalance}
          retryRequest={retryFunction}
          data={segregatedTrustBalance}
          dataLoadStatus={internalInvestmentDataLoadStatus}
          hideOnEmpty={true}
        />
      ),
      // hide when no data available
      shouldShow:
        internalInvestmentDataLoadStatus !== DataLoadStatus.EMPTY_RESPONSE &&
        isSomething(segregatedTrustBalance),
    },
  ].filter((widget) => widget.shouldShow);

  return (
    <div className={styles.container}>
      <Stack direction="row" className={styles.content}>
        <div className={styles.widgets}>
          {widgets.map((widgetInfo, index) => {
            return widgetInfo.renderFunction(index);
          })}
        </div>
      </Stack>
    </div>
  );
};
