import { ApiPath } from "../constants/ApiPathConstants";
import { IEquityClientSource, IEquityData } from "../types/dataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { handleResponse } from "./utils";

export type IEquitySourceData = Json<IEquityData>;

// Gets client equity awards data
export const getClientEquityData = async (
  clientId: string
): Promise<Maybe<IEquitySourceData[]>> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_V2_URL_BASE}/clients/${clientId}/awards`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return handleResponse(response);
};

export type IEquityClientSources = Json<IEquityClientSource>[];

export const getEquityClients = async (): Promise<
  Maybe<IEquityClientSource>
> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_V2_URL_BASE}/clients`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return await handleResponse(response);
};
