import { Stack } from "@mui/material";
import {
  ElectionsLabels,
  IElectionsFilter,
  isSomething,
  Optional,
  OptionalBooleanSelector,
  some,
} from "common";
import React from "react";

import { CurrentStageFilter } from "./CurrentStageFilter/CurrentStageFilter";

export interface IElectorViewGridFilterContentProps {
  currentFilters: IElectionsFilter;
  updateFilters: (newValue: IElectionsFilter) => void;
}
export const ElectorViewGridFilterContent = ({
  currentFilters,
  updateFilters,
}: IElectorViewGridFilterContentProps) => {
  const onChangeCurrentStatusFilter = (newStatuses: number[]) => {
    const newFilterValue = {
      ...currentFilters,
      currentStage: some(newStatuses),
    };
    updateFilters(newFilterValue);
  };

  const onChangeOptionalBooleanFilter = (
    key: keyof IElectionsFilter,
    value: Optional<boolean>
  ) => {
    const newFilterValue = {
      ...currentFilters,
      [key]: value,
    };
    updateFilters(newFilterValue);
  };

  return (
    <Stack direction="column">
      <CurrentStageFilter
        onChange={onChangeCurrentStatusFilter}
        selectedItems={
          isSomething(currentFilters.currentStage)
            ? currentFilters.currentStage.value
            : []
        }
      />
      <OptionalBooleanSelector
        currentValue={currentFilters.wasReopened}
        label={ElectionsLabels.REOPENED_FILTER_LABEL}
        onChange={(value) =>
          onChangeOptionalBooleanFilter("wasReopened", value)
        }
      />
    </Stack>
  );
};
