import {
  AuthenticatedPage,
  IInvestmentVehicleByClientByPeriod,
  isInProgress,
  LoadingIndicator,
  NoPermissionPage,
  PathFragment,
  PeriodHash,
  reqClientDistributionsData,
  reqClientEquityData,
  reqInternalInvestmentData,
  reqStockData,
  setActiveEquityDataId,
  setSelectedEntity,
  useFetchEntitlements,
  userIsValid,
} from "common";
import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { PageName, Pages } from "../../../constants/Pages/Pages";
import { RelativePath } from "../../../constants/Paths";
import { Login } from "../../../features/Authentication/Login";
import ElectionAdminPage from "../../../features/Elections/ElectionAdmin/ElectionAdminPage";
import { selectActiveClients } from "../../../redux/selectors/clientsSelector";
import { AdminUIStore } from "../../../redux/store";
import { findInternalInvestmentClientForClientId } from "../../../utils/adminClientUtils";
import { isPageVisible } from "../../../utils/routingUtils";
import styles from "./RouterProvider.module.scss";

export const RouterProvider = () => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;

  const {
    internalInvestmentAdminEntitlementLoadStatus,
    equityAdminEntitlementLoadStatus,
    hasInternalInvestmentAdminEntitlement,
    hasEquityAdminEntitlement,
    isElectionsAdmin,
    isElectionsAdminLoadStatus,
  } = useSelector((state: AdminUIStore) => state.entitlements);

  const { employees } = useSelector((state: AdminUIStore) => state.employees);

  const { internalInvestmentClients, equityClients } = useSelector(
    (state: AdminUIStore) => state.clients
  );

  useFetchEntitlements(auth.user, [
    internalInvestmentAdminEntitlementLoadStatus,
    equityAdminEntitlementLoadStatus,
    isElectionsAdminLoadStatus,
  ]);

  // Extract the clientId from the pathname
  const clientIdOrEmployeeId = pathname.split("/")[2];
  const isClientPage = pathname.split("/")[1] === PathFragment.ADMIN_CLIENT;
  const isEmployeePage = pathname.split("/")[1] === PathFragment.ADMIN_EQUITY;

  // Show both equity and internal investments clients if applicable
  const searchableClients = useSelector(selectActiveClients);

  useEffect(() => {
    // re-route from old employee-data link
    if (isEmployeePage) {
      <Navigate to={RelativePath.CLIENT_DATA} />;
    }
    // check if client Id in url is valid if not redirect to landing page
    else if (isClientPage && clientIdOrEmployeeId) {
      const clientMetadata = findInternalInvestmentClientForClientId(
        clientIdOrEmployeeId,
        searchableClients
      );

      // check if clientID is valid
      if (clientMetadata) {
        const client: IInvestmentVehicleByClientByPeriod = {
          clientId: clientMetadata.id,
          investmentVehicleId: undefined,
          periodId: PeriodHash.LATEST,
          mdmClientId: clientMetadata.mdmOId,
        };

        if (hasInternalInvestmentAdminEntitlement) {
          dispatch(reqInternalInvestmentData(client));
        }

        if (hasEquityAdminEntitlement) {
          dispatch(setActiveEquityDataId(client.clientId));
          dispatch(reqClientEquityData(client.clientId));
          dispatch(reqClientDistributionsData(client.clientId));
          dispatch(reqStockData());
        }

        dispatch(setSelectedEntity(client));
      } else {
        <Navigate to={RelativePath.CLIENT_DATA} />;
      }
    } else {
      <Navigate to={RelativePath.CLIENT_DATA} />;
    }
  }, [
    clientIdOrEmployeeId,
    dispatch,
    employees,
    hasEquityAdminEntitlement,
    hasInternalInvestmentAdminEntitlement,
    searchableClients,
    isClientPage,
    isEmployeePage,
    internalInvestmentClients,
    equityClients,
  ]);

  const SetRouteByEntitlements = () => {
    // check if entitlements are still loading
    if (
      isInProgress(
        internalInvestmentAdminEntitlementLoadStatus,
        equityAdminEntitlementLoadStatus,
        isElectionsAdminLoadStatus
      )
    ) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }
    const urlPath = location.pathname;
    const urlPathLower = urlPath.toLowerCase();
    if (
      urlPath !== urlPathLower &&
      Object.keys(Pages)
        .map((object) => Pages[object as PageName].path)
        .some((item) => item === urlPathLower)
    ) {
      /*
      if url string contains uppercase and corresponds to a valid page when made lowercase, navigate to the lowercase route.
        This forces all urls to automatically become lowercase, even if typed in capitals
      */
      return <Navigate to={urlPathLower} />;
    }

    // if entitlements are loaded route to either landing page or no permissions
    return (hasEquityAdminEntitlement ||
      hasInternalInvestmentAdminEntitlement) &&
      clientIdOrEmployeeId ? (
      <Navigate
        to={RelativePath.CLIENT_DEFAULT.replace(
          ":clientId",
          clientIdOrEmployeeId
        )}
      />
    ) : hasEquityAdminEntitlement || hasInternalInvestmentAdminEntitlement ? (
      <Navigate to={RelativePath.CLIENT_DATA} />
    ) : isElectionsAdmin ? (
      <ElectionAdminPage />
    ) : (
      <NoPermissionPage />
    );
  };

  if (
    userIsValid(auth.user) &&
    isInProgress(
      internalInvestmentAdminEntitlementLoadStatus,
      equityAdminEntitlementLoadStatus,
      isElectionsAdminLoadStatus
    )
  ) {
    return (
      <div className={styles.loadingIndicator}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <Routes>
      <Route path={RelativePath.LOGIN} element={<Login />} />
      {Object.keys(Pages).map((pageKey) => {
        if (
          isPageVisible(
            pageKey as PageName,
            hasInternalInvestmentAdminEntitlement,
            hasEquityAdminEntitlement,
            isElectionsAdmin
          )
        ) {
          const page = Pages[pageKey as PageName];
          return (
            <Route
              key={page.path}
              path={page.path}
              element={
                <div className={styles.page}>
                  <AuthenticatedPage
                    pageElement={page.element}
                    // currently hard-coded to 200 since we do not have a page load error in the admin UI
                    // TODO: separate page-load logic from Auth logic
                    pageLoadStatus={200}
                  />
                </div>
              }
            />
          );
        }
      })}
      <Route path="*" element={SetRouteByEntitlements()} />
    </Routes>
  );
};
