import { Text, View } from "@react-pdf/renderer";
import {
  ElectionsLabels,
  IElectCardData,
  isSomething,
  mapYesOrNo,
  Optional,
} from "common";
import React from "react";

import { styles } from "../ElectionAgreementDocumentStyleSheet";
import { ElectionAmountTable } from "../ElectionAmountTable/ElectionAmountTable";
import { QuestionResponse } from "../QuestionResponses/QuestionResponse/QuestionResponse";

interface IElectionPageProps {
  electionAmount: IElectCardData;
  canUseFinancing: boolean;
  useReallocation: Optional<boolean>;
  investmentVehicleName?: string;
  hasForecastedInvestment: boolean;
  hasMoreThanOneStrategy: boolean;
}
export const ElectionPage = (props: IElectionPageProps) => {
  const {
    electionAmount,
    canUseFinancing,
    useReallocation,
    investmentVehicleName,
    hasForecastedInvestment,
    hasMoreThanOneStrategy,
  } = props;

  return (
    <>
      <View break={investmentVehicleName ? false : true}>
        {investmentVehicleName && (
          <Text style={styles.h1}>{investmentVehicleName}</Text>
        )}
        <Text style={styles.h2}>{ElectionsLabels.ELECTIONS}</Text>
        <Text style={styles.h3}>{ElectionsLabels.ELECTED_AMOUNTS}</Text>
      </View>
      <ElectionAmountTable
        electionAmount={electionAmount}
        canUseFinancing={canUseFinancing}
        hasForecastedInvestment={hasForecastedInvestment}
      />
      {hasMoreThanOneStrategy && isSomething(useReallocation) && (
        <>
          <View>
            <Text style={styles.h3}>{ElectionsLabels.REALLOCATION}</Text>
          </View>
          <QuestionResponse
            question={ElectionsLabels.REALLOCATION_QUESTION}
            response={mapYesOrNo(useReallocation.value)}
          />
        </>
      )}
    </>
  );
};
