import { InfoOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";

import styles from "./NoDataPage.module.scss";

interface INoData {
  message: string;
  emailId: string;
  contactText: string;
}

export const NoDataPage = (props: INoData) => {
  return (
    <div className={styles.info}>
      <Stack direction="row">
        <InfoOutlined className={styles.infoOutlinedIcon} />
        <span className={styles.data}>{props.message}</span>
      </Stack>
      <p className={styles.message}>
        {props.contactText}
        <a href={`mailto:${props.emailId}`}>{props.emailId}</a>.
      </p>
    </div>
  );
};
