import { ApiPath } from "../constants/ApiPathConstants";
import { IDistributionsDatum } from "../types/dataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { handleResponse } from "./utils";

export type IDistriutionsSourceDatum = Json<IDistributionsDatum>;

export const getDistributionsData = async (
  employeeNumber: string
): Promise<Maybe<IDistriutionsSourceDatum[]>> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_URL_BASE}/employees/${employeeNumber}/distributions`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return handleResponse(response);
};

// Client Distributions data call
export const getClientDistributionsData = async (
  clientId: string
): Promise<Maybe<IDistriutionsSourceDatum[]>> => {
  const user = getUser();
  const url = `${ApiPath.EQUITY_V2_URL_BASE}/clients/${clientId}/distributions`;

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return handleResponse(response);
};
