import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";

import { IAlert } from "../../types/storeTypes";
import styles from "./Alert.module.scss";

interface IAlertProps {
  alert: IAlert;
  handleClose: (alertId: string) => void;
}

export const Alert = (props: IAlertProps) => {
  const { alert, handleClose } = props;

  const onClose = () => {
    // if the alert was created by openAlert action it always
    // will have alertId; created when alert was raised or in the reducer.
    if (alert.alertId) {
      handleClose(alert.alertId);
    }
  };

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={
        alert.hideDuration === undefined ? 6000 : alert.hideDuration
      }
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <MuiAlert
        className={styles.alertToast}
        onClose={onClose}
        severity={alert?.severity}
      >
        {alert?.message}
      </MuiAlert>
    </Snackbar>
  );
};
