import { IAdminClient, isSomething, Optional } from "common";

/**
 * Compares two internal investment clients based on their names.
 * Case insenstive.
 *
 * @param a The first internal investment client to compare.
 * @param b The second internal investment client to compare.
 * @returns -1 if the name of client 'a' comes before the name of client 'b',
 *           1 if the name of client 'a' comes after the name of client 'b',
 *           0 if the names of both clients are equal.
 */
export const compareClients = (a: IAdminClient, b: IAdminClient): number => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  // Compare client names
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }
  // Names are equal
  return 0;
};

export const getClientOptionLabel = (option: IAdminClient) =>
  option.name + " (" + option.mdmOId + ")";

/**
 * Searches and sorts an array of internal investment clients based on a given search string.
 * Clients can be searched by exact ID match, exact name match, name beginning match, or name containing match.
 * The clients are sorted by type of match and then alphabetically by name.
 *
 * @param clients An array of internal investment clients to search and sort.
 * @param searchString The string used to search for clients.
 * @returns An array of internal investment clients sorted based on the search criteria.
 */
export const searchAndSortClients = (
  clients: IAdminClient[],
  searchString: string
): IAdminClient[] => {
  // If search string is too short, return unmodified array
  if (searchString.length < 2) {
    return clients;
  }

  // Convert search string to lowercase for case-insensitive comparison
  const searchStringLowerCase = searchString.toLowerCase();

  // Arrays to store different types of matches
  const exactNameMatches: IAdminClient[] = [];
  const beginsWithNameMatches: IAdminClient[] = [];
  const containingNameMatches: IAdminClient[] = [];
  const exactIdMatches: IAdminClient[] = [];
  // Iterate through each client to find matches
  clients.forEach((client) => {
    if (client.mdmOId === searchStringLowerCase) {
      exactIdMatches.push(client);
    } else if (
      getClientOptionLabel(client).toLowerCase() === searchStringLowerCase
    ) {
      exactNameMatches.push(client);
    } else if (client.name.toLowerCase() === searchStringLowerCase) {
      exactNameMatches.push(client);
    } else if (client.name.toLowerCase().startsWith(searchStringLowerCase)) {
      beginsWithNameMatches.push(client);
    } else if (client.name.toLowerCase().includes(searchStringLowerCase)) {
      containingNameMatches.push(client);
    }
  });

  // Concatenate and sort the matches based on name
  return [
    ...exactIdMatches,
    ...exactNameMatches,
    ...beginsWithNameMatches.sort(compareClients),
    ...containingNameMatches.sort(compareClients),
  ];
};

export const findInternalInvestmentClientForClientId = (
  clientId: string | undefined,
  clients: Optional<IAdminClient[]>
) => {
  return clientId && isSomething(clients)
    ? clients.value.find((client: IAdminClient) => client.mdmOId === clientId)
    : undefined;
};
