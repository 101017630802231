import { SaveAlt } from "@mui/icons-material";
import { Link, TableCell, TableRow } from "@mui/material";
import clsx from "clsx";
import {
  get2DPNumberFormattedWithZeroDefaultForOptional,
  getCurrencyFormattedValueWithZeroDefault,
  getCurrencyFormattedValueWithZeroDefaultForOptional,
  getPreComputedPercentageFormattedValueOrDash,
  isSomething,
  IStrategyModel,
  MonospaceNumber,
  MonospaceOptionalNumber,
  Optional,
  useDownloadSingleDocument,
} from "common";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";

import {
  selectPPMDocuments,
  selectStrategiesStageConfig,
} from "../../../../../../../../redux/selectors";
import { IBaseStore } from "../../../../../../../../redux/store";
import styles from "./StrategyAndFundRows.module.scss";

interface StrategyAndFundRowsProps {
  strategy: IStrategyModel;
  hasForecastedDeployment: boolean;
}

export const StrategyAndFundRows = (props: StrategyAndFundRowsProps) => {
  const { strategy, hasForecastedDeployment } = props;

  const { activeElection } = useSelector(
    (state: IBaseStore) => state.selectedElection
  );

  const stageConfig = useSelector((state: IBaseStore) =>
    selectStrategiesStageConfig(state)
  );

  const ppmDocuments = useSelector((state: IBaseStore) =>
    selectPPMDocuments(state)
  );

  const { downloadElectionSingleDocument } = useDownloadSingleDocument();

  const handleDocumentDownload = async (
    electionRoundId: string,
    documentId: number
  ) => await downloadElectionSingleDocument(electionRoundId, documentId);

  const getTooltipClass = (tooltip: Optional<string>) => {
    return isSomething(tooltip) ? styles.tooltipColumnCell : null;
  };

  const getPPMDocumentCell = (fundId: number): ReactNode => {
    const ppmDoc = ppmDocuments.find((doc) => doc.fundId === fundId);
    if (isSomething(activeElection) && ppmDoc) {
      return (
        <Link
          onClick={() =>
            handleDocumentDownload(
              activeElection.value.electionRoundId,
              ppmDoc.id
            )
          }
        >
          <SaveAlt className={styles.ppmDownloadIcon} />
        </Link>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <TableRow className={styles.strategyRow}>
        <TableCell className={styles.nameCell}>{strategy.name}</TableCell>
        {/* Empty cells for data not provided on strategy level */}
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {hasForecastedDeployment && (
          <>
            <TableCell></TableCell>
            <TableCell
              align="right"
              className={clsx(
                getTooltipClass(stageConfig.totalSubjectToElectionTooltip)
              )}
            >
              {strategy.funds.length > 1 ? (
                <MonospaceOptionalNumber
                  value={strategy.totalSubjectToElection}
                  valueFormatter={
                    getCurrencyFormattedValueWithZeroDefaultForOptional
                  }
                />
              ) : (
                ""
              )}
            </TableCell>
            <TableCell
              align="right"
              className={clsx(
                getTooltipClass(
                  stageConfig.forecastedDeploymentPercentageTooltip
                ),
                styles.greyCell
              )}
            >
              {strategy.funds.length > 1 ? (
                <MonospaceNumber
                  value={strategy.forecastedDeploymentPercentage * 100}
                  valueFormatter={getPreComputedPercentageFormattedValueOrDash}
                />
              ) : (
                ""
              )}
            </TableCell>
            <TableCell
              align="right"
              className={clsx(
                getTooltipClass(stageConfig.forecastedDeploymentTooltip),
                styles.greyCell
              )}
            >
              {strategy.funds.length > 1 ? (
                <MonospaceOptionalNumber
                  value={strategy.forecastedDeployment}
                  valueFormatter={
                    getCurrencyFormattedValueWithZeroDefaultForOptional
                  }
                />
              ) : (
                ""
              )}
            </TableCell>
          </>
        )}
      </TableRow>
      {strategy.funds.map((fund) => (
        <TableRow key={"fund" + fund.fundId} className={styles.fundRow}>
          <TableCell className={styles.fundNameCell}>
            {fund.fundShortName}
          </TableCell>
          <TableCell className={styles.ppmDownloadCell}>
            {getPPMDocumentCell(fund.fundId)}
          </TableCell>
          <TableCell
            align="right"
            className={clsx(getTooltipClass(stageConfig.lpCommitmentTooltip))}
          >
            <MonospaceNumber
              value={fund.limitedPartnerCommitment}
              valueFormatter={getCurrencyFormattedValueWithZeroDefault}
            />
          </TableCell>
          <TableCell
            align="right"
            className={clsx(
              getTooltipClass(stageConfig.blackstoneCommitmentTooltip)
            )}
          >
            <MonospaceNumber
              value={fund.blackstoneCommitment}
              valueFormatter={getCurrencyFormattedValueWithZeroDefault}
            />
          </TableCell>
          <TableCell align="right">
            <MonospaceNumber
              value={fund.totalFundSize}
              valueFormatter={getCurrencyFormattedValueWithZeroDefault}
            />
          </TableCell>
          {hasForecastedDeployment && (
            <>
              <TableCell align="right">
                <MonospaceOptionalNumber
                  value={fund.investmentPeriodYears}
                  valueFormatter={
                    get2DPNumberFormattedWithZeroDefaultForOptional
                  }
                />
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  getTooltipClass(stageConfig.totalSubjectToElectionTooltip)
                )}
              >
                <MonospaceOptionalNumber
                  value={fund.totalSubjectToElection}
                  valueFormatter={
                    getCurrencyFormattedValueWithZeroDefaultForOptional
                  }
                />
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  styles.greyCell,
                  getTooltipClass(
                    stageConfig.forecastedDeploymentPercentageTooltip
                  )
                )}
              >
                <MonospaceNumber
                  value={fund.forecastedDeploymentPercentage * 100}
                  valueFormatter={getPreComputedPercentageFormattedValueOrDash}
                />
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  styles.greyCell,
                  getTooltipClass(stageConfig.forecastedDeploymentTooltip)
                )}
              >
                <MonospaceOptionalNumber
                  value={fund.forecastedDeployment}
                  valueFormatter={
                    getCurrencyFormattedValueWithZeroDefaultForOptional
                  }
                />
              </TableCell>
            </>
          )}
        </TableRow>
      ))}
    </React.Fragment>
  );
};
